import { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    IconButton,
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    FormControl,
    FormLabel,
    FormHelperText,
    Alert,
    Chip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { LocalizationProvider, TimePicker, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { FieldHeader } from '../FieldHeader';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

interface TransferRuleModalProps {
    open: boolean;
    onClose: () => void;
    rule: any;
    index: number;
    formData: any;
    onSave?: (rule: any, index: number) => void;
    translate: (key: string) => string;
}

interface Availability {
    days: number[];
    start_time: string;
    end_time: string;
}

interface TransferRule {
    number: string;
    reasons: string[];
    availability: Availability[];
    closed_dates: string[];
    closed_instruction: string;
    unavailable_instruction: string;
}

interface ValidationErrors {
    number?: string;
    reasons?: string | string[];
    availability?: any[];
    closed_dates?: string;
    closed_instruction?: string;
    unavailable_instruction?: string;
}

const DEFAULT_RULE: TransferRule = {
    number: '',
    reasons: [''],
    availability: [{
        days: [],
        start_time: '09:00',
        end_time: '17:00'
    }],
    closed_dates: [],
    closed_instruction: "{{assistant.name}} is unavailable at the moment. Gather relevant information and tell caller you will forward their message to the relevant person within {{assistant.name}}.",
    unavailable_instruction: "Mention that {[assistant.name}} is closed at the moment. Call transfers are not possible, offer to pass on a message or create a call-back request."
};

const DAYS_OF_WEEK = [
    { id: '1', name: 'Monday' },
    { id: '2', name: 'Tuesday' },
    { id: '3', name: 'Wednesday' },
    { id: '4', name: 'Thursday' },
    { id: '5', name: 'Friday' },
    { id: '6', name: 'Saturday' },
    { id: '7', name: 'Sunday' },
];

const doTimeRangesOverlap = (start1: string, end1: string, start2: string, end2: string): boolean => {
    // Convert time strings to comparable values (minutes since midnight)
    const toMinutes = (timeStr: string): number => {
        const [hours, minutes] = timeStr.split(':').map(Number);
        return hours * 60 + minutes;
    };
    
    const start1Min = toMinutes(start1);
    const end1Min = toMinutes(end1);
    const start2Min = toMinutes(start2);
    const end2Min = toMinutes(end2);
    
    const adjustedEnd1Min = end1Min === 0 ? 24 * 60 : end1Min;
    const adjustedEnd2Min = end2Min === 0 ? 24 * 60 : end2Min;
    
    // Check for overlap
    return start1Min < adjustedEnd2Min && adjustedEnd1Min > start2Min;
};

const TransferRuleModal = ({ open, onClose, rule, index, formData, onSave, translate }: TransferRuleModalProps) => {
    const [currentRule, setCurrentRule] = useState<TransferRule>(DEFAULT_RULE);
    const [isSaving, setIsSaving] = useState(false);
    const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
    const [error, setError] = useState('');
    const [is24HourModes, setIs24HourModes] = useState<boolean[]>([]);

    // Initialize the form with the rule data
    useEffect(() => {
        if (rule) {
            const preparedRule = {
                ...DEFAULT_RULE,
                ...rule,
                reasons: Array.isArray(rule.reasons) && rule.reasons.length > 0 
                    ? rule.reasons 
                    : (rule.reason ? [rule.reason] : ['']),
                availability: Array.isArray(rule.availability) && rule.availability.length > 0
                    ? rule.availability.map(schedule => ({
                        ...schedule,
                        days: schedule.days.map(day => day.toString())
                    }))
                    : DEFAULT_RULE.availability
            };
            setCurrentRule(preparedRule);
            
            // Initialize 24-hour modes based on rule data
            if (Array.isArray(rule.availability)) {
                const modes = rule.availability.map(schedule => 
                    schedule.start_time === '00:00' && schedule.end_time === '23:59'
                );
                setIs24HourModes(modes);
            } else {
                setIs24HourModes([false]);
            }
        }
    }, [rule]);

    // Improved time formatting function to handle different time formats consistently
    const formatTime = (time: any): string => {
        if (!time) return "00:00";
        
        // Handle dayjs objects from the TimePicker
        if (time.format && typeof time.format === 'function') {
            return time.format('HH:mm');
        }
        
        if (typeof time === 'string') {
            if (time.includes('T')) {
                // Handle ISO string format
                const date = new Date(time);
                return date.toLocaleTimeString('en-US', { 
                    hour12: false, 
                    hour: '2-digit', 
                    minute: '2-digit' 
                });
            }
            
            // If it's already in HH:mm format, just return it
            if (/^\d{1,2}:\d{2}$/.test(time)) {
                // Ensure hours are two digits
                const [hours, minutes] = time.split(':');
                return `${hours.padStart(2, '0')}:${minutes}`;
            }
        }
        
        if (time instanceof Date) {
            return time.toLocaleTimeString('en-US', { 
                hour12: false, 
                hour: '2-digit', 
                minute: '2-digit' 
            });
        }
        
        return "00:00"; // Default fallback
    };

    const validate = (): boolean => {
        const errors: ValidationErrors = {};

        // Phone number validation
        if (!currentRule.number) {
            errors.number = 'resources.assistants.errors.required';
        } else if (!/^\+?[1-9]\d{1,14}$/.test(currentRule.number)) {
            errors.number = 'resources.assistants.errors.invalid_phone';
        }

        // Validate that at least one reason exists and no reasons are empty
        if (!currentRule.reasons || currentRule.reasons.length === 0) {
            errors.reasons = 'resources.assistants.errors.at_least_one_reason_required';
        } else if (!currentRule.reasons.some(reason => reason && reason.trim())) {
            errors.reasons = 'resources.assistants.errors.at_least_one_reason_required';
        } else if (currentRule.reasons.some(reason => !reason || !reason.trim())) {
            errors.reasons = 'resources.assistants.errors.empty_reasons_not_allowed';
        }

        // Check for duplicate reasons
        if (currentRule.reasons && currentRule.reasons.length > 0) {
            const reasonsMap = new Map();
            const duplicateIndices: number[] = [];
            
            // Identify duplicates
            currentRule.reasons.forEach((reason, index) => {
                if (reason) {
                    const normalizedReason = reason.trim().toLowerCase();
                    if (reasonsMap.has(normalizedReason)) {
                        duplicateIndices.push(index);
                        duplicateIndices.push(reasonsMap.get(normalizedReason));
                    } else {
                        reasonsMap.set(normalizedReason, index);
                    }
                }
            });

            if (duplicateIndices.length > 0) {
                errors.reasons = 'resources.assistants.errors.duplicate_reasons_found';
            }
        }

        if (currentRule.availability && currentRule.availability.length > 0) {
            const availabilityErrors = [];

            currentRule.availability.forEach((schedule, idx) => {
                if (!schedule.days || schedule.days.length === 0) {
                    availabilityErrors.push({
                        index: idx,
                        error: 'resources.assistants.errors.at_least_one_day_required'
                    });
                }
            });

            if (availabilityErrors.length > 0) {
                errors.availability = availabilityErrors;
            }
        }

        // Validate availability for overlapping time ranges
        if (currentRule.availability && currentRule.availability.length > 1) {
            const availabilityErrors: any[] = errors.availability as any[] || [];
            
            for (let i = 0; i < currentRule.availability.length; i++) {
                for (let j = i + 1; j < currentRule.availability.length; j++) {
                    const schedule1 = currentRule.availability[i];
                    const schedule2 = currentRule.availability[j];

                    if (!schedule1.days?.length || !schedule2.days?.length) continue;

                    const commonDays = schedule1.days.filter(day => 
                        schedule2.days.includes(day)
                    );

                    // If they share days and time ranges overlap, it's an error
                    if (
                        commonDays.length > 0 && 
                        doTimeRangesOverlap(
                            formatTime(schedule1.start_time), 
                            formatTime(schedule1.end_time),
                            formatTime(schedule2.start_time), 
                            formatTime(schedule2.end_time)
                        )
                    ) {
                        availabilityErrors.push({
                            index: i,
                            error: 'resources.assistants.errors.overlapping_schedules'
                        });
                        availabilityErrors.push({
                            index: j,
                            error: 'resources.assistants.errors.overlapping_schedules'
                        });
                        break;
                    }
                }
                if (availabilityErrors.some(e => e.index === i)) break;
            }
            
            if (availabilityErrors.length > 0) {
                errors.availability = availabilityErrors;
            }
        }

        // Check for duplicated closed dates
        if (currentRule.closed_dates && currentRule.closed_dates.length > 0) {
            const uniqueDates = new Set(currentRule.closed_dates);
            if (uniqueDates.size !== currentRule.closed_dates.length) {
                errors.closed_dates = 'resources.assistants.errors.duplicate_dates_found';
            }
        }

        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSave = () => {
        if (!validate()) {
            setError(translate('resources.assistants.errors.validation_failed'));
            return;
        }

        setIsSaving(true);
        setError('');
        
        try {
            const formattedRule = {
                number: currentRule.number,
                reasons: currentRule.reasons.filter(reason => reason && reason.trim()) || [],
                availability: currentRule.availability?.map(schedule => ({
                    days: schedule.days.map(day => parseInt(day, 10)),
                    start_time: formatTime(schedule.start_time),
                    end_time: formatTime(schedule.end_time)
                })),
                closed_dates: currentRule.closed_dates,
                closed_instruction: currentRule.closed_instruction,
                unavailable_instruction: currentRule.unavailable_instruction
            };

            if (typeof onSave === 'function') {
                // Direct prop
                onSave(formattedRule, index);
            } else if (formData && typeof formData.onSave === 'function') {
                // Inside formData object
                formData.onSave(formattedRule, index);
            } else if (formData && typeof formData.setValue === 'function') {
                // React Hook Form
                formData.setValue(`transfer_rules.${index}`, formattedRule);
            } else {
                console.error('Unable to save transfer rule - no valid save method available');
            }
        } catch (err) {
            console.error('Error saving transfer rule:', err);
            setError('An error occurred while saving');
        }

        setIsSaving(false);
        onClose();
    };

    // Handlers for form field changes
    const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentRule({
            ...currentRule,
            number: event.target.value
        });
    };

    const handleReasonChange = (index: number, value: string) => {
        const updatedReasons = [...currentRule.reasons];
        updatedReasons[index] = value;
        setCurrentRule({
            ...currentRule,
            reasons: updatedReasons
        });
    };

    const handleAddReason = () => {
        setCurrentRule({
            ...currentRule,
            reasons: [...currentRule.reasons, '']
        });
    };

    const handleRemoveReason = (index: number) => {
        const updatedReasons = [...currentRule.reasons];
        updatedReasons.splice(index, 1);
        setCurrentRule({
            ...currentRule,
            reasons: updatedReasons.length > 0 ? updatedReasons : ['']
        });
    };

    const handleInstructionChange = (field: 'closed_instruction' | 'unavailable_instruction', value: string) => {
        setCurrentRule({
            ...currentRule,
            [field]: value
        });
    };

    const handleDayToggle = (availabilityIndex: number, day: string) => {
        const updatedAvailability = [...currentRule.availability];
        const currentDays = updatedAvailability[availabilityIndex].days;
        
        if (currentDays.includes(day)) {
            updatedAvailability[availabilityIndex].days = currentDays.filter(d => d !== day);
        } else {
            updatedAvailability[availabilityIndex].days = [...currentDays, day];
        }
        
        // Clear days validation error if it exists and days are selected
        if (validationErrors.availability?.[availabilityIndex]?.days && updatedAvailability[availabilityIndex].days.length > 0) {
            const newValidationErrors = {...validationErrors};
            if (newValidationErrors.availability?.[availabilityIndex]) {
                delete newValidationErrors.availability[availabilityIndex].days;
            }
            setValidationErrors(newValidationErrors);
        }
        
        setCurrentRule({
            ...currentRule,
            availability: updatedAvailability
        });
    };

    const handleTimeChange = (availabilityIndex: number, type: 'start_time' | 'end_time', time: any) => {
        if (!time) {
            setValidationErrors(prev => ({
                ...prev,
                availability: {
                    ...(prev.availability || {}),
                    [availabilityIndex]: {
                        ...(prev.availability?.[availabilityIndex] || {}),
                        [type]: translate('resources.assistants.errors.time_required')
                    }
                }
            }));
            return;
        }

        const formattedTime = `${time.hour().toString().padStart(2, '0')}:${time.minute().toString().padStart(2, '0')}`;
        
        const updatedAvailability = [...currentRule.availability];
        updatedAvailability[availabilityIndex] = {
            ...updatedAvailability[availabilityIndex],
            [type]: formattedTime
        };
        
        // Clear validation error if it exists
        if (validationErrors.availability?.[availabilityIndex]?.[type]) {
            const newValidationErrors = {...validationErrors};
            if (newValidationErrors.availability?.[availabilityIndex]) {
                delete newValidationErrors.availability[availabilityIndex][type];
            }
            setValidationErrors(newValidationErrors);
        }
        
        setCurrentRule({
            ...currentRule,
            availability: updatedAvailability
        });
    };

    const set24HourTimeRange = (scheduleIndex: number) => {
        const updatedModes = [...is24HourModes];
        updatedModes[scheduleIndex] = true;
        setIs24HourModes(updatedModes);
        
        const updatedAvailability = [...currentRule.availability];
        updatedAvailability[scheduleIndex] = {
            ...updatedAvailability[scheduleIndex],
            start_time: '00:00',
            end_time: '23:59'
        };
        
        setCurrentRule({
            ...currentRule,
            availability: updatedAvailability
        });
    };
    
    const disableSet24HourMode = (scheduleIndex: number) => {
        const updatedModes = [...is24HourModes];
        updatedModes[scheduleIndex] = false;
        setIs24HourModes(updatedModes);
    };

    const handleAddAvailability = () => {
        setCurrentRule({
            ...currentRule,
            availability: [
                ...currentRule.availability,
                { days: [], start_time: '09:00', end_time: '17:00' }
            ]
        });
        
        setIs24HourModes([...is24HourModes, false]);
    };

    const handleRemoveAvailability = (index: number) => {
        const updatedAvailability = [...currentRule.availability];
        updatedAvailability.splice(index, 1);
        
        const updatedModes = [...is24HourModes];
        updatedModes.splice(index, 1);
        setIs24HourModes(updatedModes);
        
        // Remove validation errors for this availability if they exist
        if (validationErrors.availability?.[index]) {
            const newValidationErrors = {...validationErrors};
            if (newValidationErrors.availability) {
                const newAvailabilityErrors = {...newValidationErrors.availability};
                delete newAvailabilityErrors[index];
                
                // Reindex higher indices
                Object.keys(newAvailabilityErrors)
                    .filter(key => parseInt(key) > index)
                    .forEach(key => {
                        const newKey = parseInt(key) - 1;
                        newAvailabilityErrors[newKey] = newAvailabilityErrors[key];
                        delete newAvailabilityErrors[key];
                    });
                
                newValidationErrors.availability = newAvailabilityErrors;
                setValidationErrors(newValidationErrors);
            }
        }
        
        setCurrentRule({
            ...currentRule,
            availability: updatedAvailability
        });
    };

    const handleAddClosedDate = () => {
        const newDate = dayjs().format('YYYY-MM-DD');
        
        // Check if this date already exists
        if (currentRule.closed_dates.includes(newDate)) {
            // If it exists, add a day to make it unique
            const nextDay = dayjs().add(1, 'day').format('YYYY-MM-DD');
            setCurrentRule({
                ...currentRule,
                closed_dates: [...currentRule.closed_dates, nextDay]
            });
        } else {
            setCurrentRule({
                ...currentRule,
                closed_dates: [...currentRule.closed_dates, newDate]
            });
        }
    };

    const handleRemoveClosedDate = (index: number) => {
        const updatedClosedDates = [...currentRule.closed_dates];
        updatedClosedDates.splice(index, 1);
        setCurrentRule({
            ...currentRule,
            closed_dates: updatedClosedDates
        });
    };

    const handleClosedDateChange = (index: number, date: any) => {
        if (!date) return;
        
        const formattedDate = date.format('YYYY-MM-DD');
        
        // Check if this date already exists elsewhere in the array
        const dateExists = currentRule.closed_dates.some(
            (existingDate, i) => i !== index && existingDate === formattedDate
        );
        
        if (dateExists) {
            setValidationErrors(prev => ({
                ...prev,
                closed_dates: 'resources.assistants.errors.duplicate_dates_found'
            }));
            return;
        }
        
        const updatedClosedDates = [...currentRule.closed_dates];
        updatedClosedDates[index] = formattedDate;
        
        // Clear validation error if it exists
        if (validationErrors.closed_dates) {
            setValidationErrors(prev => {
                const newErrors = {...prev};
                delete newErrors.closed_dates;
                return newErrors;
            });
        }
        
        setCurrentRule({
            ...currentRule,
            closed_dates: updatedClosedDates
        });
    };


    return (
        <Dialog 
            open={open} 
            maxWidth="md" 
            fullWidth
            disableEscapeKeyDown
        >
            <DialogTitle>
                <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                    {translate('resources.assistants.fields.edit_transfer_rule')}
                    <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent dividers>
                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}

                <Grid container spacing={2}>
                    {/* Transfer Reasons Section */}
                    <Grid item xs={12}>
                        <FormControl 
                            fullWidth 
                            error={!!validationErrors.reasons}
                            sx={{ mb: 2 }}
                        >   
                            <FieldHeader 
                                    title="resources.assistants.fields.transfer_reasons" 
                                    description="resources.assistants.descriptions.transfer_reasons" 
                                />
                            {currentRule.reasons.map((reason, index) => (
                                <Box key={index} sx={{ display: 'flex', mb: 1, mt: 1 }}>
                                    <TextField
                                        fullWidth
                                        value={reason}
                                        onChange={(e) => handleReasonChange(index, e.target.value)}
                                        placeholder={translate('resources.assistants.placeholders.transfer_reason')}
                                        error={!!validationErrors.reasons}
                                    />
                                    <IconButton 
                                        onClick={() => handleRemoveReason(index)}
                                        disabled={currentRule.reasons.length <= 1}
                                        sx={{ ml: 1 }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            ))}
                            <Button 
                                startIcon={<AddIcon />}
                                onClick={handleAddReason}
                                sx={{ mt: 1, alignSelf: 'flex-start' }}
                                color="primary"
                                variant="outlined"
                            >
                                {translate('resources.assistants.actions.add_reason')}
                            </Button>
                            {validationErrors.reasons && (
                                <FormHelperText error>
                                    {typeof validationErrors.reasons === 'string' 
                                        ? translate(validationErrors.reasons)
                                        : translate('resources.assistants.errors.duplicate_reasons_found')}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Grid>

                    {/* Transfer Destination Section */}
                    <Grid item xs={12}>
                        <FormControl 
                            fullWidth 
                            error={!!validationErrors.number}
                            sx={{ mb: 2 }}
                        >
                            <FieldHeader 
                                    title="resources.assistants.fields.transfer_destination" 
                                    description="resources.assistants.descriptions.transfer_destination" 
                                />
                            <TextField
                                fullWidth
                                value={currentRule.number}
                                onChange={handleNumberChange}
                                placeholder="+1234567890"
                                error={!!validationErrors.number}
                                helperText={validationErrors.number ? translate(validationErrors.number) : ''}
                                sx={{ mt: 1 }}
                            />
                        </FormControl>
                    </Grid>

                    {/* Instructions Section */}
                    <Grid item xs={12}>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <FieldHeader 
                                    title="resources.assistants.fields.closed_instruction" 
                                    description="resources.assistants.descriptions.closed_instruction" 
                                />
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                value={currentRule.closed_instruction}
                                onChange={(e) => handleInstructionChange('closed_instruction', e.target.value)}
                                placeholder={translate('resources.assistants.placeholders.closed_instruction')}
                            />
                            <FieldHeader 
                                    title="resources.assistants.fields.unavailable_instruction" 
                                    description="resources.assistants.descriptions.unavailable_instruction" 
                                />
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                value={currentRule.unavailable_instruction}
                                onChange={(e) => handleInstructionChange('unavailable_instruction', e.target.value)}
                                placeholder={translate('resources.assistants.placeholders.unavailable_instruction')}
                            />
                        </FormControl>
                    </Grid>

                    {/* Availability Section */}
                    <Grid item xs={12}>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <FieldHeader 
                                    title="resources.assistants.fields.availability" 
                                    description="resources.assistants.descriptions.availability" 
                                />
                            {/* Add error alert for availability validation errors */}
                            {validationErrors.availability && Array.isArray(validationErrors.availability) && 
                             validationErrors.availability.length > 0 && (
                                <Alert severity="error" sx={{ mb: 2 }}>
                                    {translate('resources.assistants.errors.availability_overlap')}
                                </Alert>
                            )}

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                {currentRule.availability.map((schedule, scheduleIndex) => (
                                    <Box 
                                        key={scheduleIndex} 
                                        sx={{ 
                                            mb: 3, 
                                            p: 2, 
                                            border: '1px solid', 
                                            borderColor: 'divider',
                                            borderRadius: 1,
                                            position: 'relative'
                                        }}
                                    >
                                        <IconButton 
                                            onClick={() => handleRemoveAvailability(scheduleIndex)}
                                            sx={{ position: 'absolute', top: 8, right: 8 }}
                                            size="small"
                                        >
                                            <DeleteIcon />
                                        </IconButton>

                                        <FormControl 
                                            fullWidth 
                                            error={!!validationErrors.availability?.[scheduleIndex]?.days}
                                            sx={{ mb: 2 }}
                                        >
                                            <FormLabel component="legend">
                                                {translate('resources.assistants.days.label')}
                                            </FormLabel>
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                                                {DAYS_OF_WEEK.map(day => (
                                                    <Chip
                                                        key={day.id}
                                                        label={translate(`resources.assistants.days.${day.id}`)}
                                                        onClick={() => handleDayToggle(scheduleIndex, day.id)}
                                                        color={schedule.days.includes(day.id) ? "primary" : "default"}
                                                        variant={schedule.days.includes(day.id) ? "filled" : "outlined"}
                                                    />
                                                ))}
                                            </Box>
                                            {validationErrors.availability?.[scheduleIndex]?.days && (
                                                <FormHelperText error>
                                                    {translate(validationErrors.availability[scheduleIndex].days)}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                        
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                            {is24HourModes[scheduleIndex] ? (
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    <Typography variant="body2" color="primary">
                                                        <AccessTimeIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 0.5 }} />
                                                        {translate('resources.assistants.working_hours.active_24_hours')}
                                                    </Typography>
                                                    <Button
                                                        size="small"
                                                        onClick={() => disableSet24HourMode(scheduleIndex)}
                                                        variant="text"
                                                    >
                                                        {translate('resources.assistants.working_hours.customize')}
                                                    </Button>
                                                </Box>
                                            ) : (
                                                <Button
                                                    size="small"
                                                    onClick={() => set24HourTimeRange(scheduleIndex)}
                                                    startIcon={<AccessTimeIcon />}
                                                    variant="outlined"
                                                >
                                                    {translate('resources.assistants.working_hours.set_24_hours')}
                                                </Button>
                                            )}
                                        </Box>

                                        {!is24HourModes[scheduleIndex] && (
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6}>
                                                    <FormControl 
                                                        fullWidth 
                                                        error={!!validationErrors.availability?.[scheduleIndex]?.start_time || 
                                                               !!validationErrors.availability?.[scheduleIndex]?.time_range}
                                                    >
                                                        <FormLabel>
                                                            {translate('resources.assistants.working_hours.start_time')}
                                                        </FormLabel>
                                                        <TimePicker
                                                            value={dayjs(`2022-01-01T${schedule.start_time || '00:00'}`)}
                                                            onChange={(newValue) => handleTimeChange(scheduleIndex, 'start_time', newValue)}
                                                            format="HH:mm"
                                                            slotProps={{ 
                                                                textField: { 
                                                                    fullWidth: true, 
                                                                    margin: 'normal',
                                                                    error: !!validationErrors.availability?.[scheduleIndex]?.start_time || 
                                                                           !!validationErrors.availability?.[scheduleIndex]?.time_range,
                                                                    helperText: validationErrors.availability?.[scheduleIndex]?.start_time || 
                                                                               (validationErrors.availability?.[scheduleIndex]?.time_range && 
                                                                                translate(validationErrors.availability[scheduleIndex].time_range)) || ''
                                                                } 
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <FormControl 
                                                        fullWidth 
                                                        error={!!validationErrors.availability?.[scheduleIndex]?.end_time || 
                                                               !!validationErrors.availability?.[scheduleIndex]?.time_range}
                                                    >
                                                        <FormLabel>
                                                            {translate('resources.assistants.working_hours.end_time')}
                                                        </FormLabel>
                                                        <TimePicker
                                                            value={dayjs(`2022-01-01T${schedule.end_time || '00:00'}`)}
                                                            onChange={(newValue) => handleTimeChange(scheduleIndex, 'end_time', newValue)}
                                                            format="HH:mm"
                                                            slotProps={{ 
                                                                textField: { 
                                                                    fullWidth: true, 
                                                                    margin: 'normal',
                                                                    error: !!validationErrors.availability?.[scheduleIndex]?.end_time || 
                                                                           !!validationErrors.availability?.[scheduleIndex]?.time_range,
                                                                    helperText: validationErrors.availability?.[scheduleIndex]?.end_time || ''
                                                                } 
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Box>
                                ))}
                                
                                <Button 
                                    startIcon={<AddIcon />}
                                    onClick={handleAddAvailability}
                                    sx={{ mt: 1, alignSelf: 'flex-start' }}
                                    color="primary"
                                    variant="outlined"
                                >
                                    {translate('resources.assistants.actions.add_availability')}
                                </Button>
                            </LocalizationProvider>
                        </FormControl>
                    </Grid>

                    {/* Closed Dates Section */}
                    <Grid item xs={12}>
                        <FormControl 
                            fullWidth
                            error={!!validationErrors.closed_dates}
                        >
                            <FieldHeader 
                                    title="resources.assistants.fields.closed_dates" 
                                    description="resources.assistants.descriptions.closed_dates" 
                                />
                            {validationErrors.closed_dates && (
                                <FormHelperText error>
                                    {translate(validationErrors.closed_dates)}
                                </FormHelperText>
                            )}

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                {currentRule.closed_dates.map((date, dateIndex) => (
                                    <Box key={dateIndex} sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                                        <DatePicker
                                            value={dayjs(date)}
                                            onChange={(newValue) => handleClosedDateChange(dateIndex, newValue)}
                                            slotProps={{ 
                                                textField: { 
                                                    fullWidth: true,
                                                    margin: 'normal'
                                                } 
                                            }}
                                        />
                                        <IconButton 
                                            onClick={() => handleRemoveClosedDate(dateIndex)}
                                            sx={{ ml: 1, mt: 1 }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                ))}
                                
                                <Button 
                                    startIcon={<AddIcon />}
                                    onClick={handleAddClosedDate}
                                    sx={{ mt: 1, alignSelf: 'flex-start' }}
                                    color="primary"
                                    variant="outlined"
                                >
                                    {translate('resources.assistants.actions.add_closed_date')}
                                </Button>
                            </LocalizationProvider>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="inherit">
                    {translate('resources.generic.cancel')}
                </Button>
                <Button 
                    onClick={handleSave}
                    variant="contained" 
                    color="primary"
                    disabled={isSaving}
                >
                    {translate('resources.generic.save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TransferRuleModal;