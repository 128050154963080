import { useListContext, useTranslate } from 'react-admin';
import { Box, Button, Typography, useTheme, useMediaQuery, Select, MenuItem } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

const CustomPagination = () => {
    const theme = useTheme();
    const { page, setPage, total, perPage, setPerPage } = useListContext();
    const totalPages = Math.ceil(total / perPage);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const translate = useTranslate();

    const handlePerPageChange = (event: any) => {
        setPerPage(event.target.value);
        setPage(1); // Reset to first page when changing items per page
    };

    const getPageNumbers = () => {
        const pages = [];
        
        if (isMobile) {
            // Mobile: Show current page and immediate neighbors
            if (page > 1) pages.push(page - 1);
            pages.push(page);
            if (page < totalPages) pages.push(page + 1);
            return pages;
        }

        // Desktop: Show more pages
        const delta = 2;
        pages.push(1);
        let start = Math.max(2, page - delta);
        let end = Math.min(totalPages - 1, page + delta);

        if (start > 2) pages.push('...');
        for (let i = start; i <= end; i++) {
            pages.push(i);
        }
        if (end < totalPages - 1) pages.push('...');
        if (totalPages > 1) pages.push(totalPages);

        return pages;
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between',
                padding: theme.spacing(1),
                gap: theme.spacing(1),
                alignItems: 'center',
                color: theme.palette.common.white,
                maxWidth: '100%',
                margin: '0 auto',
                borderBottomLeftRadius: theme.shape.borderRadius,
                borderBottomRightRadius: theme.shape.borderRadius,
                borderBottom: `1px solid ${theme.palette.divider}`,
                borderLeft: `1px solid ${theme.palette.divider}`,
                borderRight: `1px solid ${theme.palette.divider}`,
                width:'100%',
                position: 'relative',
            }}>
                <Box sx={{ 
                    flex: isMobile ? 'none' : 1,
                    display: 'flex', 
                    justifyContent: 'center',
                    minWidth: isMobile ? 'auto' : 'fit-content'
                }}>
                    <Button 
                        onClick={() => setPage(page - 1)} 
                        disabled={page <= 1}
                        sx={{ 
                            color: '#808080', 
                            textTransform: 'none',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            minWidth: isMobile ? '40px' : 'auto',
                            padding: isMobile ? '6px' : 'auto',
                        }}
                    >
                        <ChevronLeft /> 
                        {!isMobile && 'Previous'}
                    </Button>
                </Box>

                <Box sx={{ 
                    flex: isMobile ? 1 : 2,
                    display: 'flex', 
                    gap: 1,
                    alignItems: 'center', 
                    justifyContent: 'center',
                    margin: '0 auto',
                    flexWrap: 'nowrap',
                }}>
                    {getPageNumbers().map((pageNum, index) => (
                        <Typography
                            key={index}
                            onClick={() => pageNum !== '...' ? setPage(Number(pageNum)) : undefined}
                            sx={{
                                cursor: pageNum !== '...' ? 'pointer' : 'default',
                                fontWeight: page === pageNum ? 'bold' : 'normal',
                                color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
                                fontSize: '14px',
                                width: '32px',
                                height: '32px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                ...(page === pageNum && {
                                    border: '1px solid #000000',
                                    borderColor: theme.palette.divider,
                                    borderRadius: '4px',
                                }),
                            }}
                        >
                            {pageNum}
                        </Typography>
                    ))}
                </Box>

                <Box sx={{ 
                    flex: isMobile ? 'none' : 1,
                    display: 'flex', 
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 1,
                    minWidth: isMobile ? 'auto' : 'fit-content'
                }}>
                    <Button 
                        onClick={() => setPage(page + 1)} 
                        disabled={page >= totalPages}
                        sx={{ 
                            color: '#808080',
                            textTransform: 'none',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            minWidth: isMobile ? '40px' : 'auto',
                            padding: isMobile ? '6px' : 'auto',
                        }}
                    >
                        {!isMobile && 'Next'} 
                        <ChevronRight />
                    </Button>
                    <Select
                        value={perPage}
                        onChange={handlePerPageChange}
                        size="small"
                        sx={{ ml: 2 }}
                    >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                    </Select>
                    {!isMobile && (
                        <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
                            {translate('resources.generic.per_page')}
                        </Typography>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default CustomPagination;
