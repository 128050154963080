import { Menu, MenuProps, usePermissions, useTranslate } from 'react-admin';
import { JSX } from 'react/jsx-runtime';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { styled } from '@mui/material/styles';
import { Box, Divider } from '@mui/material';
import { Typography } from '@mui/material';

const StyledMenu = styled(Menu)(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',

    '& .MuiList-root': {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
    },

    '&.RaMenu-closed': {
        '& .MuiMenuItem-root': {
            justifyContent: 'center',
            minWidth: '100%',
            fontSize: 0,
            '& .MuiListItemIcon-root': {
                margin: 0,
                minWidth: 'auto',
                display: 'flex',
                justifyContent: 'center',
            },
        },
        '& .MuiTypography-root': {
            display: 'none',
        },
    },

    '& .MuiMenuItem-root': {
        color: theme.palette.mode === 'dark' ? '#ffffff' : '#172121',
        transition: 'all 0.2s ease',
        marginBottom: theme.spacing(2),

        '& .MuiListItemIcon-root': {
            color: 'inherit',
        },

        '&:hover': {
            color: theme.palette.primary.main,
        },

        '&.RaMenuItemLink-active': {
            color: `${theme.palette.primary.main} !important`,
            fontWeight: 'bold',
            '&:hover': {
                color: `${theme.palette.primary.main} !important`,
            },
        },
    },
}));

const MenuLabel = styled(Typography)(({ theme }) => ({
    color: theme.palette.mode === 'dark' ? '#ffffff' : theme.palette.text.secondary,
    fontWeight: 500,
    fontSize: '0.75rem',
    padding: theme.spacing(1, 2),
    marginTop: theme.spacing(1),
}));

const CustomMenu = (props: JSX.IntrinsicAttributes & MenuProps) => {
    const { permissions } = usePermissions();
    const translate = useTranslate();
    
    return (
        <StyledMenu {...props}>
            <Menu>
                <Menu.DashboardItem />
                <Menu.ResourceItem name="tasks" />
                <Menu.ResourceItem name="conversations" />
                <Menu.ResourceItem name="assistants" />

                {["admin", "partner"].includes(permissions) && (
                    <div>
                        <Box sx={{ px: 2 }}>
                            <Divider sx={{ my: 2 }} />
                        </Box>
                        <MenuLabel>{translate('resources.generic.partner_tools')}</MenuLabel>
                        <Menu.ResourceItem name="partners" />
                        <Menu.ResourceItem name="customers" />
                        <Menu.ResourceItem name="prompt-templates" />
                        {permissions === 'admin' && (
                            <Menu.Item
                                to="/onboarding"
                                primaryText="Onboarding"
                                leftIcon={<SettingsSuggestIcon />}
                            />
                        )}
                    </div>
                )}
            </Menu>
        </StyledMenu>
    );
};

export default CustomMenu;

