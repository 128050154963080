import { Typography } from '@mui/material';
import { useResourceContext, useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';

export const ResourceTitle = () => {
    const translate = useTranslate();
    const resource = useResourceContext();
    const location = useLocation();

    const formatPathName = (path: string) => {
        const lastSegment = path.split('/').pop() || 'Dashboard';
        return lastSegment === '' ? 'Dashboard' : lastSegment
            .split('-')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    return (
        <Typography
            variant="h3"
            fontWeight="bold"
            sx={{
                mb: 1,
                display: { xs: 'none', sm: 'block' }
            }}
        >
            {resource 
                ? translate(`resources.${resource}.overview`)
                : formatPathName(location.pathname)}
        </Typography>
    );
};