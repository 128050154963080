// in src/i18nProvider.js
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import dutchMessages from 'ra-language-dutch';
import { email, I18N_CHANGE_LOCALE } from 'react-admin';

export const en_US = {
    ...englishMessages,
    app: {
        auth: {
            verify: "Verify",
            resend_code: "Resend Code",
            code_resent: "Verification code resent successfully",
            sign_in_to_your_account: 'Sign in to your account',
            email: 'Email',
            forgot_password: 'Forgot Password?',
            send_reset_instructions: 'Send Reset Instructions',
            back_to_login: 'Back to Login',
            if_account_exists: "If the email address you entered matches one in our records, " +
            "we've sent you instructions to reset your password.",            
            reset_password: 'Reset Password',
            new_password: 'New Password',
            confirm_password: 'Confirm Password',
            invalid_reset_token: 'Invalid reset token',
            passwords_do_not_match: 'Passwords do not match',
            password_reset_success: 'Password reset successfully',
            password_reset_failed: 'Password reset failed',
            password_reset_instructions_sent: 'Password reset instructions sent to your email',
            errors: {
                'INVALID_CREDENTIALS': 'Invalid email or password',
                'USER_NOT_FOUND': 'No account found with this email',
                'INVALID_RESET_TOKEN': 'Password reset link is invalid or has expired',
                'PASSWORD_TOO_WEAK': 'Password is too weak',
                'PASSWORDS_DO_NOT_MATCH': 'Passwords do not match',
                'SAME_PASSWORD': 'New password must be different from your current password',
                'EMAIL_EXISTS': 'An account with this email already exists',
                'GENERIC_ERROR': 'An error occurred. Please try again later.',
                'unknown': 'An error occurred. Please try again later.',
                'MAX_ATTEMPTS_REACHED': 'Maximum verification attempts reached. Please wait 10 minutes before requesting a new code.',
                'INVALID_CODE': 'The verification code you entered is incorrect. Please try again.',
                'VERIFICATION_FAILED': 'Verification failed. Please try again later.',
                'MAX_SEND_ATTEMPTS_REACHED': 'Too many code requests. Please wait 10 minutes before requesting a new code.',
                'CODE_SEND_FAILED': 'Failed to send verification code. Please try again later.',
                'INCORRECT_CREDENTIALS': 'Incorrect username or password',
            },
            verify_2fa: "Two-Factor Authentication",
            enter_verification_code: "Enter the verification code sent to your device",
            phone_number: 'Phone Number',
            incorrect_code: 'The verification code you entered is incorrect. Please try again.',
            check_mail: "Check your email",
        },
        validation: {
            required: 'Required',
            minLength: 'Password must be at least 8 characters',
            maxLength: 'Password must be less than 64 characters',
            password: {
                uppercase: 'Password must contain at least one uppercase letter',
                lowercase: 'Password must contain at least one lowercase letter',
                number: 'Password must contain at least one number',
                special: 'Password must contain at least one special character (!@#$%^&*)',
                same: 'New password must be different from old password',
                mismatch: 'Passwords do not match'
            },
            invalid_phone: 'Please enter a valid phone number',
            phone_too_short: 'Phone number must be at least 9 digits',
            phone_format: 'Enter phone number in E.164 format (e.g., +1234567890)',
            phone_number_required_for_2fa: 'Phone number is required for 2-factor authentication',
        },
        message: {
            password: {
                create: 'Create a strong password with at least 8 characters',
                reset: 'Enter your new password',
                change: 'Enter a new password different from your current one',
                confirmation: 'Please confirm your password'
            },
            invalid_form: 'The form is not valid. Please check your input'
        },
        converting_image: "Converting image to a supported format...",
        image_converted_and_valid: "Image converted and validated successfully"
    },
    resources: {
        languages: { 
            nl_NL: "Dutch (Netherlands)",
            nl_BE: "Dutch (Belgium)",
            en_US: "English (United States)",
            es_ES: "Spanish (Spain)",
            de_DE: "German (Germany)",
            en_AU: "English (Australia)",
            en_UK: "English (United Kingdom)",
            el_GR: "Greek (Greece)",
            zh_CN: "Chinese (Mandarin, simplified)",
            fr_FR: "French (France)",
            fr_BE: "French (Belgium)",
            tr_TR: "Turkish (Turkey)",
            da_DK: "Danish (Denmark)",
            sv_SE: "Swedish (Sweden)",
            nb_NO: "Norwegian (Bokmål)",
            it_IT: "Italian (Italy)",
            pt_PT: "Portuguese (Portugal)",
            pl_PL: "Polish (Poland)",
            de_AT: "German (Austria)",
            de_CH: "German (Switzerland)",
        },        
        dashboard: {
            name: 'Dashboard',
            welcome_back: 'Welcome back',
            subheader: 'Here is a quick snapshot of Bonnie\'s performance.'
        },
        partners: {
            name: 'Partner |||| Partners',
            overview: 'Partners',
        },
        customers: {
            name: 'Customer |||| Customers',
            overview: 'Customers',
            fields: {
                name: 'Name',
                created_at: 'Date',
                users: 'User |||| Users',
                assistants: 'Assistant',
                overview: 'Customers',
            }
        },
        "prompt-templates": {
            name: 'Prompt Template |||| Prompt Templates',
            overview: 'Prompt Templates',
        },
        generic: {
            save: 'Save',
            close: 'Close',
            copy: 'Copy',
            remove: 'Remove',
            save_and_close: 'Save & Close',
            settings: 'Settings',
            locale: 'Language',
            support: 'Support',
            billing: 'Billing',
            users: 'Users',
            my_profile: 'My Profile',
            profile_updated: 'Your profile has been updated',
            unavailable: 'Not available',
            toggle_theme: 'Toggle between light and dark mode',
            hide_filters: "Hide filters",
            show_filters: "Show filters",
            open_filters: "Open filters",
            filters: "Filters",
            clear_filters: "Clear filters",
            customer_tools: "Customer Tools",
            partner_tools: "Partner Tools",
            switch_to_light: "Light mode",
            switch_to_dark: "Dark mode",
            units: {
                hours: "hours",
                minutes: "minutes",
                calls: "calls"
            },
            date_shortcuts: {
                last_7_days: 'Last 7 days',
                last_30_days: 'Last 30 days',
                last_90_days: 'Last 90 days',
                this_year: 'This year',
                last_year: 'Last year',
                last_365_days: 'Last 365 days',
            },
            cancel: "Cancel",
            apply_filters: "Apply filters",
            showing_data: "Showing data for", 
            over: "over",
            at: "at",
            all_assistants: "all assistants",
            per_page: "per page",
        },
        users: {
            name: 'User |||| Users',
            empty: 'No Users yet.',
            invite: 'Do you want to add one?',
            edit: 'Edit User',
            new_password: 'New Password',
            new_password_confirm: 'Confirm Password',
            fields: {
                first_name: 'First name',
                last_name: 'Last name',
                email: 'Email',
                role: 'Role',
                phone_number: 'Phone Number',
                requires_2fa: 'Enable 2-factor authentication'
            }, 
        },
        filters: {
            assistant: 'Assistant',
            date_range: 'Date Range',
            outcome: 'Outcome',
            customer: 'Customer',
            tags: 'Tags',
            topic: 'Topic',
            contact: 'Contact',
            content: 'Content',
        },
        conversation_notes: {
            add_note: 'Add Note',
            edit_note: 'Edit Note',
            back_to_conversations: 'Back to Conversations',
            fields: {
                note: 'Note',
                content: 'Content',
                created_at: 'Date',
                created_by: 'Created by',
                is_private: 'Only visible to me',
                status: 'Status',
                assigned_to: 'Assigned to',
            },
            statuses: {
                open: 'Open',
                closed: 'Closed',
                none: 'None',
            },
        },             
        conversations: {
            recording: 'Recording',
            overview: 'Conversations',
            role: 'Role',
            content: 'Content',
            tag_updated: 'Tag updated',
            add_tag: 'Add tag',
            no_conversations: 'No conversations to show.',
            conversation_details: 'Conversation details',
            fields: {
                assistant_id: 'Assistant',
                summary: 'Summary',
                details: 'Details',
                phone_number: 'Phone Number',
                from_name: 'Name',
                duration: 'Duration',
                created_at: 'Created At',
                topic: 'Topic',
                result: 'Result',    
                outcome: 'Outcome',
                tags: 'Tags',
                accessible_open_notes_count: 'Open notes',
                has_open_notes: 'Open notes',
                name: 'Name',
                transcript: 'Transcript',
                notes: 'Notes',
                admin: 'Admin',
                rating: 'Rating',
            },
            results: {
                all: 'All',
                success: 'Success',
                failure: 'Failure',
                transferred: 'Transferred',
                none: 'None',
                null: 'None',
                followup: 'Follow-up',
            },
            outcomes: {
                all: 'All',
                none: 'None',
                null: 'None',
                completed: 'Completed',
                caller_follow_up: 'Caller Follow-Up',
                business_follow_up: 'Business Follow-Up',
                transferred: 'Transferred',
                information_provided: 'Information Provided',
                no_action: 'No Action',
                answered_by_business: 'Answered by Business',
                missed_by_business: 'Missed by Business',
            },
            'copy_url': 'Copy URL',
            'copy_messages': 'Copy messages',
            'url_copied': 'URL to this conversation has been copied to clipboard',
            'messages_copied': 'Messages have been copied to clipboard',
            'delete': 'Delete',
            'deleted': 'Conversation deleted',
            'delete_error': 'Failed to delete conversation',
            stats: {
                duration_title: 'Call duration',
                count_title: 'Number of calls',
                duration: 'Duration',
                count: 'Count',
                duration_label: 'Time',
                count_label: 'Number of calls',
                direct_calls: 'Handled by Bonnie',
                transferred_calls: 'Transferred calls',
                call_duration: 'Call duration Bonnie',
                transfer_duration: 'Transfer duration',
                total_calls: "Calls",
                total_call_time: "Call time",
                total_transfers: "Transfers",
                total_transfer_time: "Transfer time",
                transfer_count_percentage: "Transfer rate",
                transfer_duration_percentage: "Transfer time rate",
                total_call_time_help: "The total call time of all conversations",
                total_calls_help: "The total number of conversations handled",
                transfer_count_percentage_help: "The percentage of conversations that were transferred to a human agent",
                transfer_duration_percentage_help: "The percentage of total conversation time spent on transferred conversations",
                total_messages: "Messages",
                total_messages_help: "The total number of messages exchanged in all conversations",
            },
            tabs: {
                overview: 'Overview',
                transcript: 'Transcript',
                notes: 'Notes',
                admin: 'Admin',
            },
            unknown: 'Unknown',
            no_summary: 'No summary available',
            add_note: 'Add a note',
        },
        tasks: {
            name: 'Task |||| Tasks',
            overview: 'Tasks',
        },
        "assistants": {
            "name": "Assistant |||| Assistants",
            "edit": "Edit Assistant",
            "overview": "Assistants",
            "banner_prompt_components_line1": "Our Assistant configuration has changed. New fields have been introduced to separate instructions into distinct categories, making it more structured and easier to manage.",
            "banner_prompt_components_note": "Note:",
            "banner_prompt_components_line2": "If you do not update these fields, everything will continue to work as it is. However, using these new options will simplify maintaining and updating your settings.",
            "fields": {
                "name": "Name",
                "from_id": "Caller ID",
                "prompt": "Call Handling Instructions",
                "post_call_prompt": "Post-Call Instructions",
                "post_call_prompt_help_text": "These instructions are used after the call has completed",
                "category": "Category",
                "default_language": "Greeting Language",
                "additional_languages": "Additional Supported Languages",
                "ai_model": "AI model",
                "config": "Custom configuraton",
                "timezone": "Business Timezone",
                "created_at": "Created At",
                "notification_outcomes": "Notification triggers",
                "notification_user": "Recipient of notifications",
                "config.notifications.include_transcript": "Include call transcript in email",
                "whatsapp_image": "WhatsApp profile image",
                "greeting": "Greeting message",
                "language": "Language",
                "lexicon": "Pronunciation",
                "edit_pronunciations": "Edit pronunciations",
                "tasks": "Tasks",
                "deployment": "Deployment",
                "caller_id": "Linked phone number",
                "fallback_number": "Fallback Number",
                "customer_id": "Customer ID",
                "prompt_scenarios.transfer_policy": "Call Transfer Policy",
                "prompt_scenarios.call_policy": "Call Handling Policies & Guidelines",
                "prompt_scenarios.faq": "Frequently Asked Questions (FAQs)",
                "prompt_scenarios.context": "Context for the call",
                "prompt_scenarios.business_information": "Business Details",
                "prompt_scenarios.role": "Assistant's role",          
                "config.stt.interruption": "Allow interruption",
                "config.stt.backend": "Speech-to-Text backend",
                "config.tools.formitable.config.api_key": "Formitable API Key",
                "config.tools.formitable.config.restaurant_id": "Formitable Restaurant ID",
                "config.tts.use_human_time_format": "Use Human Time Format",
                "phone_number_id": "Phone Number",
                "config.daily_report.enabled": "Daily Report",
                "config.daily_report.schedule": "Schedule",
                "config.daily_report.user_recipient": "Daily Report Recipient",
                "google_place_id": "Google Place ID",
                "voice": "Assistant's Voice",
                "opening_hours": "Opening Hours",
                "show_opening_hours": "Use opening hours from Google",
                "answer_schedule": "Answer schedule",
                "image_valid": "Image is valid",
                "validating_image": "Validating image...",
                "retry_validation": "Retry",
                "image_validation_required": 'Image validation is required',
                "image_validation_failed": 'Image validation failed',
                "transfer_rules": "Transfer rules",
                "transfer_destination": "Destination",
                "transfer_instructions": "Transfer instructions",
                "transfer_reason": "Transfer reason for this destination",
                "transfer_reasons": "Transfer reason(s) for this destination",
                "closed_instruction": "Destination closed",
                "unavailable_instruction": "Destination unavailable",
                "edit_transfer_rule": "Edit Transfer Rule",
                "add_schedule": "Add Schedule",
                "closed_dates": "Closed Dates",
                "add_new_rule": "Add new rule",
                "use_transfer_rules": "Use transfer rules",
                "schedule": "Schedule",
                "availability": "Availability",
                "instructions": "Instructions for when the transfer failed",
                "start_time": "Start Time",
                "end_time": "End Time",
                "days": "Days",
                "transfer_purpose": "Transfer purpose",
                "no_transfer_rules": "No transfer rules configured",
            },
            "descriptions": {
                "name": "Enter the name of your business as it will be used by the assistant",
                "timezone": "Select the timezone your business operates in",
                "whatsapp_image": "Upload the image that will be used when sending WhatsApp messages",
                "greeting": "Enter the greeting message that will be used at the beginning of conversations.",
                "default_language": "Select the language in which the caller will be greeted",
                "additional_languages": "Select additional languages the user can switch to during the call. Limit this to the most common languages spoken by your customers to avoid the possibility of switching to an incorrect language",
                "lexicon": "Enter the pronunciation of words that the assistant may not pronounce correctly",
                "prompt": "Provide detailed instructions for the tasks that Bonnie should perform during the call",
                "notification_outcomes": "Select the outcomes that should trigger a notification to the specified user",
                "notification_user": "Specify the user who will receive all notifications",
                "deployment": "Details about the deployment configuration",
                "caller_id": "The phone number for this assistant",
                "fallback_number": "The fallback number to be used if the main number is unreachable",
                "customer_id": "The customer associated with this assistant",
                "config": "Custom configuration for the assistant",
                "config.notifications.include_transcript": "Choose whether to include the call transcript in the email notification",
                "config.stt.interruption": "Choose whether to allow the caller to interrupt the assistant while it is speaking",
                "config.stt.backend": "Select the backend to use for speech-to-text conversion",
                "config.tools.formitable.config.api_key": "Enter the API key for Formitable",
                "config.tools.formitable.config.restaurant_id": "Enter the restaurant ID for Formitable",
                "prompt_scenarios.transfer_policy": "Specify the conditions under which calls should be transferred and the destination for the transfer",
                "prompt_scenarios.call_policy": "Outline the general call handling policies and guidelines that Bonnie should follow during interactions with callers",
                "prompt_scenarios.faq": "Add common questions and their answers here to assist Bonnie in providing accurate information to callers",
                "prompt_scenarios.context": "Add context for the call here, e.g. date, time and caller number",
                "prompt_scenarios.business_information": "Provide key information about your business, including operating hours, address, website, and any other relevant details that Bonnie should know to assist during or after calls",
                "prompt_scenarios.role": "Describe the role and purpose of the assistant",                
                "config.tts.use_human_time_format": "This setting converts numeric time formats to a natural language format. For example, instead of '14:00', it will be pronounced as 'two o'clock'.",
                "phone_number_id": "Phone Number that is linked to this assistant",
                "config.daily_report.enabled": "Enable daily report sending for this assistant",
                "config.daily_report.schedule": "Set the time when the daily report should be sent",
                "config.daily_report.notification_user": "User who will receive the daily report",
                "google_place_id": "Select your business location",
                "voice": "Select the voice that the assistant will use during conversations",
                "opening_hours": "Control whether to display opening hours information",
                "answer_schedule": "Determine who answers incoming calls and when. By default, Bonnie answers all calls, but you can set rules to forward calls to your phone, with or without Bonnie as backup.",
                "transfer_rules": "Instructions for handling calls when the destination is unavailable",
                "transfer_reason": "Specify the reason or situation for when this transfer number should be used",
                "closed_instruction": "Instructions for handling calls when the business is closed",
                "unavailable_instruction": "Instructions for handling calls when the transfer destination is unavailable",
                "transfer_reasons": "Here you can specify the reasons for which calls should be transferred and the destination for the transfer",
                "transfer_destination": "Specify the phone number destination for the transfer",
                "availability": "Specify the days and times when the destination is available to accept calls",
                "closed_dates": "Dates when the destination is not available to accept calls",
            },
            "days": {
                    "label": "Days of the week",
                    "1": "Monday",
                    "2": "Tuesday",
                    "3": "Wednesday",
                    "4": "Thursday",
                    "5": "Friday",
                    "6": "Saturday",
                    "7": "Sunday"
                },
            "working_hours": {
                "edit_title": "Edit Working Hours",
                "not_configured": "No working hours configured",
                "rules": "Working Hours Rules",
                "add_rule": "Add new rule",
                "add_schedule": "Add new schedule",
                "edit_rule": "Edit Rule",
                "no_rules": "No working hours configured yet. The assistant will answer all calls.",
                "forward_to": "Forward to",
                "recurring": "Recurring schedule",
                "forward_number": "Forward number",
                "forward_number_help": "Phone number to forward calls to during this time period",
                "forward_number_description": "Enter the phone number that will receive forwarded calls during this time period",
                "dial_timeout": "Dial timeout",
                "dial_timeout_help": "How many seconds to wait before considering the call unanswered",
                "dial_timeout_description": "Set how many seconds to wait for an answer before considering the call unanswered",
                "start_time": "Start time",
                "start_time_description": "The time when this rule begins to apply each day",
                "end_time": "End time",
                "end_time_description": "The time when this rule stops applying each day",
                "start_date": "Start date",
                "start_date_description": "The date when this rule begins to apply",
                "end_date": "End date",
                "end_date_description": "The date when this rule stops applying",
                "set_24_hours": "Set 24 hours",
                "active_24_hours": "Active 24 hours a day",
                "customize": "Customize hours",
                "mode": {
                    "label": "Handling mode",
                    "client_first": "Ring business phone number first, then connect to Bonnie",
                    "client_only": "Ring business phone number only",
                    "assistant_first": "Connect to Bonnie first",
                    "description": "Choose how incoming calls should be handled during this time period"
                },
                "error": {
                    "no_days": "Please select at least one day",
                    "overlap": "This rule overlaps with an existing rule. Working hours rules cannot overlap.",
                    "no_start_date": "Please select a start date",
                    "no_end_date": "Please select an end date",
                    "no_forward_number": "Please provide a forward number",
                    "invalid_forward_number": "The forward number is invalid",
                    "no_dial_timeout": "Please specify a dial timeout",
                    "invalid_dial_timeout": "The dial timeout must be between 1 and 60 seconds",
                    "no_start_time": "Please select a start time",
                    "no_end_time": "Please select an end time",
                }
                
            },
            validation: {
                "missing_time": "Time is required",
                "invalid_time_range": "Invalid time range",
                "empty_schedule_days": "Days are required",
            },
            placeholders: {
                "transfer_reason": "Example: Event request Or: Lost item",
                "closed_instruction": "Example: Tell caller the office is closed and offer to pass a message.",
                "unavailable_instruction": "Example: Tell caller the call was not answered and offer their call can be returned later.",
            },
            try: {
                send: 'Send',
                type_your_message: 'Type your message',
                update: 'Update',
                edit_message: 'Edit Message',
            },
            errors: {
                invalid_phone: 'Invalid phone number',
                empty_schedule_days: 'Select at least one day',
                schedule_overlap: 'Schedules cannot overlap for the same days',
                missing_time: 'Start and end time are required',
                invalid_time_range: 'End time must be after start time',
                duplicate_reason: 'Duplicate reason found',
                duplicate_reasons_found: 'Duplicate reasons found',
                at_least_one_reason_required: 'At least one reason must be provided for a valid transfer rule',
                validation_failed: 'There are errors in the form. Please check your input and try again.',
                availability_overlap: 'Availability schedules cannot overlap for the same days',
                empty_reasons_not_allowed: 'Empty reasons are not allowed',
                duplicate_dates_found: 'Duplicate dates found',
                required: 'Required',
            },
            general: 'General',
            call_flow: 'Call Flow',
            prompt: 'Prompt',
            notifications: 'Notifications',
            tabs: {
                "business_information": "About",
                "personality": "Personality",
                "tasks": "Instructions",
                "policies_and_context": "Policies & Context",
                "notifications": "Notifications",
                "tools": "Integrations",
                "admin": "Admin Settings", 
            },
            actions: {
                select_phone_number: 'Select Phone Number',
                add_availability: 'Add Availability',
                add_closed_date: 'Add Closed Date',
                add_reason: 'Add Reason',
            },
            phone_number: {
                confirm_title: 'Change Phone Number',
                confirm_content: 'Weet je zeker dat je het gekoppelde telefoonnummer wilt wijzigen?',
                no_options: "Geen beschikbare telefoonnummers gevonden"
            },
            loading_opening_hours: "Loading opening hours...",
            opening_hours_error: "Error fetching opening hours. Please try again.",
            opening_hours_not_found: "Opening hours not found for this location",
            opening_hours_server_error: "Server error while fetching opening hours. Please try again later.",
            opening_hours_invalid_format: "Invalid opening hours format received",
        },
        profile: {
            user_info: 'User Information',
            password_settings: 'Password Settings',
            system_preferences: 'System Preferences',
            theme: 'Theme',
        },
        tools: {
            'get_availability': 'Getting availability...',
            'get_available_products': 'Getting available products...',
            'find_bookings_by_phone_number': 'Finding existing bookings...',
            'create_booking': 'Creating reservation...',
            'update_booking': 'Updating reservation...',
            'cancel_booking': 'Canceling reservation...',
            'switch_language': 'Switching language...',
            'send_sms': 'Sending SMS...',
            'transfer_phone_call': 'Transferring call...',
            'save_conversation': 'Saving conversation...',
            'end_phone_call': 'Ending call...',
            'send_message_to_restaurant': 'Sending message to restaurant...',
            
        },
        "phone_numbers": {
            "name": "Phone Number |||| Phone Numbers",
            "fields": {
                "number": "Number",
                "assistant_id": "Assistant",
                "created_at": "Created At"
            }
        },
    },
};

export const en_AU = {
    ...en_US,
};

export const nl_NL = {
    ...dutchMessages,
    app: {
        auth: {
            verify: "Verifiëren",
            resend_code: "Code opnieuw versturen",
            code_resent: "Verificatiecode opnieuw verzonden",
            sign_in_to_your_account: 'Log in bij je account',
            email: 'Email',
            forgot_password: 'Wachtwoord vergeten?',
            send_reset_instructions: 'Verstuur reset instructies',
            back_to_login: 'Terug naar inloggen',
            if_account_exists: 'Als het e-mailadres dat u heeft ingevoerd overeenkomt met een adres in onze administratie, hebben wij u instructies gestuurd om uw wachtwoord opnieuw in te stellen.',
            reset_password: 'Wachtwoord resetten',
            new_password: 'Nieuw wachtwoord',
            confirm_password: 'Bevestig wachtwoord',
            invalid_reset_token: 'Ongeldige reset token',
            passwords_do_not_match: 'Wachtwoorden komen niet overeen',
            password_reset_success: 'Wachtwoord succesvol gereset',
            password_reset_failed: 'Wachtwoord resetten mislukt',
            password_reset_instructions_sent: 'Instructies voor het resetten van het wachtwoord zijn verzonden naar je e-mail',
            errors: {
                'INVALID_CREDENTIALS': 'Ongeldige email of wachtwoord',
                'USER_NOT_FOUND': 'Geen account gevonden met dit emailadres',
                'INVALID_RESET_TOKEN': 'De resetlink is ongeldig of verlopen',
                'PASSWORD_TOO_WEAK': 'Het wachtwoord is te zwak',
                'PASSWORDS_DO_NOT_MATCH': 'De wachtwoorden komen niet overeen',
                'SAME_PASSWORD': 'Nieuw wachtwoord moet verschillen van uw huidige wachtwoord',
                'EMAIL_EXISTS': 'Er bestaat al een account met dit emailadres',
                'GENERIC_ERROR': 'Er is een fout opgetreden. Probeer het later opnieuw.',
                'unknown': 'Er is een fout opgetreden. Probeer het later opnieuw.',
                'MAX_ATTEMPTS_REACHED': 'Maximaal aantal verificatiepogingen bereikt. Wacht 10 minuten voordat u een nieuwe code aanvraagt.',
                'INVALID_CODE': 'De verificatiecode die u heeft ingevoerd is onjuist. Probeer het opnieuw.',
                'VERIFICATION_FAILED': 'Verificatie mislukt. Probeer het later opnieuw.',
                'MAX_SEND_ATTEMPTS_REACHED': 'Te veel codeverzoeken. Wacht 10 minuten voordat u een nieuwe code aanvraagt.',
                'CODE_SEND_FAILED': 'Verificatiecode verzenden mislukt. Probeer het later opnieuw.',
                'INCORRECT_CREDENTIALS': 'Onjuiste gebruikersnaam of wachtwoord',
            },
            verify_2fa: "Twee-factor-authenticatie",
            enter_verification_code: "Voer de verificatiecode in die naar uw apparaat is verzonden",
            phone_number: "Telefoonnummer",
            incorrect_code: 'De verificatiecode die u heeft ingevoerd is onjuist. Probeer het opnieuw.',
            code_resent: "Verificatiecode opnieuw verzonden",
            check_mail: "Check uw e-mail",
        },
        validation: {
            required: 'Verplicht',
            minLength: 'Wachtwoord moet minimaal 8 tekens bevatten',
            maxLength: 'Wachtwoord mag maximaal 64 tekens bevatten',
            password: {
                uppercase: 'Wachtwoord moet minimaal één hoofdletter bevatten',
                lowercase: 'Wachtwoord moet minimaal één kleine letter bevatten',
                number: 'Wachtwoord moet minimaal één cijfer bevatten',
                special: 'Wachtwoord moet minimaal één speciaal teken bevatten (!@#$%^&*)',
                same: 'Nieuw wachtwoord moet verschillen van het oude wachtwoord',
                mismatch: 'Wachtwoorden komen niet overeen'
            },
            invalid_phone: 'Voer een geldig telefoonnummer in',
            phone_too_short: 'Telefoonnummer moet minimaal 9 tekens bevatten',
            phone_format: 'Voer het telefoonnummer in E.164-formaat in (bijv. +1234567890)',
            phone_number_required_for_2fa: 'Telefoonnummer is vereist voor 2-factor authenticatie',
        },
        message: {
            password: {
                create: 'Maak een sterk wachtwoord aan met minimaal 8 tekens',
                reset: 'Voer je nieuwe wachtwoord in',
                change: 'Voer een nieuw wachtwoord in dat verschilt van je huidige wachtwoord',
                confirmation: 'Bevestig je wachtwoord'
            },
            invalid_form: 'Het formulier is niet geldig. Controleer uw invoer'
        },
        converting_image: "Afbeelding converteren naar een ondersteund formaat...",
        image_converted_and_valid: "Afbeelding geconverteerd en succesvol gevalideerd"
    },
    resources: {
        languages: { // Toevoegen van taal vertalingen onder de resources
            nl_NL: "Nederlands (Nederland)",
            nl_BE: "Nederlands (België)",
            en_US: "Engels (Verenigde Staten)",
            es_ES: "Spaans (Spanje)",
            de_DE: "Duits (Duitsland)",
            en_AU: "Engels (Australië)",
            en_UK: "Engels (Verenigd Koninkrijk)",
            el_GR: "Grieks (Griekenland)",
            zh_CN: "Chinees (Mandarijn, vereenvoudigd)",
            fr_FR: "Frans (Frankrijk)",
            fr_BE: "Frans (België)",
            tr_TR: "Turks (Turkije)",
            da_DK: "Deens (Denemarken)",
            sv_SE: "Zweeds (Zweden)",
            nb_NO: "Noors (Bokmål)",
            it_IT: "Italiaans (Italië)",
            pt_PT: "Portugees (Portugal)",
            pl_PL: "Pools (Polen)",
            de_AT: "Duits (Oostenrijk)",
            de_CH: "Duits (Zwitserland)",
        },
        dashboard: {
            name: 'Dashboard',
            welcome_back: 'Welkom terug',
            subheader: 'Hier een snel overzicht van Bonnie\'s prestaties.',
        },
        generic: {
            save: 'Opslaan',
            close: 'Sluiten',
            copy: 'Kopiëren',
            remove: 'Verwijderen',
            save_and_close: 'Opslaan & sluiten',
            settings: 'Instellingen',  
            locale: 'Taal',
            support: 'Support',
            billing: 'Factuur',
            users: 'Gebruikers',
            my_profile: 'Mijn profiel',
            profile_updated: 'Uw profiel is bijgewerkt',
            unavailable: 'Niet beschikbaar',
            toggle_theme: 'Wissel tussen licht en donker thema',
            hide_filters: "Verberg filters",
            show_filters: "Toon filters",
            open_filters: "Open filters",
            filters: "Filters",
            clear_filters: "Wis filters",
            customer_tools: "Klant tools",
            partner_tools: "Partner tools",
            switch_to_dark: "Schakel naar donker thema",
            switch_to_light: "Schakel naar licht thema",
            units: {
                hours: "uur",
                minutes: "minuten",
                calls: "gesprekken"
            },
            date_shortcuts: {
                last_7_days: 'Laatste 7 dagen',
                last_30_days: 'Laatste 30 dagen',
                last_90_days: 'Laatste 90 dagen',
                this_year: 'Dit jaar',
                last_year: 'Afgelopen jaar',
                last_365_days: 'Laatste 365 dagen',
            },
            cancel: "Annuleren",
            apply_filters: "Toepassen",
            showing_data: "Gegevens voor",
            over: "tijdens",
            at: "bij",
            all_assistants: "alle assistenten",
            per_page: "per pagina",
        },
        users: {
            name: 'Gebruiker |||| Gebruikers',
            empty: 'Nog geen gebruikers.',
            invite: 'Wil je er een toevoegen?',
            edit: 'Bewerk Gebuiker',
            new_password: 'Nieuw wachtwoord',
            new_password_confirm: 'Bevestig wachtwoord',
            fields: {
                first_name: 'Voornaam',
                last_name: 'Achternaam',
                locale: 'Taal',
                email: 'E-mail',
                role: 'Rol',
                customer_id: 'Klant',
                phone_number: 'Telefoonnummer',
                requires_2fa: 'Activeer 2fa-authenticatie',
            },
        },
        filters: {
            assistant: 'Assistent',
            date_range: 'Datum',
            outcome: 'Resultaat',
            customer: 'Klant',
            tags: 'Tags',
            topic: 'Onderwerp',
            contact: 'Contact',
            content: 'Inhoud',
        },
        customers: {
            name: 'Klant |||| Klanten',
            overview: 'Klanten',
            fields: {
                name: 'Naam',
                created_at: 'Datum',
                users: 'Gebruiker |||| Gebruikers',
                assistants: 'Assistenten',
            }
        },
        "assistants": {
            "name": "Assistent |||| Assistenten",
            "overview": "Assistenten",
            "edit": "Assistent bewerken",
            "banner_prompt_components_line1": "Onze assistentconfiguratie is gewijzigd. Er zijn nieuwe velden geïntroduceerd om instructies in afzonderlijke categorieën te scheiden, waardoor het meer gestructureerd en gemakkelijker te beheren is.",
            "banner_prompt_components_note": "Opmerking:",
            "banner_prompt_components_line2": "Als u deze velden niet bijwerkt, blijft alles werken zoals het is. Het gebruik van deze nieuwe opties zal echter het onderhouden en bijwerken van uw instellingen vereenvoudigen.",
            "fields": {
                "name": "Bedrijfsnaam",
                "customer_id": "Klant ID",
                "category": "Categorie",
                "default_language": "Begroetingstaal",
                "additional_languages": "Extra ondersteunde talen",
                "lexicon": "Uitspraak",
                "edit_pronunciations": "Uitspraak bewerken",
                "ai_model": "AI model",
                "from_id": "Beller ID",
                "prompt": "Instructies voor gespreksafhandeling",
                "post_call_prompt": "Instructies na het gesprek",
                "post_call_prompt_help_text": "Deze instructies worden gebruikt nadat het gesprek is afgerond",
                "notification_user": "Ontvanger van meldingen",
                "email": "E-mail",
                "greeting": "Begroetingsbericht",
                "formitable_id": "Formitable ID",
                "config": "Aangepaste configuratie",
                "timezone": "Bedrijfstijdzone",
                "conversations": "Gesprekken",
                "created_at": "Datum",
                "notification_outcomes": "Meldingsactiveringen",
                "config.notifications.include_transcript": "Gespreksverslag in e-mail opnemen",
                "whatsapp_image": "WhatsApp-profielafbeelding",
                "tasks": "Taken",
                "post_call_tasks": "Taken voor na het gesprek",
                "deployment": "Deployment",
                "caller_id": "Gekoppeld telefoonnummer",
                "fallback_number": "Fallback Nummer",
                "prompt_scenarios.transfer_policy": "Beleid voor doorverbinden",
                "prompt_scenarios.call_policy": "Beleid en richtlijnen voor gespreksafhandeling",
                "prompt_scenarios.faq": "Veelgestelde vragen (FAQs)",
                "prompt_scenarios.context": "Context voor gesprek",
                "prompt_scenarios.business_information": "Bedrijfsgegevens",
                "prompt_scenarios.role": "Rol van de assistent",
                "config.stt.interruption": "Onderbreking toestaan",
                "config.stt.backend": "Speech-to-Text Backend",
                "config.tools.formitable.config.api_key": "Formitable API Key",
                "config.tools.formitable.config.restaurant_id": "Formitable Restaurant ID",
                "config.tts.use_human_time_format": "Gebruik menselijke tijdnotatie",
                "phone_number_id": "Telefoonnummer",
                "config.daily_report.enabled": "Dagelijkse rapport versturen inschakelen",
                "config.daily_report.schedule": "Stel de tijd in wanneer het dagelijkse rapport moet worden verzonden",
                "config.daily_report.notification_user": "Gebruiker die het dagelijkse rapport ontvangt",
                "config.daily_report.user_recipient": "Gebruiker die het dagelijkse rapport ontvangt",
                "google_place_id": "Google Place ID",
                "voice": "Stem van de assistent",
                "opening_hours": "Openingstijden",
                "show_opening_hours": "Gebruik openingstijden van Google",
                "answer_schedule": "Antwoord schema",
                "image_valid": 'Afbeelding is geldig',
                "validating_image": 'Afbeelding valideren...',
                "retry_validation": 'Opnieuw proberen',
                "image_validation_required": 'Beeldvalidatie is vereist',
                "image_validation_failed": 'Beeldvalidatie mislukt',
                "transfer_instructions": "Instructies bij doorverbinden",
                "transfer_reason": "Reden van doorverbinden",
                "transfer_reasons": "Redenen van doorverbinden",
                "closed_instruction": "Bestemming gesloten",
                "unavailable_instruction": "Bestemming geen gehoor",
                "edit_transfer_rule": "Doorverbindingsregel bewerken",
                "transfer_rules": "Transferbeleid",
                "transfer_destination": "Doorschakelen naar",
                "add_schedule": "Schema toevoegen",
                "closed_dates": "Gesloten dagen",
                "add_new_rule": "Nieuwe regel toevoegen",
                "use_transfer_rules": "Gebruik regels voor doorverbinden",
                "schedule": "Schema",
                "availability": "Beschikbaarheid",
                "instructions": "Instructies indien doorverbinden niet is gelukt",
                "start_time": "Starttijd",
                "end_time": "Eindtijd",
                "days": "Dagen",
                "transfer_purpose": "Doel van doorverbinden",
                "no_transfer_rules": "Geen doorverbindregels gevonden",
            },
            "descriptions": {
                "name": "Voer de naam van uw bedrijf in zoals deze door de assistent wordt gebruikt",
                "timezone": "Selecteer de tijdzone waarin uw bedrijf zich bevindt",
                "whatsapp_image": "Upload de afbeelding die wordt gebruikt bij het verzenden van WhatsApp-berichten",
                "greeting": "Voer het begroetingsbericht in dat aan het begin van gesprekken wordt gebruikt",
                "default_language": "Selecteer de taal waarin de beller wordt begroet",
                "additional_languages": "Selecteer extra talen die de gebruiker tijdens het gesprek kan kiezen. Beperk dit tot de meest gesproken talen door uw klanten om te voorkomen dat de gebruiker naar een verkeerde taal overschakelt",
                "lexicon": "Voer de uitspraak van woorden in die de assistent mogelijk niet correct uitspreekt",
                "prompt": "Geef gedetailleerde instructies voor de taken die Bonnie tijdens het gesprek moet uitvoeren",
                "notification_outcomes": "Selecteer de uitkomsten die een melding naar de opgegeven gebruiker moeten activeren",
                "notification_user": "Specificeer de gebruiker die alle meldingen ontvangt",
                "deployment": "Details over de deployment configuratie",
                "caller_id": "Het telefoonnummer dat de assistent gebruikt om te bellen",
                "fallback_number": "Het fallback-nummer dat moet worden gebruikt als het hoofdnummer onbereikbaar is",
                "customer_id": "De klant die aan deze assistent is gekoppeld",
                "config": "Aangepaste configuratie voor de assistent",
                "config.notifications.include_transcript": "Kies of u het gespreksverslag in de e-mailmelding wilt opnemen",
                "prompt_scenarios.transfer_policy": "Specificeer de voorwaarden waaronder gesprekken moeten worden doorgeschakeld en de bestemming voor de doorgeschakelde oproep",
                "prompt_scenarios.call_policy": "Geef het algemene beleid voor gespreksafhandeling en de richtlijnen die Bonnie moet volgen tijdens interacties met bellers",
                "prompt_scenarios.faq": "Voeg hier veelgestelde vragen en hun antwoorden toe om Bonnie te helpen nauwkeurige informatie aan bellers te verstrekken",
                "prompt_scenarios.context": "Voeg hier de context voor het gesprek toe, zoals datum, tijd en beller nummer",
                "prompt_scenarios.business_information": "Geef belangrijke informatie over uw bedrijf, zoals openingstijden, adres, website en andere relevante details die Bonnie moet weten om tijdens of na gesprekken te helpen",
                "prompt_scenarios.role": "Beschrijf de rol en het doel van de assistent",                
                "config.stt.interruption": "Kies of de beller de assistent mag onderbreken terwijl deze spreekt",
                "config.stt.backend": "Selecteer de backend die moet worden gebruikt voor spraak-naar-tekstconversie",
                "config.tools.formitable.config.api_key": "Voer de API-sleutel in voor Formitable",
                "config.tools.formitable.config.restaurant_id": "Voer het restaurant-ID in voor Formitable",                
                "config.tts.use_human_time_format": "Deze instelling schakelt de conversie van numerieke tijdsnotaties naar een natuurlijke taalformaat in. Bijvoorbeeld, in plaats van '14:00' wordt 'twee uur' uitgesproken.",
                "phone_number_id": "Telefoonnummer dat is gekoppeld aan deze assistent",
                "config.daily_report.enabled": "Schakel het versturen van dagelijkse rapporten voor deze assistent in",
                "config.daily_report.schedule": "Stel in op welk tijdstip het dagelijkse rapport moet worden verzonden",
                "config.daily_report.notification_user": "Gebruiker die het dagelijkse rapport ontvangt",
                "google_place_id": "Selecteer uw bedrijfslocatie",
                "voice": "Selecteer de stem die de assistent tijdens gesprekken zal gebruiken",
                "opening_hours": "Bepaal of openingstijden worden weergegeven",
                "answer_schedule": "Bepaal wie inkomende gesprekken beantwoordt en wanneer. Standaard neemt Bonnie alle gesprekken aan, maar je kunt regels instellen om gesprekken naar jouw telefoon door te schakelen, met of zonder Bonnie als backup.",
                "transfer_rules": "Regels voor het doorverbinden van gesprekken",
                "transfer_reason": "Geef aan voor welke situatie of doel dit nummer moet worden gebruikt",
                "transfer_destination": "Geef aan waar naar gesprekken moeten worden doorgeschakeld",
                "closed_instruction": "Instructies indien buiten aangegeven tijden",
                "unavailable_instruction": "Instructies indien doorverbinden niet is gelukt (niet beantwoord of bezet)",
                "transfer_reasons": "Hier kunt u de redenen opgeven waarvoor gesprekken moeten worden doorgeschakeld en de bestemming waar naar moet worden doorgeschakeld",
                "availability": "Geef aan wanneer de bestemming beschikbaar is om gesprekken te accepteren",
                "closed_dates": "Dagen wanneer de bestemming niet beschikbaar is om gesprekken te accepteren",
            },
            validation: {
                "missing_time": "Tijd is vereist",
                "invalid_time_range": "Ongeldige tijdsperiode",
                "empty_schedule_days": "Dagen zijn vereist",
            },
            placeholders: {
                "transfer_reason": "Voorbeeld: Evenement aanvraag Of: Verloren item",
                "closed_instruction": "Voorbeeld: Transfer naar algemene oproepen als er geen reactie is. Of: Transfer naar algemene oproepen als urgent.",
                "unavailable_instruction": "Voorbeeld: Transfer naar voicemail en informeer de beller dat het bedrijf gesloten is. Of: Transfer naar algemene oproepen als urgent.",
            },
            tabs: {
                "business_information": "Over",
                "personality": "Persoonlijkheid",
                "tasks": "Instructies",
                "policies_and_context": "Beleid & Context",
                "notifications": "Notificaties",
                "tools": "Integraties",
                "admin": "Admin Instellingen", 
            },
            try: {
                send: 'Stuur',
                type_your_message: 'Typ uw bericht',
                update: 'Bijwerken',
                edit_message: 'Bericht bewerken',
            },
            general: 'Algemeen',
            call_flow: 'Gespreksstroom',
            prompt: 'Instructies',
            notifications: 'Notificaties',
            image_valid: 'Afbeelding is geldig',
            validating_image: 'Afbeelding valideren...',
            retry_validation: 'Opnieuw proberen',
            errors: {
                invalid_phone: 'Ongeldig telefoonnummer',
                empty_schedule_days: 'Selecteer minimaal één dag',
                schedule_overlap: 'Schema\'s mogen niet overlappen voor dezelfde dagen',
                missing_time: 'Start- en eindtijd zijn verplicht',
                invalid_time_range: 'Eindtijd moet na starttijd liggen',
                duplicate_reason: 'Dubbele reden gevonden',
                duplicate_reasons_found: 'Dubbele redenen gevonden',
                at_least_one_reason_required: 'Er moet minimaal één reden worden opgegeven voor een geldige doorverbindregel',
                validation_failed: 'Er zijn fouten in het formulier. Controleer uw invoer en probeer het opnieuw.',
                availability_overlap: 'Beschikbaarheid schema\'s mogen niet overlappen voor dezelfde dagen',
                empty_reasons_not_allowed: 'Lege redenen zijn niet toegestaan',
                duplicate_dates_found: 'Dubbele datums gevonden',
                required: 'Vereist',
            },
            actions: {
                select_phone_number: 'Selecteer telefoonnummer',
                add_availability: 'Beschikbaarheid toevoegen',
                add_closed_date: 'Gesloten dag toevoegen',
                add_reason: 'Reden toevoegen',
            },
            phone_number: {
                confirm_title: 'Telefoonnummer wijzigen',
                confirm_content: 'Weet je zeker dat je het gekoppelde telefoonnummer wilt wijzigen?',
                no_options: "Geen beschikbare telefoonnummers gevonden"
            },
            loading_opening_hours: "Openingstijden worden geladen...",
            opening_hours_error: "Fout bij ophalen openingstijden. Probeer het opnieuw.",
            opening_hours_not_found: "Openingstijden niet gevonden voor deze locatie",
            opening_hours_server_error: "Serverfout bij ophalen openingstijden. Probeer het later opnieuw.",
            opening_hours_invalid_format: "Ongeldig formaat openingstijden ontvangen",
            "days": {
                    "label": "Dagen van de week",
                    "1": "Maandag",
                    "2": "Dinsdag",
                    "3": "Woensdag",
                    "4": "Donderdag",
                    "5": "Vrijdag",
                    "6": "Zaterdag",
                    "7": "Zondag"
            },
            working_hours: {
                "edit_title": "Werktijden bewerken",
                "not_configured": "Geen werktijden geconfigureerd",
                "rules": "Werktijden regels",
                "add_rule": "Nieuwe regel toevoegen",
                "add_schedule": "Nieuw schema toevoegen",
                "edit_rule": "Regel bewerken",
                "no_rules": "Nog geen werktijden geconfigureerd. De Assistent zal alle gesprekken beantwoorden.",
                "forward_to": "Doorschakelen naar",
                "recurring": "Terugkerend schema",
                "forward_number": "Doorschakelnummer",
                "forward_number_help": "Telefoonnummer om gesprekken naar door te schakelen tijdens deze periode",
                "forward_number_description": "Voer het telefoonnummer in dat doorgeschakelde gesprekken zal ontvangen tijdens deze periode",
                "dial_timeout": "Beltijdslimiet",
                "dial_timeout_help": "Hoeveel seconden te wachten voordat het gesprek als onbeantwoord wordt beschouwd",
                "dial_timeout_description": "Stel in hoeveel seconden er gewacht moet worden op een antwoord voordat het gesprek als onbeantwoord wordt beschouwd",
                "start_time": "Starttijd",
                "start_time_description": "De tijd waarop deze regel elke dag van toepassing wordt",
                "end_time": "Eindtijd",
                "end_time_description": "De tijd waarop deze regel elke dag niet meer van toepassing is",
                "start_date": "Startdatum",
                "start_date_description": "De datum waarop deze regel van toepassing wordt",
                "end_date": "Einddatum",
                "end_date_description": "De datum waarop deze regel niet meer van toepassing is",
                "set_24_hours": "24 uur",
                "active_24_hours": "Actief 24 uur per dag",
                "customize": "Uren aanpassen",
                "mode": {
                    "label": "Afhandelingsmodus",
                    "client_first": "Klant antwoordt eerst",
                    "client_only": "Alleen klant antwoordt",
                    "assistant_first": "Bonnie antwoordt eerst",
                    "description": "Kies hoe inkomende gesprekken tijdens deze periode moeten worden afgehandeld"
                },
                "error": {
                    "no_days": "Selecteer ten minste één dag",
                    "overlap": "Deze regel overlapt met een bestaande regel. Werktijdenregels mogen niet overlappen.",
                    "no_start_date": "Selecteer een startdatum",
                    "no_end_date": "Selecteer een einddatum",
                    "no_forward_number": "Please provide a forward number",
                    "invalid_forward_number": "The forward number is invalid",
                    "no_dial_timeout": "Please specify a dial timeout",
                    "invalid_dial_timeout": "The dial timeout must be between 1 and 60 seconds",
                    "no_start_time": "Please select a start time",
                    "no_end_time": "Please select an end time",
                }
            },
        },
        conversation_notes: {
            add_note: 'Notitie toevoegen',
            edit_note: 'Notitie bewerken',        
            back_to_conversations: 'Terug naar Gesprekken',
            fields: {
                note: 'Notitie',
                content: 'Inhoud',
                created_at: 'Datum',
                created_by: 'Gemaakt door',
                is_private: 'Alleen zichtbaar voor mij',
                status: 'Status',
                assigned_to: 'Toegewezen aan',
            },
            statuses: {
                open: 'Open',
                closed: 'Gesloten',
                none: 'Geen',
            },
        },              
        conversations: {
            name: 'Gesprek |||| Gesprekken',
            overview: 'Gesprekken',
            recording: 'Opname',
            role: 'Rol',
            content: 'Inhoud',
            tag_updated: 'Tag bijgewerkt',
            add_tag: 'Tag toevoegen',
            no_conversations: 'Geen gesprekken gevonden',
            conversation_details: 'Gesprek Details',
            fields: {
                assistant_id: 'Assistent',
                created_at: 'Datum',
                from_id: 'Van',
                from_name: 'Naam',
                destination: 'Bestemming',
                duration: 'Duur',
                summary: 'Samenvatting',
                details: 'Details',
                phone_number: 'Telefoonnummer',
                from_name: 'Naam',
                duration: 'Duur',
                created_at: 'Aangemaakt op',
                topic: 'Onderwerp',
                result: 'Resultaat',
                outcome: 'Resultaat',
                messages: 'Berichten',
                accessible_open_notes_count: 'Open notities',
                has_open_notes: 'Open notities',
                name: 'Naam',
                transcript: 'Transcript',
                notes: 'Notities',
                admin: 'Admin',
                rating: 'Rating',
            },
            results: {
                all: 'Alle',
                success: 'Succesvol',
                failure: 'Fout',
                transferred: 'Doorgeschakeld',
                none: 'Geen',
                followup: 'Opvolging',
                null: 'Geen',
            },            
            outcomes: {
                all: 'Alle',
                none: 'Geen',
                null: 'Geen',
                completed: 'Afgerond',
                caller_follow_up: 'Opvolging Beller',
                business_follow_up: 'Opvolging Bedrijf',
                transferred: 'Doorverbonden',
                information_provided: 'Informatie Verstrekt',
                no_action: 'Geen Actie',
                answered_by_business: 'Beantwoord door Bedrijf',
                missed_by_business: 'Gemist door Bedrijf',
            },
            'copy_url': 'URL kopiëren',
            'copy_messages': 'Berichten kopiëren',            
            'url_copied': 'URL naar dit gesprek is gekopieerd naar het klembord',
            'messages_copied': 'Berichten zijn gekopieerd naar het klembord',
            'delete': 'Verwijderen',
            'deleted': 'Gesprek verwijderd',
            'delete_error': 'Fout bij verwijderen',
            stats: {
                duration_title: 'Gespreksduur',
                count_title: 'Aantal gesprekken',
                duration: 'Duur',
                count: 'Aantal',
                duration_label: 'Tijd',
                count_label: 'Aantal gesprekken',
                direct_calls: 'Afgehandeld door Bonnie',
                transferred_calls: 'Doorverbonden gesprekken',
                call_duration: 'Gespreksduur Bonnie',
                transfer_duration: 'Doorverbonden duur',
                total_calls: "Gesprekken",
                total_call_time: "Gesprekstijd",
                transfer_count_percentage: "Doorverbonden",
                transfer_duration_percentage: "Doorverbonden (tijd)",
                total_call_time_help: "De totale beltijd van alle gesprekken",
                total_calls_help: "Het totale aantal gesprekken dat er is afgehandeld",
                transfer_count_percentage_help: "Het percentage van gesprekken dat is doorverbonden naar een menselijke medewerker",
                transfer_duration_percentage_help: "Het percentage van de totale gesprekstijd dat is besteed aan doorverbonden gesprekken",
                total_messages: "Berichten",
                total_messages_help: "Het totale aantal berichten uitgewisseld in alle gesprekken",
            },
            tabs: {
                overview: 'Overzicht',
                transcript: 'Transcript',
                notes: 'Notities',
                admin: 'Admin',
            },
            unknown: 'Onbekend',
            no_summary: 'Geen samenvatting beschikbaar',
            add_note: 'Notitie toevoegen',
        },
        tasks: {
            name: 'Taak |||| Taken',
            overview: 'Taken',
        },
        partners: {
            name: 'Partner |||| Partners',
            overview: 'Partners',
        },
        'prompt-templates': {
            name: "Prompt Template |||| Prompt Templates",
            overview: "Prompt Templates",
        },
        profile: {
            user_info: 'Gebruikersinformatie',
            password_settings: 'Wachtwoordinstellingen',
            system_preferences: 'System Preferences',
            language: 'Taal',
        },
        tools: {
            'get_availability': 'Beschikbaarheid controleren...',
            'get_available_products': 'Beschikbare producten ophalen...',
            'find_bookings_by_phone_number': 'Bestaande reserveringen zoeken...',
            'create_booking': 'Reservering maken...',
            'update_booking': 'Reservering bijwerken...',
            'cancel_booking': 'Reservering annuleren...',
            'switch_language': 'Taal wijzigen...',
            'send_sms': 'SMS versturen...',
            'transfer_phone_call': 'Gesprek doorverbinden...',
            'save_conversation': 'Gesprek opslaan...',
            'end_phone_call': 'Gesprek beëindigen...',
            'send_message_to_restaurant': 'Bericht sturen naar restaurant...',
        },
        "phone_numbers": {
            "name": "Telefoonnummer |||| Telefoonnummers",
            "fields": {
                "number": "Nummer",
                "assistant_id": "Assistent",
                "created_at": "Aangemaakt op"
            }
        },
    },
};

const LOCALE_READABLE_NAMES = {
    "en-US": "English",
    "en-AU": "English (AU)",
    "nl-NL": "Nederlands",
    "fr-FR": "Français",
    "fr-BE": "Français (BE)",
};

export const getReadableLocaleName = (localeCode) => {
    const normalizedLocaleCode = localeCode.replace('_', '-');
    return LOCALE_READABLE_NAMES[normalizedLocaleCode] || "Unknown Locale";
};

const translations = { en_US, en_AU, nl_NL };

// Genereer de locale opties op basis van de keys in je translations object
const localeOptions = Object.keys(translations).map(locale => ({
    locale: locale,
    name: getReadableLocaleName(locale)
}));

export const i18nProvider = polyglotI18nProvider(
    locale => translations[locale],
    'en_US', // default locale
    localeOptions,
);
