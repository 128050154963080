import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { 
  IconSettings, 
  IconUser,
  IconChevronDown,
  IconUsers,
  IconSun,
  IconMoon,
} from '@tabler/icons-react';
import { useSidebarState, useTranslate, Logout, useGetIdentity, useTheme } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { useTheme as useMuiTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

const UserMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [raTheme, setRaTheme] = useTheme();
  const muiTheme = useMuiTheme();
  const {data} = useGetIdentity();
  const [sidebarOpen, setSidebarOpen] = useSidebarState();
  const translate = useTranslate();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));

  useEffect(() => {
    const auth = localStorage.getItem('auth');
    const parsedAuth = JSON.parse(auth || '{}');
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeSidebarIfMobile = () => {
    if (isMobile && sidebarOpen) {
      setSidebarOpen(false);
    }
  };

  const handleSettings = () => {
    navigate('/profile');
    handleClose();
    closeSidebarIfMobile();
  };

  const handleUsers = () => {
    navigate('/users');
    handleClose();
    closeSidebarIfMobile();
  };

  const handleToggleTheme = () => {
    const newMode = raTheme === 'light' ? 'dark' : 'light';
    setRaTheme(newMode);
    handleClose();
    closeSidebarIfMobile();
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Button
        onClick={handleClick}
        startIcon={<IconUser size={20} />}
        endIcon={sidebarOpen ? <IconChevronDown size={20} /> : null}
        sx={{
          border: `1px solid ${muiTheme.palette.divider}`,
          padding: sidebarOpen ? muiTheme.spacing(1, 2) : muiTheme.spacing(1),
          color: 'inherit',
          minWidth: 'auto',
          width: '100%',
          justifyContent: sidebarOpen ? 'space-between' : 'center',
          '& .MuiButton-startIcon': {
            margin: sidebarOpen ? undefined : 0,
          },
          '& .MuiButton-endIcon': {
            marginLeft: 'auto',
          },
        }}
      >
        {sidebarOpen ? (data?.fullName) : null}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        sx={{
          '& .MuiPaper-root': {
            marginTop: muiTheme.spacing(-1),
            border: `1px solid ${muiTheme.palette.divider}`,
            backgroundColor: muiTheme.palette.background.paper,
            minWidth: '200px',
          },
        }}
      >
       
        <MenuItem onClick={handleSettings}>
          <ListItemIcon>
            <IconSettings size={20} stroke={1.5} />
          </ListItemIcon>
          <ListItemText>{translate('resources.generic.settings')}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleUsers}>
          <ListItemIcon>
            <IconUsers size={20} stroke={1.5} />
          </ListItemIcon>
          <ListItemText>{translate('resources.generic.users')}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleToggleTheme}>
          <ListItemIcon>
            {raTheme === 'light' ? (
              <IconMoon size={20} stroke={1.5} />
            ) : (
              <IconSun size={20} stroke={1.5} />
            )}
          </ListItemIcon>
          <ListItemText>
            {raTheme === 'light' 
              ? translate('resources.generic.switch_to_dark')
              : translate('resources.generic.switch_to_light')
            }
          </ListItemText>
        </MenuItem>
          <Logout 
            sx={{ width: '100%' }}
          >
            {logout => (
              <Box
                onClick={() => {
                  closeSidebarIfMobile();
                  logout();
                }}
              >
                <ListItemIcon>
                  {logout.icon}
                </ListItemIcon>
                <ListItemText>{logout.label}</ListItemText>
              </Box>
            )}
          </Logout>
      </Menu>
    </Box>
  );
};

export default UserMenu;