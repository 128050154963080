import { useTranslate } from 'react-admin';

interface ToolNameTranslatorProps {
    toolName: string;
}

const ToolNameTranslator = ({ toolName }: ToolNameTranslatorProps) => {
    const translate = useTranslate();

    const getTranslatedName = (name: string): string => {
        // Using the translation system with a fallback to the original name
        const translationKey = `resources.tools.${name}`;
        const translation = translate(translationKey, { defaultValue: name });
        return translation;
    };

    return <>{getTranslatedName(toolName)}</>;
};

export default ToolNameTranslator; 