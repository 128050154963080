// in src/App.tsx
import { LicenseInfo } from "@mui/x-license";
import { useEffect } from "react";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";

import * as Sentry from "@sentry/react";
Sentry.init({
  dsn: "https://ce312745c4769b33cf3a35e48ed07b92@o4506200448040960.ingest.us.sentry.io/4506200466391041",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0,
  enabled: import.meta.env.VITE_NODE_ENV !== "development",
});

import { Admin, Resource, CustomRoutes } from "react-admin";
import { Route } from "react-router-dom";
import { dataProvider } from "./dataProvider";
import { AssistantList, AssistantCreate, AssistantEdit } from "./assistants";
import {
  ConversationList,
  ConversationShow,
  TwilioRedirect,
} from "./conversations";
import { CustomerList, CustomerEdit, CustomerCreate } from "./customers";
import { PartnerList, PartnerEdit, PartnerCreate } from "./partners";
import { UserList, UserEdit, UserCreate } from "./users";
import {
  PromptTemplateList,
  PromptTemplateEdit,
  PromptTemplateCreate,
} from "./PromptTemplates";
import { i18nProvider } from "./i18nProvider";

import { Dashboard } from "./Dashboard";
import { Layout, Login } from "./layout";
import { fastapiAuthProvider } from "./fastapiAuthProvider";
import TryAssistant from "./TryAssistant";
import { Profile } from "./Profile";

import { myTheme, myDarkTheme } from "./theme";
import { TaskList, TaskShow } from "./Tasks";
import Onboarding from "./Onboarding";
import { ResetPassword } from "./ResetPassword";
import { ForgotPassword } from "./ForgotPassword";
import TwoFactorAuth from "./TwoFactorAuth";
import {
  IconBuildingStore,
  IconChecklist,
  IconHeartHandshake,
  IconMessage,
  IconPhone,
  IconSparkles,
  IconTemplate,
} from "@tabler/icons-react";

import { FilterProvider } from './contexts/FilterContext';

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_X_LICENSE_KEY);

export const App = () => {
  // Temporary redirect to bonnie.co
  useEffect(() => {
    const sourceHost = "app.pollie.ai";
    const targetHost = "app.bonnie.co";
    const redirectEnabled =
      import.meta.env.VITE_ENABLE_REDIRECT?.toLowerCase() === "true";

    // Only redirect if enabled and on source domain
    if (redirectEnabled && window.location.hostname === sourceHost) {
      try {
        const newUrl = new URL(`https://${targetHost}`);
        newUrl.pathname = window.location.pathname;
        newUrl.search = window.location.search;
        newUrl.hash = window.location.hash;
        window.location.href = newUrl.toString();
      } catch (error) {
        console.error("Redirect failed:", error);
      }
    }
  }, []);

  return (
    <FilterProvider>
      <Admin
        i18nProvider={i18nProvider}
        layout={Layout}
        loginPage={Login}
        authProvider={fastapiAuthProvider}
        dataProvider={dataProvider}
        dashboard={Dashboard}
        theme={myTheme}
        darkTheme={myDarkTheme}
        defaultTheme="light"
        disableTelemetry={true}
      >
        {(permissions) => (
          <>
            <Resource
              name="tasks"
              list={TaskList}
              show={TaskShow}
              icon={IconChecklist}
            >
            </Resource>
            <Resource
              name="conversations"
              list={ConversationList}
              show={ConversationShow}
              icon={IconMessage}
            >
              <Route path="redirect/:twilio_id" element={<TwilioRedirect />} />
            </Resource>
            <Resource
              name="assistants"
              list={AssistantList}
              edit={AssistantEdit}
              create={
                ["admin", "partner"].includes(permissions)
                  ? AssistantCreate
                  : undefined
              }
              icon={IconSparkles}
            >
              <Route path=":id/try" element={<TryAssistant />} />
              <Route
                path=":id/try/from/:phoneNumber"
                element={<TryAssistant />}
              />
            </Resource>
            {["admin"].includes(permissions) ? (
              <Resource
                name="partners"
                list={PartnerList}
                edit={PartnerEdit}
                create={PartnerCreate}
                icon={IconHeartHandshake}
              >
                <Route path=":partnerId/users" element={<UserList />} />
                <Route path=":partnerId/users/:id" element={<UserEdit />} />
                <Route path=":partnerId/users/create" element={<UserCreate />} />
              </Resource>
            ) : null}
            {["admin", "partner"].includes(permissions) ? (
              <Resource
                name="customers"
                list={CustomerList}
                edit={CustomerEdit}
                create={CustomerCreate}
                icon={IconBuildingStore}
              >
                <Route path=":customerId/users" element={<UserList />} />
                <Route path=":customerId/users/:id" element={<UserEdit />} />
                <Route path=":customerId/users/create" element={<UserCreate />} />
              </Resource>
            ) : null}
            <Resource name="phone-numbers" icon={IconPhone} />
            {permissions === "admin" ? (
              <Resource
                name="prompt-templates"
                list={PromptTemplateList}
                edit={PromptTemplateEdit}
                create={PromptTemplateCreate}
                icon={IconTemplate}
              ></Resource>
            ) : null}
            <CustomRoutes noLayout>
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/2fa" element={<TwoFactorAuth />} />
            </CustomRoutes>
            <CustomRoutes>
              {permissions === "admin" && (
                <Route path="/onboarding" element={<Onboarding />} />
              )}
              <Route path="/users" element={<UserList />} />
              <Route path="/users/:id" element={<UserEdit />} />
              <Route path="/users/create" element={<UserCreate />} />
              <Route path="/profile" element={<Profile />} />
            </CustomRoutes>
          </>
        )}
      </Admin>
    </FilterProvider>
  );
};
