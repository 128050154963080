import { useTranslate, TopToolbar, ListButton, Create, Edit, ReferenceInput, AutocompleteInput, SimpleForm, Datagrid, List, TextField, TextInput, required, usePermissions } from 'react-admin';
import { Box, Button } from '@mui/material';
import UserIcon from "@mui/icons-material/Group";
import { useRecordContext } from 'react-admin';
import { Link } from 'react-router-dom';
import CustomPagination from './components/react-admin/CustomPagination';
import { ResourceTitle } from './layout/ResourceTitle';

const LinkToRelatedUsers = () => {
    const record = useRecordContext();
    const translate = useTranslate();
    
    // Handler om de event propagatie te stoppen
    const handleClick = (event: any) => {
        // Voorkomt dat de event verder bubbelt
        event.stopPropagation();
    };
    return record ? (
        <Button
            size="small"
            onClick={handleClick}
            color="primary"
            variant="outlined"
            component={Link}
            startIcon={<UserIcon />}
            to={{
                pathname: `/customers/${record.id}/users`,
            }}
        >
            {translate('resources.users.name', { smart_count: 2 })}
        </Button>
    ) : null;
};

const postFilters = [
    <TextInput source="name" alwaysOn />,
];

const CustomerListButton = () => (
    <TopToolbar>    
        <ListButton />
    </TopToolbar>
);


export const CustomerList = () => (
    <Box p={2}>
        <ResourceTitle/>
        <List filters={postFilters} exporter={false} pagination={<CustomPagination />}>
            <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="lookup_key" />
                <LinkToRelatedUsers />
            </Datagrid>
        </List>
    </Box>
    );

export const CustomerEdit = () => {
    const { permissions } = usePermissions();
    return (
        <Edit actions={<CustomerListButton />}>
            <SimpleForm>
                <TextInput source="name" />
                <TextInput source="lookup_key" helperText="The id of the customer in the partner platform" />
                {permissions === 'admin' && 
                <>
                <br />
                <ReferenceInput source="partner_id" reference="partners">
                    <AutocompleteInput optionText="name" filterToQuery={(searchText: any) => ({ name: `%${searchText}%` })} validate={required()} />
                </ReferenceInput>
                </>
                }
            </SimpleForm>
        </Edit>
    );
}

export const CustomerCreate = () => {
    const { permissions } = usePermissions();

    return (
        <Create actions={<CustomerListButton />} redirect="list">
            <SimpleForm>
                <TextField source="id"  />
                <TextInput source="name" />
                <TextInput source="lookup_key" />
                {permissions === 'admin' && 
                <ReferenceInput source="partner_id" reference="partners">
                    <AutocompleteInput optionText="name" filterToQuery={(searchText: any) => ({ name: `%${searchText}%` })} validate={required()} />
                </ReferenceInput>
                }   
            </SimpleForm>

        </Create>
    );

}