import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useTranslate } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Box, Typography, CircularProgress, Button, TextField } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import RefreshIcon from '@mui/icons-material/Refresh';

interface WhatsAppImageValidatorProps {
  assistantId?: number;
  imageUrl?: string;
  rawFile?: File;
}

function WhatsAppImageValidator({ imageUrl, rawFile }: WhatsAppImageValidatorProps) {
  const [status, setStatus] = useState<'idle' | 'loading' | 'converting' | 'success' | 'error'>('idle');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const translate = useTranslate();
  const { setValue, getValues, trigger } = useFormContext();
  const inputRef = useRef<HTMLInputElement>(null);
  const [convertedFile, setConvertedFile] = useState<File | null>(null);
  const conversionAttemptedRef = useRef<boolean>(false);
  const fileProcessedRef = useRef<string | null>(null);
  const validationInProgressRef = useRef<boolean>(false);
  const initialCheckDoneRef = useRef<boolean>(false);

  // Check initial validation state
  useEffect(() => {
    if (initialCheckDoneRef.current) return;
    
    const formData = getValues();
    const isValid = formData?.config?.whatsapp_image_valid === true;
    
    // If returning to component with already validated image
    if (isValid && (imageUrl || rawFile)) {
      setStatus('success');
      initialCheckDoneRef.current = true;
    }
  }, [getValues, imageUrl, rawFile]);

  // Helper to update form with validation result
  const updateFormValidation = useCallback((isValid: boolean, file?: File) => {
    const formData = getValues();
    const newConfig = { ...(formData.config || {}) };
    
    // If we have a converted file, update the file reference too
    if (file) {
      newConfig.whatsapp_image = {
        rawFile: file,
        src: URL.createObjectURL(file),
        title: file.name
      };
    }
    
    newConfig.whatsapp_image_valid = isValid;
    setValue('config', newConfig, { shouldValidate: isValid });
    
    if (isValid) {
      trigger();
    } else {
      trigger('config');
    }
  }, [getValues, setValue, trigger]);

  // Helper function to convert image to PNG format
  const convertImageToPng = async (file: File): Promise<File | null> => {
    try {
      const tempUrl = URL.createObjectURL(file);
      
      try {
        // Load the image
        const img = new Image();
        await new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
          img.src = tempUrl;
        });
        
        // For SVGs, set a minimum size to ensure proper conversion
        const isSvg = file.type === 'image/svg+xml' || file.name.toLowerCase().endsWith('.svg');
        let width = img.width;
        let height = img.height;
        
        // For SVGs, apply scaling for higher resolution
        if (isSvg) {
          // Apply a scaling factor to improve resolution
          const scaleFactor = 3;
          
          // Always scale SVGs for better resolution
          const aspectRatio = width / height;
          
          // Start with higher base dimensions (minimum 480px which exceeds WhatsApp's 192px requirement)
          const baseSize = Math.max(480, Math.max(width, height) * scaleFactor);
          
          if (width < height) {
            height = baseSize;
            width = Math.round(height * aspectRatio);
          } else {
            width = baseSize;
            height = Math.round(width / aspectRatio);
          }
        }
        
        // Create a canvas element to convert the image
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        
        // Draw the image on the canvas with sRGB color space conversion
        const ctx = canvas.getContext('2d', { colorSpace: 'srgb' });
        if (!ctx) {
          throw new Error('Could not get canvas context');
        }
        
        // For better quality rendering, especially for SVGs
        if (isSvg) {
          ctx.imageSmoothingEnabled = true;
          ctx.imageSmoothingQuality = 'high';
        }
        
        ctx.drawImage(img, 0, 0, width, height);
        
        // Convert canvas to blob (PNG format)
        const blob = await new Promise<Blob | null>((resolve) => {
          canvas.toBlob(resolve, 'image/png', 1.0);
        });
        
        if (!blob) {
          throw new Error('Failed to convert image to PNG');
        }
        
        // Create a new file from the blob
        const convertedFile = new File([blob], file.name.replace(/\.[^.]+$/, '.png'), {
          type: 'image/png',
          lastModified: new Date().getTime()
        });
        
        return convertedFile;
      } finally {
        URL.revokeObjectURL(tempUrl);
      }
    } catch (error) {
      return null;
    }
  };

  // Converts an image and handles state updates
  const handleImageConversion = useCallback(async (file: File): Promise<boolean> => {
    try {
      setStatus('converting');
      const pngFile = await convertImageToPng(file);
      
      if (pngFile) {
        setConvertedFile(pngFile);
        updateFormValidation(true, pngFile);
        setStatus('success');
        initialCheckDoneRef.current = true;
        return true;
      } else {
        throw new Error(`Could not convert to a valid format. Please upload a JPEG or PNG file.`);
      }
    } catch (error) {
      setStatus('error');
      setErrorMessage(
        error instanceof Error ? error.message : 'Could not convert image. Please upload a JPEG or PNG file.'
      );
      updateFormValidation(false);
      return false;
    } finally {
      validationInProgressRef.current = false;
    }
  }, [updateFormValidation]);

  // Main validation function
  const validateImage = useCallback(async () => {
    // Skip if validation is already in progress or if no image to validate
    if (validationInProgressRef.current || (!imageUrl && !rawFile && !convertedFile)) {
      return;
    }
    
    // Skip validation if already in error state or converting (unless explicitly reset)
    if (status === 'error' || status === 'converting') {
      return;
    }
    
    // We need to always check new raw files, even if the config says it's valid
    const fileToProcess = convertedFile || rawFile;
    const formData = getValues();
    const isAlreadyValid = formData?.config?.whatsapp_image_valid === true;
    
    // If already valid AND we're not processing a new file, don't revalidate
    if (isAlreadyValid && status === 'success' && 
        (!fileToProcess || (fileProcessedRef.current === fileToProcess.name))) {
      return;
    }
    
    validationInProgressRef.current = true;
    setStatus('loading');
    
    try {
      // Validate a file (either raw or converted)
      if (fileToProcess) {
        // Track the file we're processing
        fileProcessedRef.current = fileToProcess.name;
        
        // Handle SVG files - convert first without checking dimensions
        const isSvgFile = fileToProcess.type === 'image/svg+xml' || 
                          fileToProcess.name.toLowerCase().endsWith('.svg');
        
        if (isSvgFile && !convertedFile) {
          if (!conversionAttemptedRef.current && rawFile) {
            conversionAttemptedRef.current = true;
            const success = await handleImageConversion(rawFile);
            return;
          } else {
            throw new Error(`Could not process SVG file. Please try a different image format.`);
          }
        }
        
        // For converted PNGs and non-SVG files, perform normal validation
        const tempUrl = URL.createObjectURL(fileToProcess);
        
        try {
          // Check basic image properties
          const img = new Image();
          await new Promise((resolve, reject) => {
            img.onload = resolve;
            img.onerror = reject;
            img.src = tempUrl;
          });
          
          // Check file type first, for non-SVG and non-converted files
          const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
          if (!validTypes.includes(fileToProcess.type) && !convertedFile) {
            if (!conversionAttemptedRef.current && rawFile) {
              conversionAttemptedRef.current = true;
              validationInProgressRef.current = false;
              const success = await handleImageConversion(rawFile);
              return;
            } else {
              throw new Error(`Invalid image format: ${fileToProcess.type}. Only JPEG and PNG are supported.`);
            }
          }
          
          // Now check dimensions for the current file
          if (img.naturalWidth < 192 || img.naturalHeight < 192) {
            throw new Error(`Image too small: ${img.naturalWidth}x${img.naturalHeight}. Minimum size is 192x192 pixels.`);
          }
          
          // Check file size (max 5MB for WhatsApp)
          if (fileToProcess.size > 5 * 1024 * 1024) {
            throw new Error(`Image too large: ${(fileToProcess.size / (1024 * 1024)).toFixed(2)}MB. Maximum size is 5MB.`);
          }
          
          // All validation passed
          setStatus('success');
          setErrorMessage(null);
          updateFormValidation(true);
          initialCheckDoneRef.current = true;
        } finally {
          URL.revokeObjectURL(tempUrl);
        }
      } 
      // Validate existing image URL
      else if (imageUrl) {
        const img = new Image();
        await new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
          img.src = imageUrl;
        });
        
        // Check if it's an SVG
        const isSvgUrl = imageUrl?.toLowerCase().endsWith('.svg') || false;
        if (!isSvgUrl && (img.naturalWidth < 192 || img.naturalHeight < 192)) {
          throw new Error(`Image too small: ${img.naturalWidth}x${img.naturalHeight}. Minimum size is 192x192 pixels.`);
        }
        
        setStatus('success');
        setErrorMessage(null);
        updateFormValidation(true);
        initialCheckDoneRef.current = true;
      }

      if (rawFile && !convertedFile && !conversionAttemptedRef.current) {
        conversionAttemptedRef.current = true;
        const success = await handleImageConversion(rawFile);
        return;
      }
    } catch (error) {
      setStatus('error');
      setErrorMessage(
        error instanceof Error ? error.message : translate('resources.assistants.fields.image_validation_failed')
      );
      updateFormValidation(false);
      initialCheckDoneRef.current = true;
    } finally {
      validationInProgressRef.current = false;
    }
  }, [
    imageUrl, 
    rawFile, 
    convertedFile, 
    status, 
    getValues, 
    handleImageConversion,
    updateFormValidation,
    translate
  ]);

  // Run validation when needed
  useEffect(() => {
    // Reset when raw file changes
    if (rawFile) {
      const fileName = rawFile.name;
      if (fileProcessedRef.current !== fileName) {
        setConvertedFile(null);
        conversionAttemptedRef.current = false;
        fileProcessedRef.current = null;
        setStatus('idle'); // Reset status when file changes
        initialCheckDoneRef.current = false; // Reset the initial check
      }
    }
    
    // Don't revalidate if we're already in a success state with the current file
    if (status === 'success' && fileProcessedRef.current === (convertedFile?.name || rawFile?.name)) {
      return;
    }
    
    // Don't set a timer if we're already in error state or converting
    if (status === 'error' || status === 'converting') {
      return;
    }
    
    const timer = setTimeout(() => {
      validateImage();
    }, 500);
    
    return () => clearTimeout(timer);
  }, [imageUrl, rawFile, convertedFile, validateImage, status]);

  // Ensure form validation is triggered when success state is reached
  useEffect(() => {
    if (status === 'success') {
      const timer = setTimeout(() => {
        trigger();
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [status, trigger]);

  if (status === 'idle' || (!imageUrl && !rawFile && !convertedFile)) {
    return null;
  }

  return (
    <Box sx={{ 
      mt: 0,
      display: 'flex', 
      alignItems: 'center', 
      gap: 1, 
      flexWrap: 'wrap',
      maxWidth: '100%',
      position: 'relative',
      minHeight: '28px'
    }}>
      {status === 'loading' && (
        <>
          <CircularProgress size={20} />
          <Typography variant="body2" color="textSecondary">
            {translate('resources.assistants.fields.validating_image')}
          </Typography>
        </>
      )}
      
      {status === 'converting' && (
        <>
          <CircularProgress size={20} />
          <Typography variant="body2" color="textSecondary">
            {translate('app.converting_image')}
          </Typography>
        </>
      )}
      
      {status === 'success' && (
        <>
          <CheckCircleIcon color="success" fontSize="small" />
          <Typography variant="body2" color="success.main">
            {(rawFile?.name?.toLowerCase().endsWith('.svg') && convertedFile) 
              ? translate('app.image_converted_and_valid')
              : translate('resources.assistants.fields.image_valid')}
          </Typography>
        </>
      )}
      
      {status === 'error' && (
        <>
          <ErrorIcon color="error" fontSize="small" />
          <Typography variant="body2" color="error" sx={{ flex: '1 0 auto' }}>
            {errorMessage || translate('resources.assistants.fields.image_validation_failed')}
          </Typography>
          <Button 
            startIcon={<RefreshIcon />} 
            size="small" 
            onClick={() => {
              fileProcessedRef.current = null;
              conversionAttemptedRef.current = false;
              setStatus('idle');
              initialCheckDoneRef.current = false;
              validateImage();
            }}
            variant="outlined"
          >
            {translate('resources.assistants.fields.retry_validation')}
          </Button>
        </>
      )}
      
      <TextField
        inputRef={inputRef}
        type="hidden" 
        name="config.whatsapp_image_valid" 
        value={status === 'success' ? 'true' : ''} 
        sx={{ position: 'absolute', opacity: 0, pointerEvents: 'none' }}
        onChange={() => {}}
        inputProps={{
          'data-testid': 'whatsapp-image-validation-input'
        }}
      />
    </Box>
  );
}

export default WhatsAppImageValidator;