import * as React from 'react';
import { Box, Typography, Avatar, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PollieIcon from '../../layout/PollieIcon';
import { IconUser, IconChevronDown, IconSettings } from '@tabler/icons-react';
import { useTranslate } from 'react-admin';
import ToolNameTranslator from './ToolNameTranslator';
import ToolIcon from './ToolIcon';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState } from 'react';

interface Message {
    role: 'system' | 'assistant' | 'tool' | 'user';
    content: string | null;
    tool_calls?: {
        id: string;
        function: {
            name: string;
            arguments: string;
        };
        type: string;
    }[];
    tool_call_id?: string;
    refusal?: string | null;
}

interface ConversationTranscriptProps {
    messages: Message[];
}

const ConversationTranscript = ({ messages }: ConversationTranscriptProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const translate = useTranslate();
    
    // Add state to track which tool calls are open
    const [openToolCalls, setOpenToolCalls] = useState<Record<number, boolean>>({});
    
    const parsedMessages = React.useMemo(() => {
        if (!messages) return [];
        let parsed = Array.isArray(messages) ? messages : [];
        if (!Array.isArray(messages)) {
            try {
                parsed = JSON.parse(messages);
            } catch (error) {
                console.error('Failed to parse messages:', error);
                return [];
            }
        }
        return parsed.slice(1); // Skip first message
    }, [messages]);

    if (!parsedMessages || parsedMessages.length === 0) return null;

    // Toggle function to handle opening/closing tool calls
    const toggleToolCall = (index: number) => {
        setOpenToolCalls(prev => ({
            ...prev,
            [index]: !prev[index]
        }));
    };

    return (
        <Paper sx={{
            p: theme.spacing(2),
            boxShadow: theme.shadows[0],
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        }}>
            <Typography
                variant="h5"
                sx={{
                    fontWeight: 'bold',
                    mb: theme.spacing(2)
                }}
            >
                {translate('resources.conversations.fields.transcript')}
            </Typography>

            <Box sx={{ 
                flexGrow: 1,
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                    width: '8px',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0,0,0,0.2)',
                    borderRadius: '4px',
                }
            }}>
                {parsedMessages.map((message: Message, index: number) => {
                    const toolResponse = message.tool_calls ? 
                        parsedMessages.find((m: Message) => 
                            m.role === 'tool' && 
                            m.tool_call_id === message?.tool_calls?.[0]?.id
                        ) : null;

                    // Skip tool responses as they'll be shown with their tool calls
                    if (message.role === 'tool' && message.tool_call_id) {
                        return null;
                    }

                    // Using the shared state instead
                    const isOpen = !!openToolCalls[index];

                    return (
                        <Box
                            key={index} 
                            sx={{ 
                                display: 'flex', 
                                justifyContent: message.role === 'user' ? 'flex-start' :'flex-end',
                                mb: 2,
                                width: '100%'
                            }}
                        >
                            <Box
                                sx={{ 
                                    width: isMobile ? '100%' : '80%',
                                    display: 'flex',
                                    flexDirection: message.role === 'user' ? 'row' : 
                                                      message.role === 'tool' ? 'row-reverse' : 
                                                      'row-reverse',
                                    alignItems: 'flex-start',
                                    gap: 2
                                }}
                            >
                                {message.role === 'system' ? (
                                    <Avatar 
                                        sx={{ 
                                            bgcolor: theme.palette.primary.light,
                                            width: 32,
                                            height: 32
                                        }}
                                    >
                                        <IconSettings size="1rem" color={theme.palette.warning.contrastText} />
                                    </Avatar>
                                ) : message.role === 'assistant' || message.role === 'tool' ? (
                                    message.tool_calls ? (
                                        <ToolIcon toolName={message.tool_calls[0]?.function?.name} />
                                    ) : (
                                        <PollieIcon sx={{ width: 32, height: 32 }}/>
                                    )
                                ) : (
                                    <Avatar 
                                        sx={{ 
                                            bgcolor: 'transparent',
                                            border: `1px solid ${theme.palette.info.main}`,
                                            width: 32,
                                            height: 32
                                        }}
                                    >
                                        <IconUser size="1rem" color={theme.palette.info.main} />
                                    </Avatar>
                                )}
                                <Box sx={{ width: '100%' }}>
                                    <Paper 
                                        sx={{ 
                                            p: 2,
                                            color: message.role === 'user' 
                                                ? theme.palette.text.primary 
                                                : theme.palette.getContrastText(theme.palette.primary.main),
                                            bgcolor: message.tool_calls 
                                                ? theme.palette.primary.light
                                                : message.role === 'system'
                                                    ? 'primary.light'
                                                    : message.role === 'assistant'
                                                        ? 'primary.main'
                                                        : message.role === 'tool'
                                                            ? 'primary.light'
                                                            : theme.palette.mode === 'dark' 
                                                                ? 'grey.800' 
                                                                : 'grey.100',
                                            mb: 1,
                                            width: '100%'
                                        }}
                                    >
                                        <Typography 
                                            variant="body2" 
                                            color={message.role === 'assistant' && !message.tool_calls ? 'white' : 'inherit'}
                                            sx={{
                                                wordBreak: message.role === 'tool' ? 'break-all' : 'break-word'
                                            }}
                                        >
                                            {message.content}
                                        </Typography>
                                        {message.tool_calls && (
                                            <Box sx={{ mt: 1 }}>
                                                <details open={isOpen} onToggle={() => toggleToolCall(index)}>
                                                    <summary 
                                                        style={{ 
                                                            cursor: 'pointer',
                                                            listStyle: 'none',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between'
                                                        }}
                                                    >
                                                        <Typography 
                                                            variant="body2" 
                                                            sx={{ 
                                                                mb: 0.5,
                                                                fontSize: '0.875rem'
                                                            }}
                                                        >
                                                            <ToolNameTranslator toolName={message.tool_calls[0]?.function?.name || 'Tool Call'} />
                                                        </Typography>
                                                        <IconChevronDown 
                                                            size={18} 
                                                            style={{ 
                                                                transition: 'transform 0.2s',
                                                                transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)'
                                                            }}
                                                        />
                                                    </summary>
                                                    <Box sx={{ mt: 1 }}>
                                                        <Paper 
                                                            elevation={0} 
                                                            sx={{ 
                                                                p: 1.5, 
                                                                bgcolor: theme.palette.mode === 'dark' ? 'grey.900' : 'white',
                                                                color: 'text.primary',
                                                                '& pre': {
                                                                    margin: 0,
                                                                    whiteSpace: 'pre-wrap',
                                                                    wordBreak: 'break-word',
                                                                    fontSize: '0.75rem',
                                                                    fontFamily: 'monospace',
                                                                }
                                                            }}
                                                        >
                                                            <pre>
                                                                {(() => {
                                                                    try {
                                                                        const toolCall = message.tool_calls[0];
                                                                        let args = {};
                                                                        try {
                                                                            args = JSON.parse(toolCall.function.arguments);
                                                                        } catch (e) {
                                                                            // If parsing fails, try to handle string arguments better
                                                                            if (typeof toolCall.function.arguments === 'string') {
                                                                                // Display the raw string if it can't be parsed as JSON
                                                                                return [
                                                                                    `Function: ${toolCall.function.name}`,
                                                                                    `ID: ${toolCall.id}`,
                                                                                    'Parameters:',
                                                                                    `  ${toolCall.function.arguments}`
                                                                                ].join('\n');
                                                                            } else {
                                                                                args = toolCall.function.arguments;
                                                                            }
                                                                        }

                                                                        return [
                                                                            `Function: ${toolCall.function.name}`,
                                                                            `ID: ${toolCall.id}`,
                                                                            'Parameters:',
                                                                            ...Object.entries(args).map(([key, value]) => 
                                                                                `  ${key}: ${typeof value === 'object' ? JSON.stringify(value, null, 2) : value}`
                                                                            )
                                                                        ].join('\n');
                                                                    } catch (error) {
                                                                        return JSON.stringify(message.tool_calls, null, 2);
                                                                    }
                                                                })()}
                                                            </pre>
                                                        </Paper>
                                                        {/* Tool response */}
                                                        {toolResponse && (
                                                            <Paper 
                                                                elevation={0}
                                                                sx={{ 
                                                                    mt: 1,
                                                                    p: 1.5,
                                                                    bgcolor: theme.palette.mode === 'dark' ? 'grey.900' : 'white',
                                                                    color: 'text.primary',
                                                                    borderTop: 1,
                                                                    borderColor: 'divider'
                                                                }}
                                                            >
                                                                <Typography 
                                                                    variant="body2"
                                                                    sx={{ wordBreak: 'break-all' }}
                                                                >
                                                                    Response: {toolResponse.content}
                                                                </Typography>
                                                            </Paper>
                                                        )}
                                                    </Box>
                                                </details>
                                            </Box>
                                        )}
                                    </Paper>
                                </Box>
                            </Box>
                        </Box>
                    );
                })}
            </Box>
        </Paper>
    );
};

export default ConversationTranscript;
