import * as React from 'react';
import { Box, Typography, Paper, Button, Chip, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useGetIdentity, useCreate, useUpdate, useDelete, useGetList, useGetOne, useTranslate } from 'react-admin';
import { IconPlus, IconLock, IconPencil, IconTrash, IconUser } from '@tabler/icons-react';
import { ConversationNoteCreateModal } from '../ConversationNoteCreateModal';
import { ConversationNoteEditModal } from '../ConversationNoteEditModal';
import { useUsersWithPollie } from '../../hooks/useUsersWithPollie';
import { alpha } from '@mui/material/styles';

interface Note {
    id: string;
    title: string;
    status: 'none' | 'open' | 'closed';
    isPrivate: boolean;
    createdBy: string;
    createdAt: string;
    assignedTo: number | null;
    assignedToName: string;
}

interface ConversationNotesProps {
    record?: any;
}

const getColorFromText = (text: string, theme: any): string => {
    const hash = Array.from(text.toLowerCase()).reduce((acc, char) => {
        return char.charCodeAt(0) + ((acc << 5) - acc);
    }, 0);
    const palette = theme.customComponents.noteColors.palette;
    return palette[Math.abs(hash) % palette.length];
};

const ConversationNotes = ({ record }: ConversationNotesProps) => {
    const theme = useTheme();
    const translate = useTranslate();
    const { identity } = useGetIdentity();
    const [createModalOpen, setCreateModalOpen] = React.useState(false);
    const [editModalOpen, setEditModalOpen] = React.useState(false);
    const [selectedNote, setSelectedNote] = React.useState<Note | null>(null);
    const { data: notes } = useGetList('conversation-notes', {
        pagination: { page: 1, perPage: 100 },
        filter: { conversation_id: record?.id },
        sort: { field: 'created_at', order: 'DESC' }
    }, {
        enabled: !!record?.id,
        queryOptions: {
            queryKey: ['conversation-notes', record?.id],
        }
    });

    const { data: assistant } = useGetOne(
        'assistants',
        { id: record?.assistant_id },
        { enabled: !!record?.assistant_id }
    );

    const { users, isLoading: usersLoading } = useUsersWithPollie({
        customerId: assistant?.customer_id
    });

    const [create] = useCreate();
    const [update] = useUpdate();
    const [deleteOne] = useDelete();

    const handleAddNote = (noteData: {
        title: string;
        status: 'none' | 'closed' | 'opened';
        isPrivate: boolean;
        assignedTo?: string;
    }) => {

        create('conversation-notes', {
            data: {
                content: noteData.title,
                status: noteData.status,
                is_private: noteData.isPrivate,
                assigned_to: noteData.assignedTo ? parseInt(noteData.assignedTo, 10) : null,
                conversation_id: record.id,
                created_by: identity?.id,
            }
        });
        setCreateModalOpen(false);
    };

    const handleEditNote = (note: any) => {
        update('conversation-notes', {
            id: note.id,
            data: {
                content: note.title,
                status: note.status,
                is_private: note.isPrivate,
                assigned_to: note.assignedTo ? parseInt(note.assignedTo, 10) : null,
            }
        });
        setEditModalOpen(false);
    };

    const handleDeleteNote = (noteId: string) => {
        deleteOne('conversation-notes', { id: noteId });
    };

    const handleTogglePrivacy = (note: Note) => {
        update('conversation-notes', {
            id: note.id,
            data: {
                is_private: !note.isPrivate,
            }
        });
    };

    const transformedNotes = React.useMemo(() => {
        return notes
            ?.filter((note: any) => note.conversation_id === record?.id)
            ?.map((note: any) => {
                const assignedUser = users?.find((user: any) => user.id === note.assigned_to);
                const createdByUser = users?.find((user: any) => user.id === note.created_by);
                return {
                    id: note.id,
                    title: note.content,
                    status: note.status || 'none',
                    isPrivate: note.is_private || false,
                    createdBy: createdByUser ? `${createdByUser.first_name} ${createdByUser.last_name}` : note.created_by,
                    createdAt: note.created_at,
                    assignedTo: note.assigned_to,
                    assignedToName: assignedUser ? `${assignedUser.first_name} ${assignedUser.last_name}` : '',
                };
            }) || [];
    }, [notes, users, record?.id]);

    // Transform users data for the modals
    const transformedUsers = React.useMemo(() => {
        return users?.map((user: any) => ({
            id: user.id,
            name: `${user.first_name} ${user.last_name}`,
            email: user.email,
        })) || [];
    }, [users]);

    return (
        <Paper sx={{
            p: theme.spacing(2),
            boxShadow: theme.shadows[0],
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Box sx={{ mb: 3 }}>
                <Typography variant="h5" gutterBottom fontWeight="bold" mb={theme.spacing(2)}>
                    {translate('resources.conversations.fields.notes')}
                </Typography>
                <Button
                    startIcon={<IconPlus size={18} />}
                    variant="outlined"
                    onClick={() => setCreateModalOpen(true)}
                >
                    {translate('resources.conversations.add_note')}
                </Button>
            </Box>

            <Box sx={{ 
                flexGrow: 1,
                overflowY: 'auto',
                gap: 2,
                display: 'flex',
                flexDirection: 'column',
            }}>
                {transformedNotes?.length > 0 ? (
                    transformedNotes.map((note: Note) => (
                        <Paper
                            key={note.id}
                            sx={{
                                p: 2,
                                backgroundColor: alpha(getColorFromText(note.title, theme), 0.1),
                                borderRadius: 1
                            }}
                        >
                            <Box sx={{ 
                                display: 'flex', 
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                                mb: 2
                            }}>
                                <Typography variant="subtitle1">{note.title}</Typography>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    {note.status.toLowerCase() !== 'none' && (
                                        <Chip
                                            label={note.status}
                                            size="small"
                                            color={note.status === 'open' ? 'success' : 'error'}
                                            sx={{ textTransform: 'capitalize' }}
                                        />
                                    )}
                                    {note.isPrivate && (
                                        <IconButton 
                                            size="small" 
                                            onClick={() => handleTogglePrivacy(note)}
                                            title={note.isPrivate ? "Make public" : "Make private"}
                                        >
                                            <IconLock size={18} />
                                        </IconButton>
                                    )}
                                    <IconButton size="small" onClick={() => {
                                        setSelectedNote(note);
                                        setEditModalOpen(true);
                                    }}>
                                        <IconPencil size={18} />
                                    </IconButton>
                                    <IconButton size="small" onClick={() => handleDeleteNote(note.id.toString())}>
                                        <IconTrash size={18} />
                                    </IconButton>
                                </Box>
                            </Box>

                            <Box sx={{ color: 'text.secondary', display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="subtitle1">
                                    Created by {note.createdBy} on {new Date(note.createdAt).toLocaleDateString()}
                                </Typography>
                                {note.assignedToName && (
                                    <Box sx={{ display: 'flex', alignItems: 'center'}} mt={theme.spacing(1)}>
                                        <IconUser size={18} />
                                        <Typography variant="subtitle1" ml={theme.spacing(1)}>
                                            Assigned to {note.assignedToName}
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        </Paper>
                    ))
                ) : (
                    <Typography variant="body2" sx={{ textAlign: 'center', color: 'text.secondary' }}>
                        No notes available
                    </Typography>
                )}
            </Box>

            <ConversationNoteCreateModal
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                onSubmit={handleAddNote}
                users={users}
                loading={usersLoading}
            />

            {selectedNote && (
                <ConversationNoteEditModal
                    open={editModalOpen}
                    onClose={() => {
                        setEditModalOpen(false);
                        setSelectedNote(null);
                    }}
                    onSubmit={handleEditNote}
                    note={selectedNote}
                    users={transformedUsers}
                    loading={usersLoading}
                />
            )}
        </Paper>
    );
};

export default ConversationNotes;