import { useTranslate, TopToolbar, ListButton, Create, Edit, SimpleForm, Datagrid, List, TextField, TextInput, BooleanInput, SelectInput, NumberInput } from 'react-admin';
import { Box, Button } from '@mui/material';
import UserIcon from "@mui/icons-material/Group";
import { useRecordContext } from 'react-admin';
import { Link } from 'react-router-dom';
import CustomPagination from './components/react-admin/CustomPagination';
import { ResourceTitle } from './layout/ResourceTitle';

const LinkToRelatedUsers = () => {
    const record = useRecordContext();
    const translate = useTranslate();
    
    // Handler om de event propagatie te stoppen
    const handleClick = (event: any) => {
        // Voorkomt dat de event verder bubbelt
        event.stopPropagation();
    };
    return record ? (
        <Button
            size="small"
            onClick={handleClick}
            color="primary"
            variant="outlined"
            component={Link}
            startIcon={<UserIcon />}
            to={{
                pathname: `/partners/${record.id}/users`,
            }}
        >
            {translate('resources.users.name', { smart_count: 2 })}
        </Button>
    ) : null;
};

const postFilters = [
    <TextInput source="name" alwaysOn />,
];

const PartnerListButton = () => (
    <TopToolbar>    
        <ListButton />
    </TopToolbar>
);

const PartnerForm = () => (
    <SimpleForm>
        <TextInput source="name" />
        <SelectInput 
            source="meta.partner_type" 
            label="Partner type"
            choices={[
                { id: 'reseller', name: 'Reseller' },
                { id: 'distributor', name: 'Distributor' },
                { id: 'referral', name: 'Referral' }
            ]} 
        />
        <BooleanInput source="meta.hide_referral_link" label="Hide referral link" />
        <NumberInput source="meta.revenue_share_percentage" min={0} max={100} label="Revenue share percentage" />
        <NumberInput source="meta.revenue_share_period_months" min={0} max={60} label="Revenue share period (months)" />
    </SimpleForm>
);

export const PartnerList = () => {
    const translate = useTranslate();
    return (
        <Box p={2}>
            <ResourceTitle />
            <List filters={postFilters} exporter={false} pagination={<CustomPagination />}>
                <Datagrid rowClick="show" bulkActionButtons={false}>
                    <TextField source="id" />
                    <TextField source="name" />
                    <LinkToRelatedUsers />
                </Datagrid>
            </List>
        </Box>
    );
};

export const PartnerEdit = () => (
    <Edit actions={<PartnerListButton />}>
        <PartnerForm />
    </Edit>
);

export const PartnerCreate = () => (
    <Create actions={<PartnerListButton />} redirect="list">
        <PartnerForm />
    </Create>
);


