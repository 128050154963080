import { useGetList, useGetIdentity } from 'react-admin';
import { useState, useEffect } from 'react';

const POLLIE_SUPPORT_USER = {
    id: 133,
    first_name: 'Team Pollie',
    last_name: '',
    email: 'support@pollie.ai',
};

interface UseUsersWithPollieProps {
    assistantId?: number;
    customerId?: number;
}

export const useUsersWithPollie = ({ assistantId, customerId }: UseUsersWithPollieProps) => {
    const { data: identity } = useGetIdentity();
    const [customerFilter, setCustomerFilter] = useState({});

    useEffect(() => {
        if (customerId && !identity?.is_admin) {
            setCustomerFilter({
                _or: [
                    { customer_id: customerId },
                    { partner_id: identity?.partner_id }
                ]
            });
        }
    }, [customerId, identity?.partner_id, identity?.is_admin]);

    const { data: usersData, isLoading: usersLoading } = useGetList(
        'users',
        { filter: identity?.is_admin ? {} : customerFilter },
        { enabled: !!(identity?.is_admin || customerId || identity?.partner_id) }
    );


    const usersWithPollie = usersLoading
        ? []
        : Array.from(
            new Map(
                [POLLIE_SUPPORT_USER, ...(usersData ?? [])]
                    .map(user => [user.id, user])
            ).values()
        );

    return {
        users: usersWithPollie,
        isLoading: usersLoading,
    };
}; 