import * as React from 'react';
import { SxProps } from '@mui/material';
import { IconArrowsTransferDown, IconBrandWhatsapp, IconCalendarCancel, IconCalendarCheck, IconCalendarEvent, IconDeviceFloppy, IconLanguage, IconMail, IconMessageCircle, IconPhoneEnd, IconRefresh } from '@tabler/icons-react';
import { useTheme } from '@mui/material';


interface ToolIconProps {
    toolName: string;
    sx?: SxProps;
}

const toolIconMapping: Record<string, string | React.FC<any>> = {
    'get_availability': IconCalendarCheck,
    'get_available_products': IconCalendarCheck,
    'create_booking': IconCalendarEvent,
    'update_booking': IconCalendarEvent,
    'cancel_booking': IconCalendarCancel,
    'send_whatsapp': IconBrandWhatsapp,
    'send_sms': IconMessageCircle,
    'send_email': IconMail,
    'end_phone_call': IconPhoneEnd,
    'transfer_phone_call': IconArrowsTransferDown,
    'save_conversation': IconDeviceFloppy,
    'create_event': IconCalendarEvent,
    'switch_language': IconLanguage
    // Add more mappings as needed
};

const ToolIcon: React.FC<ToolIconProps> = ({ toolName, sx }) => {
    const theme = useTheme();
    const Icon = toolIconMapping[toolName] || IconRefresh;

    return typeof Icon === 'string' ? (
        <img src={Icon} alt={toolName} style={{ width: '32px', height: '32px', objectFit: 'contain', ...sx }} />
    ) : (
        <Icon size={32} style={sx} color={theme.palette.text.secondary} />
    );
};

export default ToolIcon; 