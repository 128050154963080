import { useState, useEffect } from 'react';
import {
  Button,
  Box,
  Typography,
  Card,
  IconButton,
  Chip,
  useTheme
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import { useFormContext } from 'react-hook-form';
import { useTranslate, usePermissions} from 'react-admin';
import TransferRuleModal from '../components/TransferRuleModal';

const TransferRuleCard = ({ rule, index, onEdit, onDelete }) => {
  const translate = useTranslate();
  const theme = useTheme();
  
  return (
    
    <Card sx={{ mb: 2, p: 2, border: `1px solid ${theme.palette.divider}` }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <Box>
          <Typography variant="h6" sx={{ mb: 1 }}>
            <span><Chip label={rule.number} size="small" color='default' /> </span>
          </Typography>
          <Box sx={{ width: '100%' }}>
            {rule.reasons && rule.reasons.length > 0 ? (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {rule.reasons.map((reason, i) => (
                  <Chip 
                    key={i} 
                    label={reason} 
                    size="small" 
                    variant="outlined" 
                    sx={{ mb: 0.5 }}
                  />
                ))}
              </Box>
            ) : ("")}
          </Box>
          
          {rule.availability?.length > 0 && (
            <Box sx={{ mt: 1 }}>
              <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <AccessTimeIcon sx={{ fontSize: 16 }} />
                {rule.availability.map((schedule, i) => (
                  `${schedule.days.length} days, ${schedule.start_time}-${schedule.end_time}`
                )).join('; ')}
              </Typography>
            </Box>
          )}
          {rule.closed_dates?.length > 0 && (
            <Box sx={{ mt: 1 }}>
              <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <EventBusyIcon sx={{ fontSize: 16 }} />
                {rule.closed_dates.map((date, i) => (
                  <Chip 
                    key={i} 
                    label={date} 
                    size="small" 
                    variant="outlined" 
                    color="error" 
                    sx={{ ml: i > 0 ? 0.5 : 0 }}
                  />
                ))}
              </Typography>
            </Box>
          )}
        </Box>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <IconButton onClick={onEdit} size="small">
            <EditIcon />
          </IconButton>
          <IconButton onClick={onDelete} size="small">
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
    </Card>
  );
};

const TransferRuleSection = () => {
  const { setValue, getValues } = useFormContext();
  const translate = useTranslate();
  const [editingIndex, setEditingIndex] = useState(null);
  const [tempRule, setTempRule] = useState(null);
  const [transferRules, setTransferRules] = useState([]);
  const {permissions} = usePermissions();
  const emptyRule = {
    number: "",
    reasons: [""],
    availability: [{
      days: [],
      start_time: "09:00",
      end_time: "17:00"
    }],
    closed_dates: [],
    closed_instruction: "This destination is closed right now. Ask the caller if you can pass on a message or if there's anything else you can do.",
    unavailable_instruction: "The transfer was not answered. Ask the caller if you can pass on a message or if there's anything else you can do."
  };

  // Load existing rules when component mounts or config changes
  useEffect(() => {
    const formData = getValues();
    const transferTool = formData?.config?.tools?.find(tool => 
      tool.name === 'call.transfer.v2' || tool.name === 'call.transfer'
    );
    const rules = transferTool?.config?.transfer_rules || [];
    setTransferRules(rules);
  }, [getValues]);

  const handleAddRule = () => {
    setTempRule(emptyRule);
    setEditingIndex(-1); // Use -1 to indicate a new rule
  };

  const handleEditRule = (index) => {
    setTempRule(transferRules[index]);
    setEditingIndex(index);
  };

  const handleDeleteRule = (index) => {
    const formData = getValues();
    const newConfig = { ...formData.config };
    const toolIndex = newConfig.tools.findIndex(tool => 
      tool.name === 'call.transfer.v2' || tool.name === 'call.transfer'
    );
    
    if (toolIndex !== -1) {
      newConfig.tools[toolIndex].config.transfer_rules = [
        ...transferRules.slice(0, index),
        ...transferRules.slice(index + 1)
      ];
      
      setValue('config', newConfig, {
        shouldDirty: true,
        shouldTouch: true
      });
      
      setTransferRules(newConfig.tools[toolIndex].config.transfer_rules);
    }
  };

  const handleCloseModal = () => {
    setEditingIndex(null);
    setTempRule(null);
  };

  const handleSaveRule = (rule, index) => {
    const formData = getValues();
    const newConfig = { ...formData.config };
    
    let toolIndex = newConfig.tools?.findIndex(tool => 
      tool.name === 'call.transfer.v2' || tool.name === 'call.transfer'
    );
    
    if (toolIndex === -1 || toolIndex === undefined) {
      // If tool doesn't exist, create it
      if (!newConfig.tools) {
        newConfig.tools = [];
      }
      
      newConfig.tools.push({
        name: 'call.transfer.v2',
        config: {
          transfer_rules: []
        }
      });
      toolIndex = newConfig.tools.length - 1;
    }

    // Ensure config and transfer_rules exist
    if (!newConfig.tools[toolIndex].config) {
      newConfig.tools[toolIndex].config = {};
    }
    if (!newConfig.tools[toolIndex].config.transfer_rules) {
      newConfig.tools[toolIndex].config.transfer_rules = [];
    }

    // Update or add the rule
    if (index >= 0) {
      newConfig.tools[toolIndex].config.transfer_rules[index] = rule;
    } else {
      newConfig.tools[toolIndex].config.transfer_rules.push(rule);
    }
    
    // Update the form data
    setValue('config', newConfig, {
      shouldDirty: true,
      shouldTouch: true
    });
    
    // Update local state
    setTransferRules(newConfig.tools[toolIndex].config.transfer_rules);
    setEditingIndex(null);
    setTempRule(null);
  };

  return (
    <>
        <Box sx={{ mb: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2 }}>
            <Button
              startIcon={<AddIcon />}
              variant="outlined"
              onClick={handleAddRule}
              size="small"
            >
              {translate('resources.assistants.fields.add_new_rule')}
            </Button>
          </Box>
          
          {transferRules.length === 0 ? (
            <Typography variant="body2" color="textSecondary" sx={{ py: 2, textAlign: 'left' }}>
              {translate('resources.assistants.fields.no_transfer_rules')}
            </Typography>
          ) : (
            <Box>
              {transferRules.map((rule, index) => (
                <TransferRuleCard
                  key={index}
                  rule={rule}
                  index={index}
                  onEdit={() => handleEditRule(index)}
                  onDelete={() => handleDeleteRule(index)}
                />
              ))}
            </Box>
          )}

          {editingIndex !== null && (
            <TransferRuleModal
              open={true}
              onClose={handleCloseModal}
              rule={tempRule}
              index={editingIndex}
              formData={{
                config: getValues().config,
                onSave: handleSaveRule
              }}
              translate={translate}
            />
          )}
        </Box>
    </>
  );
};

export default TransferRuleSection;
