declare module '@mui/material/styles' {
  interface TypographyVariants {
    chip: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    chip?: React.CSSProperties;
  }
  interface Theme {
    customComponents: {
      noteColors: {
        palette: string[];
      };
      StructuredJsonField: {
        roles: Record<string, {
          bgColor: string;
          textColor: string;
          avatarColor: string;
        }>;
      };
    }
  }
  interface ThemeOptions {
    customComponents?: {
      noteColors?: {
        palette: string[];
      };
      StructuredJsonField?: {
        roles?: Record<string, {
          bgColor: string;
          textColor: string;
          avatarColor: string;
        }>;
      };
    }
  }
}

import { defaultTheme, defaultDarkTheme } from 'react-admin';
import { deepmerge } from '@mui/utils';

export const myTheme = deepmerge(defaultTheme, {
    components: {
        ...defaultTheme.components,
        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
            },
            styleOverrides: {
                root: {
                    '&.custom-multiline .MuiInputBase-inputMultiline': {
                        minHeight: '50px', // Deze stijl zal alleen toegepast worden op textareas met de 'custom-min-height' klasse
                        fontSize: '1rem',
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: 'none', // remove app bar shadow
                    textTransform: 'none',
                    fontWeight: 'bold',
                    padding: '8px 16px',
                    lineHeight: '1',
                    '&.MuiButton-containedPrimary': {
                        backgroundColor: '#172121', // Black background for primary buttons
                        color: '#FFFFFF', // White text color for contrast
                        '&:hover': {
                            backgroundColor: '#2A3C3C', // Darker shade on hover
                        },
                    },
                    '&.MuiButton-outlinedPrimary': {
                        color: '#172121', // Black text color for outlined primary buttons
                        borderColor: '#172121', // Black border for outlined primary buttons
                    },
                },
                contained: {
                    backgroundColor: '#172121',
                    '&:hover': {
                        backgroundColor: '#2A3C3C'
                    },
                    '&.Mui-disabled': {
                        backgroundColor: 'rgba(0, 0, 0, 0.12)',
                        color: 'rgba(0, 0, 0, 0.26)',
                        border: 'none',
                        boxShadow: 'none'
                    }
                },
                outlined: {
                    backgroundColor: 'transparent',
                    boxShadow: 'inset 0 0 0 1px currentColor',
                    border: 'none',
                    '&:hover': {
                        backgroundColor: 'rgba(23, 33, 33, 0.1)',
                        boxShadow: 'inset 0 0 0 1.5px currentColor',
                        border: 'none',
                        borderColor: 'none'
                    },
                },
                MuiDisabled: {
                    borderColor: 'none'
                }
            }
        },
        RaSidebar: {
            styleOverrides: {
                docked: {
                    display: 'none'
                }
            }
        },
        RaToolbar: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white',
                    padding: 2,
                }
            }
        },
        RaTopToolbar: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white !important',

                }
            }
        },
        RaListToolbar: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white !important',
                    flexDirection: 'row !important',
                }
            }
        },
        // override default menu item state
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    color: '#172121', // Donkerblauwe kleur voor alle buttons
                }
            }
        },
        RaDatagrid: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(247, 247, 242, 0.2)',
                    borderRadius: '12px 12px 0 0',
                    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                    overflow: 'hidden',  
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    
                    '& .RaDatagrid-tableWrapper': {  
                        overflowX: 'auto',  
                        overflowY: 'auto',  // Enable vertical scrolling
                        width: '100%',
                        flex: 1,            // Allow table to fill available space
                    },
                    '& table': {
                        minWidth: '650px',  
                        width: '100%',
                        tableLayout: 'fixed', // Fixed table layout for better column control
                    },
                    '& .MuiTableHead-root': {
                        position: 'sticky',  // Sticky header
                        top: 0,
                        zIndex: 1,
                        backgroundColor: 'rgba(247, 247, 242, 0.2)'
                    },
                    '& .MuiTableCell-root': {
                        padding: '12px 16px',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                        whiteSpace: 'nowrap',
                    },
                    '& .RaDatagrid-headerCell': {
                        fontWeight: 600,
                        padding: '12px 16px',
                        height: '48px',
                        whiteSpace: 'nowrap',
                        verticalAlign: 'middle',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                        backgroundColor: 'rgba(247, 247, 242)'
                    },
                    '& .RaDatagrid-row': {
                        cursor: 'pointer',
                        backgroundColor: 'rgba(247, 247, 242, 0.2)',
                        transition: 'all 0.2s ease',
                        '&:hover': {
                            backgroundColor: '#f8fafc',
                        },
                    },
                    '& .MuiToolbar-root.MuiTablePagination-toolbar': {
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        backgroundColor: '#fff',
                        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                        '& .MuiTablePagination-spacer': {
                            display: 'none',
                        },
                    },
                    '& .MuiTablePagination-selectLabel': {
                        margin: 0,
                    },
                    '& .MuiTablePagination-select': {
                        marginLeft: '8px',
                        marginRight: '32px',
                        paddingRight: '24px',
                        border: 'none',
                        '&:focus': {
                            backgroundColor: 'transparent'
                        }
                    },
                    '& .MuiTablePagination-displayedRows': {
                        margin: 0,
                    },
                    '& .MuiTablePagination-actions': {
                        display: 'flex',
                        gap: '8px',
                        '& .MuiPagination-ul': {
                            display: 'flex',
                            gap: '8px',
                        },
                    }
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {        
                    borderRadius: 4,
                    color: '#172121', // Light theme text color
                    '&:hover': {
                        backgroundColor: 'rgba(23, 33, 33, 0.1) !important',
                    },
                    '&.RaMenuItemLink-active': {
                        fontWeight: 'bold',
                    },
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                docked: {
                    borderRight: '1px solid rgba(0, 0, 0, 0.12)', // Voeg een rechterrand toe
                },
            },
        },
        // remove appbar shadow
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: 'none', 
                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)', 
                },
            },
        },
        // remove paper shadow and table borders
        MuiPaper: {
            styleOverrides: {
                root: {
                    border: 'none', // Verwijder de linker rand
                    boxShadow: 'none', // remove app ba
                    backgroundColor: '#ffffff',
                },
            },
        },
        // column headers in bold
        MuiTableCell: {
            styleOverrides: {
                root: {
                    maxHeight: '5.5em',    // Increased to allow ~4 lines plus padding
                    padding: '12px 16px',   // Explicit padding
                    verticalAlign: 'middle',
                    '& > *': {
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: 'vertical',
                        
                    }
                },
                head: {
                    fontWeight: 'bold',
                    borderBottom: '2px solid rgba(0, 0, 0, 0.22)',
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    minHeight: 'auto',
                    borderRadius: 12,
                },
                flexContainer: {
                    padding: 4,
                }
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    '&.MuiInputBase-multiline': {
                        height: 'auto', // Reset height for multiline inputs
                        width: '100%'
                    },
                    '& input::placeholder': {
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden'
                    }
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    minWidth: 0,
                    borderRadius: 4,
                    minHeight: 'auto',
                    textTransform: 'none',
                    '&.Mui-selected': {
                        fontWeight: 'bold',
                    },
                    '& .MuiTab-iconWrapper': {
                        marginRight: 8,
                    },
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    margin: 0,
                    padding: 16,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    fontWeight: 'bold'
                }
            }
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    border: '1px solid rgba(54, 143, 139, 0.3)', // primary.main with 30% opacity
                    '&.Mui-selected': {
                        backgroundColor: 'rgba(54, 143, 139, 0.15)', // primary.main with 15% opacity
                        color: '#368F8B', // primary.main
                        '&:hover': {
                            backgroundColor: 'rgba(54, 143, 139, 0.25)', // primary.main with 25% opacity
                        }
                    }
                }
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                }
            }
        },
        RaList: {
            styleOverrides: {
                root: {
                    '& .RaList-main': {
                        overflow: 'hidden',
                        '& .MuiPaper-root': {
                            borderRadius: 0,
                        }
                    },
                    '& .RaList-content': {
                        overflow: 'hidden',
                    },
                    '& .RaList-actions': {
                        paddingBottom: '16px',
                    },
                }
            }
        },
        RaCreateButton: {
            styleOverrides: {
                root: {
                    backgroundColor: '#172121', // Using the primary teal color
                    color: 'white',
                    '&:hover': {
                        backgroundColor: '#2d3838', // Slightly lighter shade for hover
                    },
                }
            }
        },
        RaFilterForm: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    padding: '0px',
                    minHeight: '0px !important',
                }
            }
        },
        RaFilterFormInput: {
            styleOverrides: {
                root: {
                    '& .RaFilterFormInput-spacer': {
                        display: 'none !important',
                        width: '0px !important',
                    }
                }
            }
        },
    },
    sidebar: {
        width: 210,
        closedWidth: 55,
    },
    palette: {
        primary: {
            main: '#368F8B',
        },
        secondary: {
            main: '#ffffff',
            contrastText: '#141c1c',
        },
        text: {
            primary: '#172121',
            secondary:'#586274'
        },
        background: {
            default: '#FFFFFF',
            paper: '#F7F7F2',
        },
        info: {
            main: '#FC8077',
        },
        error: {
            main: '#E57373',
        },
        warning: {
            main: '#FFC107',
        },
        success: {
            main: '#81C784',
        },
    },
    spacing: 8,
    shape: {
        borderRadius: 12,
    },
    typography: {
        fontFamily: ['Figtree', '-apple-system', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
        fontSize: 12,
        h1: {
            fontSize: '2.5rem',     // 35px
            fontWeight: 700,
            lineHeight: 1.2,
        },
        h2: {
            fontSize: '2rem',       // 28px
            fontWeight: 600,
            lineHeight: 1.3,
        },
        h3: {
            fontSize: '1.5rem',     // 21px
            fontWeight: 600,
            lineHeight: 1.4,
        },
        h4: {
            fontSize: '1.25rem',    // 17.5px
            fontWeight: 600,
            lineHeight: 1.4,
        },
        body1: {
            fontSize: '1rem',       // 14px
            lineHeight: 1.5,
        },
        body2: {
            fontSize: '0.875rem',   // 12.25px
            lineHeight: 1.5,
        },
        button: {
            fontSize: '0.875rem',   // 12.25px
            fontWeight: 500,
            textTransform: 'none',
            minHeight: '2.25rem',
        },
        caption: {
            fontSize: '0.75rem',    // 10.5px
            lineHeight: 1.5,
        }
    },
    // Custom sectie voor StructuredJsonField
    customComponents: {
        noteColors: {
            palette: [
                // Core colors
                '#FC8077', // Coral
                '#368F8B', // Teal
                //'#F7F7F2', // Off-white
                //'#FBA100', // Yellow
                '#B3B8C8', // Deep Lavender
                '#D0BFD8', // Soft Lavender
                '#E0DFF0', // Pale Lavender
                '#91B6F2', // Classic Periwinkle
                '#BDC9E1', // Muted Periwinkle
                '#D6E3F7', // Light Periwinkle
                '#FFD1B3', // Pastel Peach
                '#FFC2CC', // Soft Blush Pink
                '#FFDDC1', // Light Apricot
                //'#FFF1B2', // Powder Yellow
                //'#FAE5D3', // Creamy Beige
            ],
        },
        StructuredJsonField: {
            roles: {
                assistant: {
                    bgColor: 'white',
                    textColor: 'default',
                    avatarColor: '#368F8B',
                },
                user: {
                    bgColor: '#f7f7f7',
                    textColor: 'default',
                    avatarColor: '#FC8077',
                },
                system: {
                    bgColor: 'white',
                    textColor: '#777777',
                    avatarColor: '#777777',
                },
                tool: {
                    bgColor: '#fff8f7',
                    textColor: '#777777',
                    avatarColor: '#777777',
                },
                default: {
                    bgColor: 'white',
                    textColor: 'default',
                    avatarColor: '#999999',
                }
            }
        }
    }
});

// Dark theme
export const myDarkTheme = deepmerge(defaultDarkTheme, {
    palette: {
        primary: {
            main: '#368F8B',
        },
        secondary: {
            main: '#ffffff',
            contrastText: '#ffffff',
        },
        text: {
            primary: '#ffffff',
            secondary:'#ffffff'
        },
        background: {
            default: `#1e1e1e`,
            paper: 'black',
        },
        info: {
            main: '#FC8077',
        },
        error: {
            main: '#E57373',
        },
        warning: {
            main: '#FFC107',
        },
        success: {
            main: '#81C784',
        },
    },
    components: {
        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
            },
            styleOverrides: {
                root: {
                    '&.custom-multiline .MuiInputBase-inputMultiline': {
                        minHeight: '50px', // Deze stijl zal alleen toegepast worden op textareas met de 'custom-min-height' klasse
                        fontSize: '1rem',
                    }
                }
            }
        },
        MuiDrawer: {
            styleOverrides: {
                docked: {
                    borderRight: '1px solid rgba(0, 0, 0, 0.12)', // Voeg een rechterrand toe
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    textTransform: 'none',
                    fontWeight: 'bold',
                    padding: '8px 16px',
                    lineHeight: '1',
                    '&.MuiButton-containedPrimary': {
                        backgroundColor: '#368F8B', // Black background for primary buttons
                        color: '#FFFFFF', // White text color for contrast
                        '&:hover': {
                            backgroundColor: '#2d7471'
                        },
                    },
                    '&.MuiButton-outlinedPrimary': {
                        color: '#ffffff', // White text color for outlined primary buttons
                        borderColor: '#ffffff', // White border for outlined primary buttons
                    },
                    '&.Mui-disabled': {
                        backgroundColor: 'rgba(255, 255, 255, 0.12)',
                        color: 'rgba(255, 255, 255, 0.3)',
                        border: 'none',
                        boxShadow: 'none'
                    }
                },
                contained: {
                    backgroundColor: '#ffffff',
                    '&:hover': {
                        backgroundColor: '#e6e6e6'
                    }
                },
                outlined: {
                    backgroundColor: 'transparent',
                    color: '#ffffff',
                    boxShadow: 'inset 0 0 0 1px rgba(255, 255, 255, 0.12)',
                    border: 'none',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        boxShadow: 'inset 0 0 0 1.5px #ffffff',
                        color: '#ffffff',
                        border: 'none',
                        borderColor: 'none'
                    },
                },
                MuiDisabled: {
                    borderColor: 'none'
                }
            }
        },
        RaSidebar: {
            styleOverrides: {
                docked: {
                    display: 'none'
                }
            }
        },
        RaToolbar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#1e1e1e !important',
                    padding: 2,
                }
            }
        },
        RaTopToolbar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#1e1e1e !important'
                }
            }
        },
        RaListToolbar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#1e1e1e !important',
                    flexDirection: 'row !important',
                }
            }
        },
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    color: '#ffffff',
                }
            }
        },
        RaDatagrid: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    borderRadius: '12px 12px 0 0',
                    borderTop: '1px solid rgba(255, 255, 255, 0.12)',
                    borderLeft: '1px solid rgba(255, 255, 255, 0.12)',
                    borderRight: '1px solid rgba(255, 255, 255, 0.12)',
                    overflow: 'hidden',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    
                    '& .RaDatagrid-tableWrapper': {
                        overflowX: 'auto',
                        overflowY: 'auto',
                        width: '100%',
                        flex: 1,
                    },
                    '& table': {
                        minWidth: '650px',
                        width: '100%',
                        tableLayout: 'fixed',
                    },
                    '& .MuiTableHead-root': {
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        backgroundColor: 'rgba(0, 0, 0, 0.2)'
                    },
                    '& .RaDatagrid-headerCell': {
                        fontWeight: 600,
                        padding: '12px 16px',
                        height: '48px',
                        whiteSpace: 'nowrap',
                        verticalAlign: 'middle',
                        borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
                        backgroundColor: 'rgba(0, 0, 0, 0.3)'
                    },
                    '& .RaDatagrid-row': {
                        cursor: 'pointer',
                        backgroundColor: 'rgba(0, 0, 0, 0.2)',
                        transition: 'all 0.2s ease',
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.3)',
                        },
                    },
                    '& .MuiTableCell-root': {
                        padding: '12px 16px',
                        borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
                    },
                    '& .MuiToolbar-root.MuiTablePagination-toolbar': {
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        backgroundColor: '#fff',
                        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                        '& .MuiTablePagination-spacer': {
                            display: 'none',
                        },
                    },
                    '& .MuiTablePagination-selectLabel': {
                        margin: 0,
                    },
                    '& .MuiTablePagination-select': {
                        marginLeft: '8px',
                        marginRight: '32px',
                        paddingRight: '24px',
                        border: 'none',
                        '&:focus': {
                            backgroundColor: 'transparent'
                        }
                    },
                    '& .MuiTablePagination-displayedRows': {
                        margin: 0,
                    },
                    '& .MuiTablePagination-actions': {
                        display: 'flex',
                        gap: '8px',
                        '& .MuiPagination-ul': {
                            display: 'flex',
                            gap: '8px',
                        },
                    }
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {        
                    borderRadius: 4,
                    color: '#ffffff', // Dark theme text color
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.1) !important',
                    },
                    '&.RaMenuItemLink-active': {
                        fontWeight: 'bold',
                    },
                },
            },
        },   
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    border: 'none',
                    boxShadow: 'none',
                    backgroundColor: '#1e1e1e',
                    backgroundImage: 'none',
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    maxHeight: '5.5em',    // Increased to allow ~4 lines plus padding
                    padding: '12px 16px',   // Explicit padding
                    verticalAlign: 'middle',
                    '& > *': {
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitLineClamp: 3,     // Allow 3 lines of text
                        WebkitBoxOrient: 'vertical',
                    }
                },
                head: {
                    fontWeight: 'bold',
                    borderBottom: '2px solid rgba(255, 255, 255, 0.22)',
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    minHeight: 'auto',
                    borderRadius: 12,
                },
                flexContainer: {
                    padding: 4,
                }
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    padding: '0 12px', // Add horizontal padding
                    '&.MuiInputBase-multiline': {
                        height: 'auto', // Reset height for multiline inputs
                        width: '100%'
                    },
                    '& input::placeholder': {
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden'
                    }
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    minWidth: 0,
                    borderRadius: 4,
                    minHeight: 'auto',
                    textTransform: 'none',
                    '&.Mui-selected': {
                        fontWeight: 'bold',
                    },
                    '& .MuiTab-iconWrapper': {
                        marginRight: 8,
                    },
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    margin: 0,
                    padding: 16,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    fontWeight: 'bold'
                }
            }
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    border: '1px solid rgba(54, 143, 139, 0.3)',
                    '&.Mui-selected': {
                        backgroundColor: 'rgba(54, 143, 139, 0.15)',
                        color: '#368F8B',
                        '&:hover': {
                            backgroundColor: 'rgba(54, 143, 139, 0.25)',
                        }
                    }
                }
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                }
            }
        },
        RaList: {
            styleOverrides: {
                root: {
                    '& .RaList-main': {
                        overflow: 'hidden',
                        '& .MuiPaper-root': {
                            borderRadius: 0,
                        }
                    },
                    '& .RaList-content': {
                        overflow: 'hidden',
                    },
                    '& .RaList-actions': {
                        paddingBottom: '16px',
                    }
                }
            }
        },
        RaCreateButton: {
            styleOverrides: {
                root: {
                    backgroundColor: '#368F8B', // Using the primary teal color
                    color: 'white',
                    '&:hover': {
                        backgroundColor: '#2d7471', // Slightly darker shade for hover
                    },
                }
            }
        },
        RaFilterForm: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    padding: '0px',
                    minHeight: '0px !important',
                }
            }
        },
    },
    sidebar: {
        width: 210, 
        closedWidth: 55, 
    },
    shape: {
        borderRadius: 12,
    },
    customComponents: {
        noteColors: {
            palette: [
                // Core colors
                '#FC8077', // Coral
                '#368F8B', // Teal
                //'#F7F7F2', // Off-white
                //'#FBA100', // Yellow
                '#B3B8C8', // Deep Lavender
                '#D0BFD8', // Soft Lavender
                '#E0DFF0', // Pale Lavender
                '#91B6F2', // Classic Periwinkle
                '#BDC9E1', // Muted Periwinkle
                '#D6E3F7', // Light Periwinkle
                '#FFD1B3', // Pastel Peach
                '#FFC2CC', // Soft Blush Pink
                '#FFDDC1', // Light Apricot
                //'#FFF1B2', // Powder Yellow
                //'#FAE5D3', // Creamy Beige
            ],
        },
        StructuredJsonField: {
            roles: {
                assistant: {
                    bgColor: '#1e1e1e',
                    textColor: '#ffffff',
                    avatarColor: '#368F8B',
                },
                user: {
                    bgColor: '#2d2d2d',
                    textColor: '#ffffff',
                    avatarColor: '#FC8077',
                },
                system: {
                    bgColor: '#1e1e1e',
                    textColor: '#a0a0a0',
                    avatarColor: '#666666',
                },
                tool: {
                    bgColor: '#252525',
                    textColor: '#a0a0a0',
                    avatarColor: '#666666',
                },
                default: {
                    bgColor: '#1e1e1e',
                    textColor: '#ffffff',
                    avatarColor: '#666666',
                }
            }
        }
    },
    typography: {
        fontFamily: ['Figtree', '-apple-system', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
        fontSize: 12,
        h1: {
            fontSize: '2.5rem',     // 35px
            fontWeight: 700,
            lineHeight: 1.2,
        },
        h2: {
            fontSize: '2rem',       // 28px
            fontWeight: 600,
            lineHeight: 1.3,
        },
        h3: {
            fontSize: '1.5rem',     // 21px
            fontWeight: 600,
            lineHeight: 1.4,
        },
        h4: {
            fontSize: '1.25rem',    // 17.5px
            fontWeight: 600,
            lineHeight: 1.4,
        },
        body1: {
            fontSize: '1rem',       // 14px
            lineHeight: 1.5,
        },
        body2: {
            fontSize: '0.875rem',   // 12.25px
            lineHeight: 1.5,
        },
        button: {
            fontSize: '0.875rem',   // 12.25px
            fontWeight: 500,
            textTransform: 'none',
            minHeight: '2.25rem',
        },
        caption: {
            fontSize: '0.75rem',    // 10.5px
            lineHeight: 1.5,
        }
    }
});

export default myTheme;