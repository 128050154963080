import { useState } from 'react';
import { TextInputProps, useTranslate } from 'react-admin';
import { TextInput } from 'react-admin';
type PasswordMode = 'create' | 'reset' | 'change' | 'confirmation';

interface PasswordInputProps extends Omit<TextInputProps, 'type'> {
  mode: PasswordMode;
  isRequired?: boolean;
  label?: string;
  source: string;
}

const validatePassword = [
  (value: string) => {
    if (!value) return 'app.validation.required';
    
    const missingCriteria = [];
    
    if (value.length < 8) return 'app.validation.minLength';
    if (value.length > 64) return 'app.validation.maxLength';
    
    if (!/[A-Z]/.test(value)) missingCriteria.push('app.validation.password.uppercase');
    if (!/[a-z]/.test(value)) missingCriteria.push('app.validation.password.lowercase');
    if (!/[0-9]/.test(value)) missingCriteria.push('app.validation.password.number');
    if (!/[!@#$%^&*]/.test(value)) missingCriteria.push('app.validation.password.special');

    if (missingCriteria.length > 0) {
      return missingCriteria.join(', ');
    }

    return undefined;
  }
];

const getValidation = (mode: PasswordMode) => {
  if (mode === 'confirmation') {
    return [
      (value: string, values: any) => 
        value !== values.password
          ? 'app.validation.password.mismatch'
          : undefined,
    ];
  }

  const baseValidation = validatePassword;

  if (mode === 'change') {
    return [
      ...baseValidation,
      (value: string, values: any) => 
        value === values.oldPassword
          ? 'app.validation.password.same'
          : undefined,
    ];
  }

  return baseValidation;
};

const PasswordInput = ({
  mode,
  isRequired = true,
  helperText,
  label,
  source,
  ...rest
}: PasswordInputProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const translate = useTranslate();

  const validate = getValidation(mode);

  const getHelperText = () => {
    if (helperText) return helperText;

    switch (mode) {
      case 'create':
        return translate('ra.message.password.create');
      case 'reset':
        return translate('ra.message.password.reset');
      case 'change':
        return translate('ra.message.password.change');
      case 'confirmation':
        return translate('ra.message.password.confirmation');
      default:
        return '';
    }
  };

  return (
    <TextInput
      source={source}
      label={label}
      validate={validate}
      type={showPassword ? 'text' : 'password'}
      required={isRequired}
      sx={{ width: '100%' }}
      autoComplete='new-password'
    />
  );
};

export default PasswordInput;
