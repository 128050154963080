import { Create, Edit, SimpleForm, Datagrid, List, TextField, TextInput } from 'react-admin';
import CustomMDXEditor from './CustomMdxEditor';
import CustomPagination from './components/react-admin/CustomPagination';
import { ResourceTitle } from './layout/ResourceTitle';
import { Box } from '@mui/material';


export const PromptTemplateList = () => (
    <Box p={2}>
        <ResourceTitle/>
        <List exporter={false} pagination={<CustomPagination />}>
            <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="description" />
            </Datagrid>
        </List>
    </Box>
    );

export const PromptTemplateEdit = () => {
    
    return (
        <Edit mutationMode='optimistic'>
            <SimpleForm>
                <TextInput source="name" />
                <TextInput source="description" fullWidth />
                <CustomMDXEditor source="content" />

            </SimpleForm>
        </Edit>
    );
}

export const PromptTemplateCreate = () => {

    return (
        <Create>
            <SimpleForm>
                <TextInput source="name" />
                <TextInput source="description" fullWidth />
                <CustomMDXEditor source="content" />
            </SimpleForm>
        </Create>
    );
}


