import { useNavigate } from 'react-router-dom';

import { useNotify, Toolbar, SaveButton, Edit, SimpleForm, TextInput, required, email, useAuthenticated, BooleanInput, useTranslate, useTheme} from 'react-admin';
import { useRefresh, useGetIdentity } from 'react-admin';
import { useDataProvider } from 'react-admin';
import PhoneNumberInput from './PhoneNumberInput';
import { Typography, Box } from '@mui/material';
import { CustomLocalesMenuButton } from './layout/CustomLocaleMenuButton';

export const equalToPassword = (value: any, allValues: any) => {
  if (value !== allValues.new_password) {
      return 'The two passwords must match';
  }
}
const ProfileEditToolbar = () => {
  const refresh = useRefresh();

  return (  
    <Toolbar>
      <SaveButton onClick={refresh} />
    </Toolbar>
  )
};


export const Profile = () => {
  
  useAuthenticated(); 

  const translate = useTranslate();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const navigate = useNavigate();
  const { data, refetch, isLoading, error } = useGetIdentity();
  const [theme, setTheme] = useTheme();

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error: {error.message}</div>;
  }


  const handleSubmit = (values) => {
    const updatedValues = { ...values, email: data.email };
    dataProvider.custom('users/ra/update/'+data.id, {
      method: 'POST',
      postdata: updatedValues
    }).then(() => {
        notify('resources.generic.profile_updated', { type: 'info' }); // Toon de notificatie
        refetch();
        navigate('/'); // Navigeer naar de hoofdpagina na succes
    });
  };

  return (
    <Box p={2}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
        <Typography
          variant="h3"
          fontWeight="bold"
        >
          {translate('resources.generic.my_profile')}
        </Typography>
      </div>
      <Edit title="resources.generic.my_profile" id={data.id} resource="users" mutationMode='pessimistic' redirect="/">
      <Typography variant="h5" fontWeight="bold" sx={{ mb: 1 }}>
          {translate('resources.profile.user_info')}
      </Typography>
      <SimpleForm toolbar={<ProfileEditToolbar  />} sx={{ maxWidth: 400 }} onSubmit={handleSubmit}>
        <TextInput source="first_name" validate={required()} fullWidth />
        <TextInput source="last_name" validate={required()} fullWidth />
        <TextInput source="email" type="email" validate={[required(),email()]} disabled fullWidth/>
        <PhoneNumberInput source="phone_number" validate={required()} fullWidth/>
        <BooleanInput
            source="two_factor_enabled"
            label="resources.users.fields.requires_2fa"
            validate={(value, allValues) => {
              if (value && (!allValues.phone_number || allValues.phone_number.trim() === '')) {
                return 'app.validation.phone_number_required_for_2fa';
              }
              return undefined;
            }}
        />
        <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
          {translate('resources.profile.system_preferences')}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              {translate('resources.generic.locale')}
            </Typography>
            <CustomLocalesMenuButton />
          </Box>
        </Box>
      </SimpleForm>
      </Edit>
    </Box>
  );
};

export default Profile;