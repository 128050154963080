import React, { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import { useLocation } from 'react-router-dom';

// Globale audio variabele
export let audio: HTMLAudioElement | null = null;

interface PlayAudioButtonProps {
    endpoint: string;
    params: any;
    size?: "small" | "medium" | "large";
}

export const PlayAudioButton = ({ endpoint, params, size = "large" }: PlayAudioButtonProps) => {
    // State toevoegen voor het bijhouden van de afspeelstatus
    const [isPlaying, setIsPlaying] = useState(false);
    const location = useLocation(); // Haal de huidige locatie op met React Router

    const handleClick = async (e: React.MouseEvent) => {
        // Prevent event from bubbling up
        e.stopPropagation();
        e.preventDefault();
        
        if (isPlaying) {
            stopAudio();
        } else {
            await playAudio();
        }
    };

    const playAudio = async () => {
        const apiUrl = import.meta.env.VITE_API_URL;
        const fullUrl = apiUrl + endpoint;
        const auth = localStorage.getItem('auth');
        const token = auth ? JSON.parse(auth).access_token : null;

        try {
            // Stop de huidige audio indien deze speelt en wijzig de afspeelstatus
            if (audio) {
                audio.pause();
                audio.currentTime = 0;
                setIsPlaying(false);
            }

            const response = await fetch(fullUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(params)
            });
            const audioBlob = await response.blob();
            const audioUrl = URL.createObjectURL(audioBlob);
            audio = new Audio(audioUrl);
            
            // Add event listeners for all relevant audio state changes
            audio.addEventListener('play', () => setIsPlaying(true));
            audio.addEventListener('pause', () => setIsPlaying(false));
            audio.addEventListener('ended', () => setIsPlaying(false));
            audio.addEventListener('error', () => setIsPlaying(false));
            
            audio.play();
            setIsPlaying(true);
            window.dispatchEvent(new CustomEvent('voicePlaying', { detail: { voice_id: params.voice_id } }));
            
        } catch (error) {
            console.error('Fout bij het afspelen van audio:', error);
            setIsPlaying(false);
        }
    };

    const stopAudio = () => {
        if (audio) {
            audio.pause();
            audio.currentTime = 0;
            setIsPlaying(false); // Update de afspeelstatus
        }
    };

    useEffect(() => {
        // Stop de audio wanneer de locatie verandert (d.w.z. de gebruiker navigeert weg)
        return () => {
            if (audio) {
                audio.pause();
                audio.currentTime = 0;
                setIsPlaying(false);
            }
        };
    }, [location]);

    // Listen for events from other PlayAudioButton instances
    useEffect(() => {
        const handleVoicePlaying = (event: any) => {
            // If a different voice has started playing and this button is still flagged as playing, set it to false
            if (event.detail?.voice_id !== params.voice_id && isPlaying) {
                setIsPlaying(false);
            }
        };
        window.addEventListener('voicePlaying', handleVoicePlaying);
        return () => {
            window.removeEventListener('voicePlaying', handleVoicePlaying);
        };
    }, [params.voice_id, isPlaying]);

    // Add effect to sync isPlaying state with actual audio state
    useEffect(() => {
        const checkAudioState = () => {
            // If audio doesn't exist or is paused, ensure isPlaying is false
            if (!audio || audio.paused) {
                setIsPlaying(false);
            }
        };

        // Check audio state when component mounts and periodically
        checkAudioState();
        const interval = setInterval(checkAudioState, 100);

        return () => {
            clearInterval(interval);
        };
    }, []);

    // Wijzig de onClick handler en het icoon op basis van de afspeelstatus
    return (
        <IconButton 
            onClick={handleClick}
            color="primary" 
            size={size}
            // Prevent the click from affecting the select input
            onMouseDown={(e) => {
                e.stopPropagation();
                e.preventDefault();
            }}
        >
            {isPlaying ? <StopIcon /> : <PlayArrowIcon />}
        </IconButton>
    );
};
