import { ReferenceArrayInput, AutocompleteArrayInput, useTranslate } from "react-admin";
import { DateRangeFilter } from './DateRangeFilter';
import { FiltersDrawer } from "../FiltersDrawer";
import { ActiveFilters } from './ActiveFilters';
import { Box, Typography } from '@mui/material';
import { IconCalendar, IconUser, IconBuilding } from '@tabler/icons-react';
import { useTheme } from '@mui/material/styles';

interface DashboardFiltersProps {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  filters: any;
  onFilterChange: (filters: any) => void;
  permissions: string;
  activeSection?: string | null;
  onClearFilters: () => void;
}

export const DashboardFilters = ({ 
  open, 
  onClose, 
  onOpen, 
  filters, 
  onFilterChange, 
  permissions,
  onClearFilters
}: DashboardFiltersProps) => {
  const filterToQuery = (searchText: any) => ({ name: `%${searchText}%` });
  const translate = useTranslate();
  const theme = useTheme();

  const filterComponents = [
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: theme.spacing(1) }}>
        <IconCalendar size="1rem" style={{ marginRight: 8 }} />
        <Typography variant="body2" color="textSecondary">
          {translate('resources.filters.date_range')}:
        </Typography>
      </Box>
      <Box sx={{mb: theme.spacing(4) }}>
        <DateRangeFilter 
          onChange={(dates) => onFilterChange({ 
            start_date: dates.start ? new Date(dates.start) : undefined,
            end_date: dates.end ? new Date(dates.end) : undefined,
            shortcutLabel: dates.shortcutLabel
          })} 
          value={filters.start_date ? [
            new Date(filters.start_date),
            new Date(filters.end_date || filters.start_date)
          ] : undefined}
        />
      </Box>
    </Box>,

    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: theme.spacing(1) }}>
        <IconUser size="1rem" style={{ marginRight: 8 }} />
        <Typography variant="body2" color="textSecondary">
          {translate('resources.filters.assistant')}:
        </Typography>
      </Box>
      <Box sx={{mb: theme.spacing(4) }}>
        <ReferenceArrayInput source="assistant_id" reference="assistants">
          <AutocompleteArrayInput 
            optionText="name"
            fullWidth
            helperText={false}
            filterToQuery={filterToQuery}
            onChange={(data) => onFilterChange({ assistant_ids: data })} 
            defaultValue={filters.assistant_ids}
          />
        </ReferenceArrayInput>
      </Box>
    </Box>,

    ['admin', 'partner'].includes(permissions) && (
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: theme.spacing(1) }}>
          <IconBuilding size="1rem" style={{ marginRight: 8 }} />
          <Typography variant="body2" color="textSecondary">
            {translate('resources.filters.customer')}:
          </Typography>
        </Box>
        <Box sx={{mb: theme.spacing(4) }}>
          <ReferenceArrayInput source="customer_id" reference="customers">
            <AutocompleteArrayInput 
              optionText="name"
              fullWidth
              helperText={false}
              filterToQuery={filterToQuery}
              onChange={(data) => onFilterChange({ customer_ids: data })} 
              defaultValue={filters.customer_ids}
            />
          </ReferenceArrayInput>
        </Box>
      </Box>
    )
  ];

  const activeFiltersComponent = (
    <ActiveFilters 
      filters={filters}
      onFilterChange={onFilterChange}
      onOpenFilters={onOpen}
    />
  );

  return (
    <FiltersDrawer
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      filters={filters}
      onFilterChange={onFilterChange}
      onClearFilters={onClearFilters}
      filterComponents={filterComponents}
      showActiveFilters={true}
      activeFiltersComponent={activeFiltersComponent}
    />
  );
}; 