import { Box } from '@mui/material';

interface BonnieIconProps {
    width?: number;
    height?: number;
}

const BonnieIcon = ({ width = 32, height = 32 }: BonnieIconProps) => {
    return (
        <Box
            component="img"
            src="/images/bonnie.png"
            alt="Bonnie"
            sx={{
                width: width,
                height: height || width,
                objectFit: 'contain'
            }}
        />
    );
};

export default BonnieIcon;