import React, { createContext, useContext, useState } from 'react';

interface ConversationFilters {
    start_date?: Date;
    end_date?: Date;
    shortcutLabel?: string;
    assistant_id?: number[];
    'assistant.customer_id'?: number[];
    outcome?: string;
    tags?: string[];
    topic?: string;
}

interface FilterContextType { 
    filters: ConversationFilters;
    setFilters: (filters: ConversationFilters) => void;
    filtersOpen: boolean;
    setFiltersOpen: (open: boolean) => void;
}

const FilterContext = createContext<FilterContextType | undefined>(undefined);

export const FilterProvider = ({ children }: { children: React.ReactNode }) => {
    // Original conversation state
    const [filters, setFilters] = useState<ConversationFilters>({});
    const [filtersOpen, setFiltersOpen] = useState(false);


    return (
        <FilterContext.Provider value={{
            filters,
            setFilters,
            filtersOpen,
            setFiltersOpen,
        }}>
            {children}
        </FilterContext.Provider>
    );
};

export const useFilters = () => {
    const context = useContext(FilterContext);
    if (context === undefined) {
        throw new Error('useFilters must be used within a FilterProvider');
    }
    return context;
};