import React, { useEffect, useState } from 'react';
import { Confirm, AutocompleteInput, useRecordContext, BooleanInput, TabbedForm, TabbedFormTabs, CheckboxGroupInput, AutocompleteArrayInput, useTranslate, usePermissions, SelectInput, Create, Edit, ReferenceField, ChipField, Datagrid, List, TextField, TextInput, useDataProvider, useGetOne, ReferenceInput, FormDataConsumer, ImageField, ImageInput, required } from 'react-admin';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';
import { Stack, Chip, IconButton, Modal, Switch, FormControlLabel, Card, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CustomMDXEditor from './CustomMdxEditor';
import { TabbedFormWithRevision, FieldDiff, SmartFieldDiff } from '@react-admin/ra-history';
import LexiconEditDialog from './assistant/LexiconEditDialog';
import { PlayAudioButton } from './assistant/PlayAudioButton';
import TransferRuleSection from './assistant/TransferRuleSection';

import Typography from '@mui/material/Typography';
import CustomCloneButton from './CustomCloneButton';

import {
    Toolbar,
    SaveButton,
    DeleteButton,
} from 'react-admin';

import TryAssistantButton from './TryAssistantButton';
import { Box, Grid } from '@mui/material';
import { FieldHeader } from './FieldHeader';
import CallAssistantButton from './CallAssistantButton';
import VoiceCall from './VoiceCall';

import { useFormContext } from 'react-hook-form';
import CustomPagination from './components/react-admin/CustomPagination';
import { ResourceTitle } from './layout/ResourceTitle';
import EditIcon from '@mui/icons-material/Edit';
import { TextField as MuiTextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import GooglePlacesAutocomplete from './assistant/GooglePlacesAutocomplete';
import WhatsAppImageValidator from './assistant/WhatsAppImageValidator';
import { useLocation } from 'react-router-dom';
import WorkingHoursEditDialog from './assistant/WorkingHoursEditDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@mui/material/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import TransferRuleModal from './components/TransferRuleModal';

interface Assistant {
    id: number | null;
    name: string;
}

export const AssistantDiff = () => (
    <Stack gap={2}>
        <FieldDiff source="name" />
        <FieldDiff source="prompt_scenarios.about_business" />

        {/* Personality Tab */}
        <FieldDiff source="prompt_scenarios.role" />
        <FieldDiff source="greeting" />

        {/* Tasks Tab */}
        <SmartFieldDiff source="prompt" />
        <SmartFieldDiff source="post_call_prompt" />

        {/* Policies and Context Tab */}
        <SmartFieldDiff source="prompt_scenarios.faq" />
        <SmartFieldDiff source="prompt_scenarios.transfer_policy" />
        <SmartFieldDiff source="prompt_scenarios.call_policy" />

    </Stack>
);

const CustomToolbar = ({isEdit = false}) => {
    const { permissions } = usePermissions();
    const record = useRecordContext();
    return (
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex' }}>
                {permissions !== 'demo' && <SaveButton label="resources.generic.save_and_close"/>}
                {permissions !== 'demo' && isEdit && <SaveButton 
                label="resources.generic.save"
                mutationOptions={{
                    onSuccess: () => {
                    }}
                }
                type="button"
                variant="text"
                />}
            </Box>
            <Box sx={{ display: 'flex', gap: '16px' }}> {/* Voeg hier de gap eigenschap toe */}
                {['admin', 'partner'].includes(permissions) && isEdit && <CustomCloneButton excludeFields={['id', 'notification_user', 'caller_id', 'fallback_number', 'stripe_subscription', 'phone_number_id', 'customer_id']} variant="text" resource="assistants" record={record} />}
                {['admin', 'partner'].includes(permissions) && <DeleteButton mutationMode="pessimistic" />}
            </Box>
        </Toolbar>
    );
}

const validatePhone = (value: string) => {
    // RegEx voor E.164 formaat
    const phoneRegex = /^\+[1-9]\d{1,14}$/;

    // Lege waarde is toegestaan
    if (!value) {
        return undefined;
    }

    // Test of de waarde voldoet aan het E.164 formaat
    if (!phoneRegex.test(value)) {
        // Retourneer een foutmelding als het niet voldoet
        return 'resources.assistants.errors.invalid_phone';
    }

    // Als alles in orde is, retourneer undefined (geen fout)
    return undefined;
};

const postFilters = [
    <TextInput source="name" alwaysOn />,
];


const LanguageInput = () => {
    const dataProvider = useDataProvider();
    const [choices, setChoices] = useState([]);
    const translate = useTranslate();

    useEffect(() => {
        const fetchLanguages = async () => {
            try {
                const { data } = await dataProvider.custom('assistants/languages', { method: 'GET' });
                const formattedChoices = data.map((language: any) => {
                    // Vervang koppelteken door underscore
                    const languageKey = language.replace('-', '_');
                    // Gebruik de aangepaste taalcode om de vertaling op te halen
                    const translatedName = translate('resources.languages.'+languageKey);
                    return { id: language, name: translatedName };
                });
                setChoices(formattedChoices);
            } catch (error) {
                console.error('Error fetching languages:', error);
            }
        };

        fetchLanguages();
    }, [dataProvider, translate]);

    return (
        <>
                <FieldHeader title="resources.assistants.fields.default_language" description="resources.assistants.descriptions.default_language" />
                <AutocompleteInput
                    source="default_language"
                    label=" "
                    choices={choices}
                    fullWidth
                    isRequired
                />

                <FieldHeader title="resources.assistants.fields.additional_languages" description="resources.assistants.descriptions.additional_languages" />
                        <AutocompleteArrayInput
                            source="additional_languages"
                            label=" "
                            choices={choices}
                            fullWidth
                        />
    </>
    );
};

const TimezoneInput = () => {
    const dataProvider = useDataProvider();
    const [choices, setChoices] = useState([]);
    const translate = useTranslate();

    useEffect(() => {
        const fetchTimezones = async () => {
            try {
                const { data } = await dataProvider.custom('assistants/timezones', { method: 'GET' });
                const formattedChoices = data.map((timezone: string) => ({ id: timezone, name: timezone }));
                setChoices(formattedChoices);
            } catch (error) {
                console.error('Error fetching timezones:', error);
            }
        };

        fetchTimezones();
    }, [dataProvider]);

    return <AutocompleteInput source="timezone" choices={choices} label='resources.assistants.fields.timezone' fullWidth isRequired />;
};

const DeploymentInput = () => {
    const dataProvider = useDataProvider();
    const [choices, setChoices] = useState([]);
    const [loading, setLoading] = useState(true);
    const translate = useTranslate();
    const record = useRecordContext();

    const optionRenderer = (choice: any) => {
        if (!choice) return '';
        return (
            <>
                <Chip label={choice.vendor} size="small" color='default' />
                <Typography component="span" variant="body2">{(choice.friendly_name)?choice.friendly_name:choice.model}</Typography>
            </>
        )
    }

    useEffect(() => {
        const fetchAiModels = async () => {
            setLoading(true);
            try {
                const { data } = await dataProvider.custom(
                    'assistants/aideployments' + (record?.id ? `?assistant_id=${record.id}` : ''), 
                    { method: 'GET' }
                );
                const formattedChoices = data.map((deployment: any) => ({
                    id: deployment.id,
                    vendor: deployment.vendor,
                    model: deployment.model,
                    friendly_name: deployment.friendly_name
                }));
                setChoices(formattedChoices);
            } catch (error) {
                console.error('Error fetching deployments:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchAiModels();
    }, [dataProvider, record]);

    if (loading) {
        return null; // Of een loading indicator
    }

    return (
        <SelectInput
            source="ai_deployment_id"
            choices={choices}
            optionText={optionRenderer}
            label={translate('resources.assistants.fields.ai_model')}
            fullWidth
            isRequired
            defaultValue=""
        />
    );
};  

const CallAssistantModal = ({ open, onClose, assistant }: {open: boolean, onClose: (event: React.MouseEvent<HTMLButtonElement>) => void, assistant: Assistant | null}) => {
    const modalStyle = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      boxShadow: 24,
      borderRadius: 2,
      p: 4,
      zIndex: 1301
    };
  
    return (
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="call-assistant-modal"
        aria-describedby="modal-to-call-assistant"
      >
        <Box sx={modalStyle}>
            <Box sx={
                {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }
            }>  
                <Chip label= {assistant?.name} color='primary' />
                <IconButton onClick={onClose} color='error'>
                    <CloseIcon />
                </IconButton>
            </Box>
          {assistant?.id && <VoiceCall assistantId={assistant.id} />}
        </Box>
      </Modal>
    );
  };


  const CallButtonWithRecord = ({ onClick: onClick }: { onClick: (event: React.MouseEvent<HTMLButtonElement>, assistant: Assistant) => void }) => {
    const record = useRecordContext();
    if (!record) return null;
    return (
      <CallAssistantButton 
        onClick={(event) => onClick(event, { id: record.id, name: record.name })}
      />
    );
  };

export const AssistantList = () => {
    const { permissions } = usePermissions();
    const [callModalOpen, setCallModalOpen] = useState(false);
    const [selectedAssistant, setSelectedAssistant] = useState<Assistant>({ id: null, name: '' });

    const handleCallClick = (event: React.MouseEvent<HTMLButtonElement>, assistant: Assistant) => {
        event.stopPropagation();
        setSelectedAssistant(assistant);
        setCallModalOpen(true);
      };
    
    const handleCloseCallModal = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setCallModalOpen(false);
        setSelectedAssistant({ id: null, name: '' });
    };

    return (
        <Box p={2}>
            <ResourceTitle />
            <List filters={postFilters} hasCreate={['admin', 'partner'].includes(permissions)} exporter={false} resource='assistants'
             pagination={<CustomPagination />}>
                <Datagrid rowClick="edit" bulkActionButtons={false}>`
                    <TextField source="id" />
                    <TextField source="name" />
                    <ReferenceField reference="users" source="notification_user">
                        <ChipField source="first_name" />
                    </ReferenceField>
                    <ReferenceField source="phone_number_id" reference="phone-numbers">
                        <TextField source="number" />
                    </ReferenceField>
                    <ReferenceField source="customer_id" reference="customers"><TextField source="name" /></ReferenceField>        
                    <TryAssistantButton />
                    <CallButtonWithRecord onClick={handleCallClick} />
                </Datagrid>
            </List>
            <CallAssistantModal
                open={callModalOpen}
                onClose={(event) => handleCloseCallModal(event)}
                assistant={selectedAssistant}
            />
        </Box>
        );
}

  const UserName = ({ id }) => {
    const { data: user } = useGetOne('users', { id });
    if (!user) return 'Unknown';
    return (
        <>
            {user.first_name} {user.last_name}
        </>
    );
};  
  

interface CommonFormProps {
    isEdit: boolean;
    defaultValues?: any; // Je kunt hier een specifieker type definiëren afhankelijk van de structuur van je defaultValues.
    customToolbar?: React.ReactNode;
  }

interface OpeningHoursSwitchProps {
    formData: {
        config?: {
            show_opening_hours?: boolean;
            google_place_id?: string;
            opening_hours?: {
                formatted_hours?: string;
                last_updated?: string;
            };
        };
        timezone?: string;
    };
    translate: (key: string) => string;
}

const OpeningHoursSwitch = ({ formData, translate }: OpeningHoursSwitchProps) => {
    const { setValue } = useFormContext();
    const dataProvider = useDataProvider();
    const [openingHours, setOpeningHours] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const showOpeningHours = formData?.config?.show_opening_hours ?? false;
    
    useEffect(() => {
        const fetchOpeningHours = async () => {
            if (showOpeningHours && formData?.config?.google_place_id) {
                setLoading(true);
                setError(null);
                try {
                    const queryParams = new URLSearchParams({
                        place_id: formData.config.google_place_id,
                        timezone: formData.timezone || ''
                    }).toString();

                    const { data } = await dataProvider.custom(
                        `places/opening-hours?${queryParams}`,
                        { method: 'GET' }
                    );
                    
                    if (data && typeof data.opening_hours === 'string') {
                        setOpeningHours(data.opening_hours);
                        
                        // Only update form if opening hours have changed
                        const currentHours = formData.config?.opening_hours?.formatted_hours;
                        if (currentHours !== data.opening_hours) {
                            const newConfig = { ...formData.config };
                            setValue('config', newConfig, {
                                shouldDirty: true,
                                shouldTouch: true
                            });
                        }
                    } else {
                        throw new Error('invalid_format');
                    }
                } catch (error) {
                    console.error('Error fetching opening hours:', error);
                    
                    // Only clear opening hours if they exist
                    if (formData.config?.opening_hours) {
                        const newConfig = { ...formData.config };
                        delete newConfig.opening_hours;
                        setValue('config', newConfig, {
                            shouldDirty: true,
                            shouldTouch: true
                        });
                    }

                    if (error.response) {
                        switch (error.response.status) {
                            case 404:
                                setError(translate('resources.assistants.opening_hours_not_found'));
                                break;
                            case 500:
                                setError(translate('resources.assistants.opening_hours_server_error'));
                                break;
                            default:
                                setError(translate('resources.assistants.opening_hours_error'));
                        }
                    } else if (error.message === 'invalid_format') {
                        setError(translate('resources.assistants.opening_hours_invalid_format'));
                    } else {
                        setError(translate('resources.assistants.opening_hours_error'));
                    }
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchOpeningHours();
    }, [showOpeningHours, formData?.config?.google_place_id, formData?.timezone, dataProvider, setValue, translate]);
    
    return (
        <Box>
            <FormControlLabel
                control={
                    <Switch
                        checked={showOpeningHours}
                        onChange={(event) => {
                            const newConfig = { ...formData.config };
                            newConfig.show_opening_hours = event.target.checked;
                            if (!event.target.checked) {
                                delete newConfig.opening_hours;
                                setOpeningHours(null);
                                setError(null);
                            }
                            setValue('config', newConfig, {
                                shouldDirty: true,
                                shouldTouch: true
                            });
                        }}
                    />
                }
                label={translate('resources.assistants.fields.show_opening_hours')}
            />
            {loading && (
                <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                    {translate('resources.assistants.loading_opening_hours')}
                </Typography>
            )}
            {error && (
                <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                    {error}
                </Typography>
            )}
            {showOpeningHours && openingHours && (
                <Box sx={{ mt: 2 }}>
                    <Box sx={{ mt: 1, pl: 2 }}>
                        <Typography 
                            variant="body2" 
                            color="textSecondary"
                            sx={{ 
                                fontFamily: 'monospace',
                                whiteSpace: 'pre-wrap'
                            }}
                        >
                            {openingHours}
                        </Typography>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

const TransferRuleCard = ({ rule, index, onEdit, onDelete }) => {
    const translate = useTranslate();
    const theme = useTheme();
    
    return (
        <Card sx={{ mb: 2, p: 2, border: `1px solid ${theme.palette.divider}` }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <Box>
                    <Typography variant="h6" sx={{ mb: 1 }}>
                        <span><Chip label={rule.number} size="small" color='default' /> </span>
                    </Typography>
                    <Box sx={{ width: '100%' }}>
                        {rule.reasons && rule.reasons.length > 0 ? (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {rule.reasons.map((reason, i) => (
                                    <Chip 
                                        key={i} 
                                        label={reason} 
                                        size="small" 
                                        variant="outlined" 
                                        sx={{ mb: 0.5 }}
                                    />
                                ))}
                            </Box>
                        ) : ("")}
                    </Box>
                    
                    {rule.availability?.length > 0 && (
                        <Box sx={{ mt: 1 }}>
                            <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <AccessTimeIcon sx={{ fontSize: 16 }} />
                                {rule.availability.map((schedule, i) => (
                                    `${schedule.days.length} days, ${schedule.start_time}-${schedule.end_time}`
                                )).join('; ')}
                            </Typography>
                        </Box>
                    )}
                </Box>
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <IconButton onClick={onEdit} size="small">
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={onDelete} size="small">
                        <DeleteIcon />
                    </IconButton>
                </Box>
            </Box>
        </Card>
    );
};


const TransferPolicySection = () => {
    const { setValue } = useFormContext();
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const record = useRecordContext();
    const isNew = !record?.id;

    return (
        <FormDataConsumer>
            {({ formData }) => {
                const transferTool = formData?.config?.tools?.find(tool => 
                    tool.name === 'call.transfer.v2' || tool.name === 'call.transfer'
                );
                const hasExistingRules = transferTool?.config?.transfer_rules?.length > 0;
                // Default to true for new assistants or if there are existing rules
                const useTransferRules = transferTool?.config?.use_transfer_rules ?? (isNew || hasExistingRules);

                return (
                    <Box>
                        {['admin'].includes(permissions) &&
                        <Box sx={{ mb: 2 }}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={useTransferRules}
                                        onChange={(event) => {
                                            const newConfig = { ...formData.config };
                                            const toolIndex = newConfig.tools.findIndex(tool => 
                                                tool.name === 'call.transfer.v2' || tool.name === 'call.transfer'
                                            );

                                            if (toolIndex === -1) {
                                                // If tool doesn't exist, create it
                                                newConfig.tools.push({
                                                    name: 'call.transfer.v2',
                                                    config: {
                                                        use_transfer_rules: event.target.checked,
                                                        transfer_rules: []
                                                    }
                                                });
                                            } else {
                                                // Update existing tool
                                                newConfig.tools[toolIndex].config = {
                                                    ...newConfig.tools[toolIndex].config,
                                                    use_transfer_rules: event.target.checked
                                                };
                                            }
                                            
                                            setValue('config', newConfig, {
                                                shouldDirty: true,
                                                shouldTouch: true
                                            });
                                        }}
                                    />
                                }
                                label={translate('resources.assistants.fields.use_transfer_rules')}
                            />
                        </Box>
            }

                        {useTransferRules ? (
                            <>
                                <FieldHeader 
                                    title="resources.assistants.fields.transfer_rules" 
                                    description="resources.assistants.descriptions.transfer_rules" 
                                />
                                <TransferRuleSection />
                            </>
                        ) : (
                            <>
                                <FieldHeader 
                                    title="resources.assistants.fields.prompt_scenarios.transfer_policy" 
                                    description="resources.assistants.descriptions.prompt_scenarios.transfer_policy" 
                                />
                                <CustomMDXEditor source="prompt_scenarios.transfer_policy" />
                            </>
                        )}
                    </Box>
                );
            }}
        </FormDataConsumer>
    );
};

const CommonForm: React.FC<CommonFormProps> = ({ isEdit, defaultValues }) =>  {
    const { permissions } = usePermissions(); 
    const record = useRecordContext();
    const translate = useTranslate();
    const filterToQuery = (searchText: any) => ({ name: `%${searchText}%` });
    const isAdminOrPartner = ['admin', 'partner'].includes(permissions);
    
    return (
        <Box sx={{ position: 'relative' }} p={2}>
            <Typography
                variant="h3"
                fontWeight="bold"
                sx={{mb: 1}}
            >
                {record?.name}
            </Typography>

            <TabbedFormWithRevision  
                warnWhenUnsavedChanges 
                defaultValues={defaultValues} 
                toolbar={<CustomToolbar isEdit={isEdit} />} 
                diff={<AssistantDiff />} 
                skipUserDetails={true} 
                allowRevert={true}
                renderName={id => <UserName id={id} />}
                tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" />}
            >
                <TabbedFormWithRevision.Tab label="resources.assistants.tabs.business_information">
                    <Grid container spacing={1} alignItems="top">
                        <Grid item xs={12} md={8} alignItems="top">
                            <FieldHeader title="resources.assistants.fields.name" description="resources.assistants.descriptions.name" /> 
                            <TextInput source="name" fullWidth label="" />

                            <FieldHeader title="resources.assistants.fields.prompt_scenarios.business_information" description="resources.assistants.descriptions.prompt_scenarios.business_information" />
                            <TextInput source="prompt_scenarios.about_business" fullWidth multiline className="custom-multiline" label="" />     

                            { ['admin', 'partner'].includes(permissions) &&
                            <>
                            <FieldHeader title="resources.assistants.fields.customer_id" description="resources.assistants.descriptions.customer_id" />
                            <ReferenceInput source="customer_id" reference="customers">
                                <AutocompleteInput optionText="name" fullWidth filterToQuery={(searchText: any) => ({ name: `%${searchText}%` })} validate={required()} />
                            </ReferenceInput>
                            </>
                            }
                            <FieldHeader title="resources.assistants.fields.phone_number_id" description="resources.assistants.descriptions.phone_number_id" />
                            <PhoneNumberInput />

                            <FieldHeader title="resources.assistants.fields.fallback_number" description="resources.assistants.descriptions.fallback_number" />
                            <FormDataConsumer>
                                {({ formData }) => (
                                    isAdminOrPartner ? (
                                        <TextInput source="fallback_number" fullWidth validate={validatePhone} label=""/>
                                    ) : (
                                        <MuiTextField
                                            value={formData.fallback_number || ''}
                                            disabled
                                            fullWidth
                                            size="small"
                                            variant="outlined"
                                            label=""
                                        />
                                    )
                                )}
                            </FormDataConsumer>

                            <FieldHeader title="resources.assistants.fields.timezone" description="resources.assistants.descriptions.timezone" />
                            <TimezoneInput />     
                            <FieldHeader title="resources.assistants.fields.google_place_id" description="resources.assistants.descriptions.google_place_id" />
                            <GooglePlacesAutocomplete source="config.google_place_id" label="" />
                            <Box sx={{ mb: 2 }} />
                            <FormDataConsumer>
                                {({ formData }) => {
                                    if (formData?.config?.google_place_id) {
                                        return (
                                            <>
                                                <FieldHeader 
                                                    title="resources.assistants.fields.opening_hours" 
                                                    description="resources.assistants.descriptions.opening_hours" 
                                                />
                                                <OpeningHoursSwitch formData={formData} translate={translate} />
                                            </>
                                        );
                                    }
                                    return null;
                                }}
                            </FormDataConsumer>
                            <Box sx={{ mb: 3 }} />

                            <FieldHeader title="resources.assistants.fields.whatsapp_image" description="resources.assistants.descriptions.whatsapp_image" />
                            <Grid item xs={6}>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => {                                        
                                        return (
                                            <>
                                                <ImageInput 
                                                    source="config.whatsapp_image" 
                                                    label=" "
                                                    accept={{ 
                                                        'image/png': ['.png'], 
                                                        'image/svg+xml': ['.svg'], 
                                                        'image/jpeg': ['.jpg', '.jpeg'],
                                                        'image/gif': ['.gif'],
                                                        'image/webp': ['.webp']
                                                    }}
                                                    options={{disabled:formData.config && formData.config.whatsapp_image && formData.config.whatsapp_image.src}}
                                                    placeholder={formData.config && formData.config.whatsapp_image && formData.config.whatsapp_image.src ? <></> : null}
                                                    onChange={(newValue) => {
                                                        // When a new image is uploaded, we can access the raw file
                                                        const rawFile = newValue?.rawFile;
                                                        if (rawFile) {
                                                            // Store the rawFile in the form state for validation
                                                            const newConfig = { ...formData.config };
                                                            if (!newConfig.whatsapp_image) {
                                                                newConfig.whatsapp_image = {};
                                                            }
                                                            newConfig.whatsapp_image.rawFile = rawFile;
                                                            // Mark as not validated yet
                                                            newConfig.whatsapp_image_valid = false;
                                                            // Update the form state
                                                            rest.scopedFormData.config = newConfig;
                                                        }
                                                    }}
                                                    validate={(value) => {
                                                        // Only validate new uploads, not existing images
                                                        if (value && value.rawFile && formData.config && 
                                                            formData.config.whatsapp_image_valid !== true) {
                                                            return translate('resources.assistants.fields.image_validation_required');
                                                        }
                                                        return undefined;
                                                    }}
                                                >
                                                    <ImageField source="src" title="title" sx={{ '& .RaImageField-image': { minWidth: '400px', maxWidth: '500px',  height: 'auto' } }}/>
                                                </ImageInput>
                                                {formData.id && (
                                                    <WhatsAppImageValidator 
                                                        assistantId={formData.id} 
                                                        imageUrl={formData.config?.whatsapp_image?.src} 
                                                        rawFile={formData.config?.whatsapp_image?.rawFile}
                                                    />
                                                )}
                                            </>
                                        );
                                    }}
                                </FormDataConsumer>
                            </Grid>                                                                        
                        </Grid>
                    </Grid>
                </TabbedFormWithRevision.Tab>

                <TabbedFormWithRevision.Tab label="resources.assistants.tabs.personality">
                    <Grid container spacing={1} alignItems="top">
                        <Grid item xs={12} md={8}>

                            <FieldHeader title="resources.assistants.fields.prompt_scenarios.role" description="resources.assistants.descriptions.prompt_scenarios.role" />                                       
                            <TextInput source="prompt_scenarios.role" fullWidth multiline className="custom-multiline" label="" />

                            <FieldHeader title="resources.assistants.fields.voice" description="resources.assistants.descriptions.voice" />
                            <VoiceInput />

                            <Grid container spacing={1} alignItems="top">
                                <Grid item xs={11}>
                                    <FieldHeader title="resources.assistants.fields.greeting" description="resources.assistants.descriptions.greeting" />
                                </Grid>
                                <Grid item xs={1}>
                                    <FormDataConsumer>
                                    {({ formData, ...rest }) => (
                                            <PlayAudioButton
                                                endpoint="/assistants/play/audio" 
                                                params={{
                                                    text: formData.greeting,
                                                    language: formData.default_language,
                                                    id: formData.id,
                                                    voice_id: formData.voice_id
                                                }}
                                            />
                                        )}
                                        </FormDataConsumer>    
                                    </Grid>
                                    <TextInput source="greeting" fullWidth multiline className="custom-multiline" label="" />
                                </Grid>    

                            <LanguageInput />  
                            <LexiconEditDialog />

                        </Grid>
                    </Grid>        

                    </TabbedFormWithRevision.Tab>


                    <TabbedFormWithRevision.Tab label="resources.assistants.tabs.tasks">
                        <Grid container spacing={1} alignItems="top">
                            <Grid item xs={12} md={8}>
                                <FieldHeader title="resources.assistants.fields.prompt" description="resources.assistants.descriptions.prompt" />
                                <CustomMDXEditor source="prompt" />

                                <FieldHeader title="resources.assistants.fields.post_call_prompt" description="resources.assistants.fields.post_call_prompt_help_text" />
                                <CustomMDXEditor source="post_call_prompt" />
                            </Grid>
                        </Grid>
                    </TabbedFormWithRevision.Tab>

                    <TabbedFormWithRevision.Tab label="resources.assistants.tabs.policies_and_context">
                        <Grid container spacing={1} alignItems="top">
                            <Grid item xs={12} md={8}>
                                <FieldHeader title="resources.assistants.fields.prompt_scenarios.faq" description="resources.assistants.descriptions.prompt_scenarios.faq" />
                                <CustomMDXEditor source="prompt_scenarios.faq" />
                                <TransferPolicySection />

                                <FieldHeader title="resources.assistants.fields.answer_schedule" description="resources.assistants.descriptions.answer_schedule" />
                                <WorkingHoursEditDialog />

                                <FieldHeader title="resources.assistants.fields.prompt_scenarios.call_policy" description="resources.assistants.descriptions.prompt_scenarios.call_policy" />
                                <CustomMDXEditor source="prompt_scenarios.call_policy" />
                            </Grid>
                        </Grid>
                    </TabbedFormWithRevision.Tab>

                <TabbedFormWithRevision.Tab label="resources.assistants.tabs.notifications">
                    <Grid container spacing={2}> 
                        <Grid item xs={12} md={8}> 
                            <FieldHeader title="resources.assistants.fields.notification_outcomes" description="resources.assistants.descriptions.notification_outcomes" />
                            <CheckboxGroupInput
                                        defaultValue={[]}
                                        label=""
                                        source="notification_outcomes"                            
                                        choices={[        
                                            { id: 'business_follow_up', name: 'resources.conversations.outcomes.business_follow_up' },
                                            { id: 'caller_follow_up', name: 'resources.conversations.outcomes.caller_follow_up' },
                                            { id: 'transferred', name: 'resources.conversations.outcomes.transferred' },
                                            { id: 'information_provided', name: 'resources.conversations.outcomes.information_provided' },
                                            { id: 'completed', name: 'resources.conversations.outcomes.completed' },
                                            { id: 'no_action', name: 'resources.conversations.outcomes.no_action' },
                                        ]}
                                        row={false}
                                        format={value => Array.isArray(value) ? value : []} 
                                        /> 

                                    <FieldHeader title="resources.assistants.fields.notification_user" description="resources.assistants.descriptions.notification_user" />                                        
                                    <ReferenceInput source="notification_user" reference="users">
                                        <AutocompleteInput 
                                            fullWidth 
                                            optionText={(choice) => `${choice.first_name} ${choice.last_name} (${choice.email})`} 
                                            filterToQuery={(searchText: any) => ({ 
                                                email: `%${searchText}%`, 
                                                //first_name: `%${searchText}%`, #todo allow search for OR
                                                //last_name: `%${searchText}%` 
                                            })} 
                                        />                                            
                                    </ReferenceInput>                        

                                    <FieldHeader 
                                        title="resources.assistants.fields.config.notifications.include_transcript" 
                                        description="resources.assistants.descriptions.config.notifications.include_transcript" 
                                    />
                                    <BooleanInput 
                                        source="config.notifications.include_transcript" 
                                        label=" "
                                    />

                                    <FieldHeader 
                                        title="resources.assistants.fields.config.daily_report.enabled" 
                                        description="resources.assistants.descriptions.config.daily_report.enabled" 
                                    />
                                    <DailyReportSection />

                        </Grid> 
                        </Grid>
                </TabbedFormWithRevision.Tab>

                {record && record.config && record.config.tools && record.config.tools.formitable &&
                <TabbedFormWithRevision.Tab label="resources.assistants.tabs.tools">
                    <Grid container spacing={1} alignItems="top">
                        <Grid item xs={12} md={8}>
                            <FieldHeader title="resources.assistants.fields.config.tools.formitable.config.restaurant_id" description="resources.assistants.descriptions.config.tools.formitable.config.restaurant_id" />
                            <TextInput source="config.tools.formitable.config.restaurant_id" fullWidth />

                            <FieldHeader title="resources.assistants.fields.config.tools.formitable.config.api_key" description="resources.assistants.descriptions.config.tools.formitable.config.api_key" />
                            <TextInput source="config.tools.formitable.config.api_key" fullWidth />
                        </Grid>
                    </Grid>
                </TabbedFormWithRevision.Tab>
                }

                { ['admin', 'partner'].includes(permissions) &&
                <TabbedForm.Tab label="resources.assistants.tabs.admin">
                    <Grid container spacing={2}> 
                        <Grid item xs={12} md={8}> 

                            <FieldHeader title="resources.assistants.fields.deployment" description="resources.assistants.descriptions.deployment" />
                            <DeploymentInput />

                            <FieldHeader title="resources.assistants.fields.prompt_scenarios.context" description="resources.assistants.descriptions.prompt_scenarios.context" />
                            <CustomMDXEditor source="prompt_scenarios.context" />

                            <FieldHeader 
                            title="resources.assistants.fields.config.tts.use_human_time_format" 
                            description="resources.assistants.descriptions.config.tts.use_human_time_format" 
                            />
                            <BooleanInput 
                                source="config.tts.use_human_time_format" 
                                label=" "
                            />

                            <FieldHeader 
                                title="resources.assistants.fields.config.stt.interruption" 
                                description="resources.assistants.descriptions.config.stt.interruption" 
                            />
                            <BooleanInput 
                                source="config.stt.interruption" 
                                label=" "
                            />

                            <FieldHeader title="resources.assistants.fields.config.stt.backend" description="resources.assistants.descriptions.config.stt.backend" />
                            <SelectInput
                                source="config.stt.backend"
                                choices={[
                                    { id: 'azure', name: 'Microsoft Azure' },
                                    { id: 'deepgram', name: 'Deepgram' },
                                ]}
                                label=""
                                emptyText="Default"
                                defaultValue="azure"
                            />

                            <FieldHeader title="resources.assistants.fields.config" description="resources.assistants.descriptions.config" />
                            <CustomJsonInput />    
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
                }
                
            </TabbedFormWithRevision>
            </Box>
    );
  }
// Aangepaste titel component voor AssistantEdit
const AssistantTitle = () => {
    const translate = useTranslate();
    const record = useRecordContext();
    return record ? (
        <>{translate('resources.assistants.edit')}: {record.name}</>
    ) : null;
};



export const AssistantEdit = () => {
    return (
        <Edit title={<AssistantTitle />}  mutationMode='pessimistic' resource='assistants'>
            <CommonForm isEdit={true} />
        </Edit>
    );
}


export const AssistantCreate = () => {
    const [defaultValues, setDefaultValues] = useState<any>(null);
    const dataProvider = useDataProvider();
    const location = useLocation();

    useEffect(() => {
        const fetchDefaultValues = async () => {
            try {
                // Check if we have source data from cloning
                const searchParams = new URLSearchParams(location.search);
                const sourceJson = searchParams.get('source');
                
                if (sourceJson) {
                    // If we have cloned data, use that
                    const sourceData = JSON.parse(sourceJson);
                    setDefaultValues(sourceData);
                } else {
                    // Otherwise fetch the default values
                    const { data } = await dataProvider.getDefaults('assistants');
                    setDefaultValues(data);
                }
            } catch (error) {
                console.error('Error fetching default values', error);
            }
        };

        if (defaultValues === null) {
            fetchDefaultValues();
        }
    }, [dataProvider, defaultValues, location]);

    if (defaultValues === null) {
        return null;
    }

    return (
        <Create resource='assistants' redirect="list">
            <CommonForm isEdit={false} defaultValues={defaultValues} />
        </Create>
    );
}

const CustomJsonInput = () => {
    const { setValue, trigger } = useFormContext();

    return (
        <FormDataConsumer>
            {({ formData }) => (
                <JsonView 
                    src={formData?.config || {}}
                    theme="github"
                    enableClipboard={true}
                    displaySize={true}
                    editable={{
                        edit: true,
                        add: true,
                        delete: true
                    }}
                    collapsed={false}
                    style={{ 
                        backgroundColor: 'transparent',
                        padding: '8px'
                    }}
                    onChange={({ src }) => {
                        // Update het config object
                        setValue('config', src, {
                            shouldDirty: true,    // Markeer het formulier als gewijzigd
                            shouldTouch: true     // Markeer het veld als aangeraakt
                        });
                        
                        // Trigger validatie om de save button te updaten
                        trigger('config');
                    }}
                />
            )}
        </FormDataConsumer>
    );
};

const PhoneNumberInput = () => {
    const dataProvider = useDataProvider();
    const [choices, setChoices] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const record = useRecordContext();
    const { setValue } = useFormContext();
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const isAdminOrPartner = ['admin', 'partner'].includes(permissions);
    
    useEffect(() => {
        const fetchPhoneNumbers = async () => {
            try {
                const { data } = await dataProvider.custom('assistants/phone-numbers', 
                    { 
                        method: 'POST', 
                        postdata: { assistant_id: record?.id } 
                    } 
                );
                const formattedChoices = data.map(number => ({ id: number.id, name: number.number }));
                setChoices(formattedChoices);
            } catch (error) {
                console.error('Error fetching numbers:', error);
            }
        };

        fetchPhoneNumbers();
    }, [dataProvider, record]);

    const handleEditClick = () => {
        setShowConfirm(true);
    };

    const handleConfirm = () => {
        setShowConfirm(false);
        setIsEditing(true);
    };

    const handleClose = () => {
        setShowConfirm(false);
    };

    return (
        <Box sx={{ mb: 2 }}>
            <FormDataConsumer>
                {({ formData }) => {
                    const selectedNumber = choices.find(choice => choice.id === formData.phone_number_id);

                    if (!isAdminOrPartner || (selectedNumber && !isEditing)) {
                        return (
                            <>
                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                    <MuiTextField
                                        value={selectedNumber?.name ?? null}
                                        disabled
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        label=""
                                    />
                                    {isAdminOrPartner && selectedNumber && (
                                        <IconButton 
                                            onClick={handleEditClick}
                                            size="small"
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    )}
                                </Box>
                                <Confirm
                                    isOpen={showConfirm}
                                    title={translate('resources.assistants.phone_number.confirm_title')}
                                    content={translate('resources.assistants.phone_number.confirm_content')}
                                    onConfirm={handleConfirm}
                                    onClose={handleClose}
                                />
                            </>
                        );
                    }

                    return (
                        <AutocompleteInput
                            source="phone_number_id"
                            choices={choices}
                            fullWidth
                            noOptionsText={translate('resources.assistants.phone_number.no_options')}
                            label=""
                            onChange={(value) => {
                                setValue('phone_number_id', value || null);
                                setIsEditing(false);
                            }}
                        />
                    );
                }}
            </FormDataConsumer>
        </Box>
    );
};

const DailyReportSection = () => {
    const { setValue } = useFormContext();
    
    return (
        <FormDataConsumer>
            {({ formData }) => {
                if (!formData) return null;

                return (
                    <Box>  
                        <BooleanInput 
                            source="config.daily_report.enabled" 
                            label=" "
                            defaultValue={false}
                            onChange={(event) => {
                                if (!event?.target) return;
                                const value = event.target.checked;
                                const newConfig = { ...formData.config };
                                if (value) {
                                    newConfig.daily_report = {
                                        enabled: true,
                                        schedule: {
                                            hour: 20,
                                            minute: 0
                                        },
                                        user_id: null
                                    };
                                } else {
                                    newConfig.daily_report = {
                                        enabled: false
                                    };
                                }
                                
                                setValue('config', newConfig, {
                                    shouldDirty: true,
                                    shouldTouch: true
                                });
                            }}
                        />

                        {formData.config?.daily_report?.enabled && (
                            <>
                                <FieldHeader 
                                    title="resources.assistants.fields.config.daily_report.schedule" 
                                    description="resources.assistants.descriptions.config.daily_report.schedule" 
                                />
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                    slotProps={{ textField: { fullWidth: false } }}
                                    ampm={false}
                                    minutesStep={15}
                                    value={formData.config?.daily_report?.schedule ? 
                                        dayjs().hour(formData.config.daily_report.schedule.hour)
                                          .minute(formData.config.daily_report.schedule.minute)
                                        : null
                                    }
                                    onChange={(newValue: any) => {
                                        if (newValue) {
                                            const newConfig = { ...formData.config };
                                            newConfig.daily_report.schedule = {
                                                hour: newValue.hour(),
                                                minute: newValue.minute()
                                            };
                                            setValue('config', newConfig, {
                                                shouldDirty: true,
                                                shouldTouch: true
                                            });
                                        }
                                    }}
                                    format="HH:mm"
                                />
                                </LocalizationProvider>
                                <Box sx={{ mt: 2 }}/>
                                <FieldHeader 
                                    title="resources.assistants.fields.config.daily_report.user_recipient" 
                                    description="resources.assistants.descriptions.config.daily_report.notification_user"
                                />
                                <ReferenceInput 
                                    source="config.daily_report.user_id" 
                                    reference="users"
                                >
                                    <AutocompleteInput 
                                        fullWidth 
                                        validate={value => {
                                            if (!value && formData?.config?.daily_report?.enabled) {
                                                return 'Required';
                                            }
                                            return undefined;
                                        }}
                                        optionText={(choice) => `${choice.first_name} ${choice.last_name} (${choice.email})`} 
                                        filterToQuery={(searchText: any) => ({ 
                                            email: `%${searchText}%`, 
                                        })} 
                                        onChange={(value) => {
                                            const newConfig = { ...formData.config };
                                            newConfig.daily_report = {
                                                ...newConfig.daily_report,
                                                user_id: value
                                            };
                                            setValue('config', newConfig, {
                                                shouldDirty: true,
                                                shouldTouch: true
                                            });
                                        }}
                                    />
                                </ReferenceInput>
                            </>
                        )}
                    </Box>
                );
            }}
        </FormDataConsumer>
    );
};

const VoiceInput = () => {
    const dataProvider = useDataProvider();
    const [choices, setChoices] = useState([]);
    const [loading, setLoading] = useState(true);
    const translate = useTranslate();
    const record = useRecordContext();
    const { setValue } = useFormContext();

    const optionRenderer = (choice: any) => {
        if (!choice) return '';
        const record = useRecordContext();
        
        return (
            <Box sx={{ display: 'flex', gap: 1 }}>
                <Box 
                    sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
                    onClick={(e) => {
                        // Prevent the click from bubbling up to the select input
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                >
                    <PlayAudioButton
                        endpoint="/assistants/play/audio" 
                        params={{
                            text: record?.greeting || "Hello, how can I help you today?",
                            language: record?.default_language || "en-US",
                            id: record?.id,
                            voice_id: choice.id
                        }}
                        size="small"
                    />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                        <Typography component="span" variant="body2" fontWeight="bold">
                            {choice.display_name}
                        </Typography>
                        
                        <Chip 
                            label={choice.provider.provider_type} 
                            size="small" 
                            color="default"
                            sx={{ ml: 0.5 }} 
                        />
                        
                        {choice.is_default && 
                            <Chip 
                                label="Default" 
                                size="small" 
                                sx={{ 
                                    ml: 0.5,
                                    bgcolor: '#84b5af',
                                    color: 'white',
                                }} 
                            />
                        }
                        
                        {!choice.is_public && (
                            <Chip 
                                label="Private" 
                                size="small"
                                variant="outlined"
                                color="success"         
                                sx={{ 
                                    ml: 0.5,
                                    borderColor: '#84b5af',
                                    color: 'gray'
                                }} 
                            />
                        )}
                    </Box>
                    
                    {choice.description && (
                        <Typography 
                            component="span" 
                            variant="body2" 
                            color="textSecondary"
                            sx={{ fontStyle: 'italic' }}
                        >
                            {choice.description}
                        </Typography>
                    )}
                </Box>
            </Box>
        )
    }

    useEffect(() => {
        const fetchVoices = async () => {
            setLoading(true);
            try {
                const { data } = await dataProvider.custom(
                    'assistants/ra/voices', 
                    { method: 'GET' }
                );
                const formattedChoices = data.map((voice: any) => ({
                    id: voice.id,
                    display_name: voice.display_name,
                    voice_id: voice.voice_id,
                    provider: voice.provider,
                    is_default: voice.is_default,
                    is_public: voice.is_public,
                    description: voice.description
                }))
                // Sort the voices based on multiple criteria
                .sort((a, b) => {
                    // 1. Default voice always comes first
                    if (a.is_default) return -1;
                    if (b.is_default) return 1;

                    // 2. Sort by public/custom
                    if (a.is_public !== b.is_public) {
                        return a.is_public ? -1 : 1;
                    }

                    // 3. Sort by provider
                    if (a.provider.provider_type !== b.provider.provider_type) {
                        return a.provider.provider_type.localeCompare(b.provider.provider_type);
                    }

                    // 4. Finally, sort by display name
                    return a.display_name.localeCompare(b.display_name);
                });

                setChoices(formattedChoices);

                // If this is a new record (no voice_id set) and we have choices,
                // set the default voice
                if (!record?.voice_id) {
                    const defaultVoice = formattedChoices.find((choice: any) => choice.is_default);
                    if (defaultVoice) {
                        setValue('voice_id', defaultVoice.id, {
                            shouldDirty: true,
                            shouldTouch: true
                        });
                    }
                }
            } catch (error) {
                console.error('Error fetching voices:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchVoices();
    }, [dataProvider, record, setValue]);

    if (loading) {
        return null;
    }

    return (
        <FormDataConsumer>
            {({ formData }) => (
                <SelectInput
                    source="voice_id"
                    choices={choices}
                    optionText={optionRenderer}
                    optionValue="id"
                    label={translate('resources.assistants.fields.voice')}
                    fullWidth
                    isRequired
                    onChange={(value) => {
                        const selectedVoice = choices.find(choice => choice.id === value);
                        if (selectedVoice) {
                            setValue('voice_id', selectedVoice.id, {
                                shouldDirty: true,
                                shouldTouch: true
                            });
                        }
                    }}
                />
            )}
        </FormDataConsumer>
    );
};
