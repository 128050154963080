import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  Checkbox,
  Box,
  Typography,
  Grid,
  IconButton,
  Chip,
  Alert,
  FormHelperText,
  Card,
  useTheme
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useFormContext } from 'react-hook-form';
import { useTranslate } from 'react-admin';
import { LocalizationProvider, TimePicker, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { FieldHeader } from '../FieldHeader';

const DAYS_OF_WEEK = [
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' },
  { value: 7, label: 'Sunday' },
];

const DEFAULT_RULE = {
  mode: 'client_first',
  recurring: true,
  recurrence_pattern: {
    days: [1, 2, 3, 4, 5],
    start_time: '09:00',
    end_time: '17:00'
  },
  forward_number: '',
  dial_timeout: 10
};

// Function to check if two rules overlap
const checkOverlap = (rule1, rule2) => {
  // Convert times to minutes for easier comparison
  const timeToMinutes = (time) => {
    if (!time) return 0;
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  };

  // Get start and end times based on whether the rule is recurring or not
  const getTimeValues = (rule) => {
    if (!rule) return { start: 0, end: 0, days: [] };

    if (rule.recurring && rule.recurrence_pattern) {
      return {
        start: timeToMinutes(rule.recurrence_pattern.start_time),
        end: timeToMinutes(rule.recurrence_pattern.end_time),
        days: rule.recurrence_pattern.days || []
      };
    }
    return {
      start: timeToMinutes(rule.start_time),
      end: timeToMinutes(rule.end_time),
      // For non-recurring rules, create an array of days between start and end date
      days: rule.start_date && rule.end_date ? 
        Array.from({ length: 7 }, (_, i) => i + 1) : []
    };
  };

  const time1 = getTimeValues(rule1);
  const time2 = getTimeValues(rule2);

  // If either rule is invalid, consider no overlap
  if (!time1.days.length || !time2.days.length) {
    return false;
  }

  // Check if any days overlap
  const commonDays = time1.days.filter(day => 
    time2.days.includes(day)
  );

  // If no days in common, rules don't overlap
  if (commonDays.length === 0) {
    return false;
  }

  // Check time overlap only if days overlap
  return (time1.start < time2.end && time2.start < time1.end);
};

const WorkingHoursRuleCard = ({ rule, index, onEdit, onDelete }) => {
  const translate = useTranslate();
  const theme = useTheme();

  const getRuleSummary = () => {
    if (rule.recurring) {
      const days = rule.recurrence_pattern.days.map(day => 
        translate(`resources.assistants.days.${day}`).substring(0, 3)
      ).join(', ');
      return `${days} • ${rule.recurrence_pattern.start_time} - ${rule.recurrence_pattern.end_time}`;
    } else {
      const startDate = rule.start_date ? dayjs(rule.start_date).format('DD/MM/YYYY') : '---';
      const endDate = rule.end_date ? dayjs(rule.end_date).format('DD/MM/YYYY') : '---';
      return `${startDate} to ${endDate}`;
    }
  };

  return (
    <Card sx={{ mb: 2, p: 2, border: `1px solid ${theme.palette.divider}` }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <Box>
          <Typography variant="h6" sx={{ mb: 1 }}>
            <Chip 
              label={translate(`resources.assistants.working_hours.mode.${rule.mode}`)} 
              size="small" 
              color="primary" 
            />
          </Typography>
          <Box sx={{ width: '100%' }}>
            <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <AccessTimeIcon sx={{ fontSize: 16 }} />
              {getRuleSummary()}
            </Typography>
          </Box>
          {rule.forward_number && (
            <Box sx={{ mt: 1 }}>
              <Typography variant="body2" color="text.secondary">
                {translate('resources.assistants.working_hours.forward_to')}: {rule.forward_number}
              </Typography>
            </Box>
          )}
        </Box>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <IconButton onClick={onEdit} size="small">
            <EditIcon />
          </IconButton>
          <IconButton onClick={onDelete} size="small">
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
    </Card>
  );
};

const WorkingHoursEditDialog = () => {
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentRuleIndex, setCurrentRuleIndex] = useState(-1);
  const [workingHoursRules, setWorkingHoursRules] = useState([]);
  const [currentRule, setCurrentRule] = useState({...DEFAULT_RULE});
  const [error, setError] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [is24HourMode, setIs24HourMode] = useState(false);
  
  const { setValue, getValues } = useFormContext();
  const translate = useTranslate();

  // Load existing rules when component mounts or config changes
  useEffect(() => {
    const config = getValues('config') || {};
    const rules = config.working_hours || [];
    setWorkingHoursRules(rules);
  }, [getValues]);

  const handleOpen = (index = -1) => {
    setCurrentRuleIndex(index);
    const rule = index >= 0 ? {...workingHoursRules[index]} : {...DEFAULT_RULE};
    setCurrentRule(rule);
    
    // Check if the rule is already using 24-hour time range
    if (rule.recurring && 
        rule.recurrence_pattern?.start_time === '00:00' && 
        rule.recurrence_pattern?.end_time === '23:59') {
      setIs24HourMode(true);
    } else {
      setIs24HourMode(false);
    }
    
    setEditMode(true);
    setOpen(true);
    setError('');
  };

  const handleClose = () => setOpen(false);

  const handleSave = () => {
    const currentConfig = getValues('config') || {};
    const newConfig = {
      ...currentConfig,
      working_hours: workingHoursRules
    };
    
    setValue('config', newConfig, {
      shouldDirty: true,
      shouldTouch: true
    });
    
    setOpen(false);
  };

  const handleDeleteRule = (index) => {
    const updatedRules = [...workingHoursRules];
    updatedRules.splice(index, 1);
    setWorkingHoursRules(updatedRules);
    
    const currentConfig = getValues('config') || {};
    const newConfig = {
      ...currentConfig,
      working_hours: updatedRules
    };
    
    setValue('config', newConfig, {
      shouldDirty: true,
      shouldTouch: true
    });
    
    if (currentRuleIndex === index) {
      setEditMode(false);
      setCurrentRuleIndex(-1);
    } else if (currentRuleIndex > index) {
      setCurrentRuleIndex(currentRuleIndex - 1);
    }
  };

  const validate = (values) => {
    const errors = {};
    
    // Validate forward number
    if (!values.forward_number) {
      errors.forward_number = 'resources.assistants.working_hours.error.no_forward_number';
    } else if (!/^\+[1-9]\d{1,14}$/.test(values.forward_number)) {
      errors.forward_number = 'resources.assistants.working_hours.error.invalid_forward_number';
    }

    // Validate dial timeout
    if (!values.dial_timeout) {
      errors.dial_timeout = 'resources.assistants.working_hours.error.no_dial_timeout';
    } else if (values.dial_timeout < 1 || values.dial_timeout > 60) {
      errors.dial_timeout = 'resources.assistants.working_hours.error.invalid_dial_timeout';
    }

    // Validate dates for non-recurring rules
    if (!values.recurring) {
      if (!values.start_date) {
        errors.start_date = 'resources.assistants.working_hours.error.no_start_date';
      }
      if (!values.end_date) {
        errors.end_date = 'resources.assistants.working_hours.error.no_end_date';
      }
    }

    // Validate recurring pattern for recurring rules
    if (values.recurring) {
      if (!values.recurrence_pattern?.days?.length) {
        errors.days = 'resources.assistants.working_hours.error.no_days';
      }
      if (!values.recurrence_pattern?.start_time) {
        errors.start_time = 'resources.assistants.working_hours.error.no_start_time';
      }
      if (!values.recurrence_pattern?.end_time) {
        errors.end_time = 'resources.assistants.working_hours.error.no_end_time';
      }
    }
    
    return errors;
  };

  const handleSaveRule = () => {
    const errors = validate(currentRule);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    // Reset validation state
    setValidationErrors({});
    setError('');

    let hasOverlap = false;
    workingHoursRules.forEach((rule, index) => {
      if (index !== currentRuleIndex && checkOverlap(currentRule, rule)) {
        hasOverlap = true;
      }
    });
    
    if (hasOverlap) {
      setError(translate('resources.assistants.working_hours.error.overlap'));
      return;
    }

    // Create base rule structure
    const formattedRule = {
      mode: currentRule.mode,
      recurring: currentRule.recurring,
      forward_number: currentRule.forward_number || '',
      dial_timeout: currentRule.dial_timeout || 20
    };

    // Add appropriate fields based on recurring status
    if (currentRule.recurring) {
      formattedRule.recurrence_pattern = {
        days: currentRule.recurrence_pattern.days,
        start_time: currentRule.recurrence_pattern.start_time,
        end_time: currentRule.recurrence_pattern.end_time
      };
    } else {
      formattedRule.start_date = currentRule.start_date;
      formattedRule.end_date = currentRule.end_date;
      formattedRule.start_time = currentRule.recurrence_pattern?.start_time || '00:00';
      formattedRule.end_time = currentRule.recurrence_pattern?.end_time || '23:59';
    }
    
    const updatedRules = [...workingHoursRules];
    if (currentRuleIndex >= 0) {
      updatedRules[currentRuleIndex] = formattedRule;
    } else {
      updatedRules.push(formattedRule);
    }
    
    setWorkingHoursRules(updatedRules);

    const currentConfig = getValues('config') || {};
    const newConfig = {
      ...currentConfig,
      working_hours: updatedRules
    };
    
    setValue('config', newConfig, {
      shouldDirty: true,
      shouldTouch: true
    });

    setEditMode(false);
    setCurrentRuleIndex(-1);
    setOpen(false);
  };

  const handleModeChange = (event) => {
    setCurrentRule({
      ...currentRule,
      mode: event.target.value
    });
  };

  const handleRecurringChange = (event) => {
    setCurrentRule({
      ...currentRule,
      recurring: event.target.checked,
      // Initialize or preserve recurrence_pattern when switching to recurring
      recurrence_pattern: event.target.checked ? {
        days: currentRule.recurrence_pattern?.days || [1, 2, 3, 4, 5],
        start_time: currentRule.recurrence_pattern?.start_time || '09:00',
        end_time: currentRule.recurrence_pattern?.end_time || '17:00'
      } : currentRule.recurrence_pattern
    });
  };

  const handleDialTimeoutChange = (event) => {
    setCurrentRule({
      ...currentRule,
      dial_timeout: parseInt(event.target.value) || 20
    });
  };

  const handleForwardNumberChange = (event) => {
    setCurrentRule({
      ...currentRule,
      forward_number: event.target.value
    });
  };

  const handleDayToggle = (dayValue) => {
    const days = [...currentRule.recurrence_pattern.days];
    const index = days.indexOf(dayValue);
    
    if (index === -1) {
      days.push(dayValue);
    } else {
      days.splice(index, 1);
    }
    
    setCurrentRule({
      ...currentRule,
      recurrence_pattern: {
        ...currentRule.recurrence_pattern,
        days: days.sort((a, b) => a - b)
      }
    });
  };

  const handleTimeChange = (type, time) => {
    if (!time) return;
    
    const formattedTime = `${time.hour().toString().padStart(2, '0')}:${time.minute().toString().padStart(2, '0')}`;
    
    setCurrentRule({
      ...currentRule,
      recurrence_pattern: {
        ...currentRule.recurrence_pattern,
        [type]: formattedTime
      }
    });
  };

  const handleDateChange = (type, date) => {
    setCurrentRule({
      ...currentRule,
      [type]: date ? date.format('YYYY-MM-DD') + (type === 'start_date' ? ' 00:00' : ' 23:59') : null
    });
  };

  const set24HourTimeRange = () => {
    setIs24HourMode(true);
    setCurrentRule({
      ...currentRule,
      recurrence_pattern: {
        ...currentRule.recurrence_pattern,
        start_time: '00:00',
        end_time: '23:59'
      }
    });
  };
  
  const disableSet24HourMode = () => {
    setIs24HourMode(false);
  };

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2 }}>
          <Button
            startIcon={<AddIcon />}
            variant="outlined"
            onClick={() => handleOpen()}
            size="small"
            sx={{ alignSelf: 'flex-start' }}
          >
            {translate('resources.assistants.working_hours.add_schedule')}
          </Button>
        </Box>
        
        {workingHoursRules.length === 0 ? (
          <Typography variant="body2" color="textSecondary" sx={{ py: 2, textAlign: 'left' }}>
            {translate('resources.assistants.working_hours.no_rules')}
          </Typography>
        ) : (
          <Box>
            {workingHoursRules.map((rule, index) => (
              <WorkingHoursRuleCard
                key={index}
                rule={rule}
                index={index}
                onEdit={() => handleOpen(index)}
                onDelete={() => handleDeleteRule(index)}
              />
            ))}
          </Box>
        )}
      </Box>
      <Dialog open={open} maxWidth="md" fullWidth>
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
            {currentRuleIndex >= 0 ? 
              translate('resources.assistants.working_hours.edit_rule') : 
              translate('resources.assistants.working_hours.add_rule')
            }
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FieldHeader
                title={translate('resources.assistants.working_hours.mode.label')}
                description={translate('resources.assistants.working_hours.mode.description')}
              />
              <FormControl component="fieldset">
                <RadioGroup
                  value={currentRule.mode}
                  onChange={handleModeChange}
                >
                  <FormControlLabel 
                    value="client_first" 
                    control={<Radio />} 
                    label={translate('resources.assistants.working_hours.mode.client_first')} 
                  />
                  <FormControlLabel 
                    value="client_only" 
                    control={<Radio />} 
                    label={translate('resources.assistants.working_hours.mode.client_only')} 
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FieldHeader
                title={translate('resources.assistants.working_hours.forward_number')}
                description={translate('resources.assistants.working_hours.forward_number_description')}
              />
              <FormControl fullWidth error={!!validationErrors.forward_number}>
                <TextField
                  value={currentRule.forward_number}
                  onChange={handleForwardNumberChange}
                  placeholder="+31612345678"
                  fullWidth
                  margin="normal"
                  error={!!validationErrors.forward_number}
                  helperText={validationErrors.forward_number ? translate(validationErrors.forward_number) : ''}
                />
              </FormControl>
            </Grid>

            {currentRule.mode !== 'client_only' && (
              <Grid item xs={12}>
                <FieldHeader
                  title={translate('resources.assistants.working_hours.dial_timeout')}
                  description={translate('resources.assistants.working_hours.dial_timeout_description')}
                />
                <FormControl fullWidth error={!!validationErrors.dial_timeout}>
                  <TextField
                    type="number"
                    value={currentRule.dial_timeout}
                    onChange={handleDialTimeoutChange}
                    fullWidth
                    margin="normal"
                    inputProps={{ min: 1, max: 60 }}
                    error={!!validationErrors.dial_timeout}
                    helperText={validationErrors.dial_timeout ? translate(validationErrors.dial_timeout) : ''}
                  />
                </FormControl>
              </Grid>
            )}

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={currentRule.recurring}
                    onChange={handleRecurringChange}
                  />
                }
                label={translate('resources.assistants.working_hours.recurring')}
              />
            </Grid>

            {currentRule.recurring ? (
              // Show days and time selection for recurring rules
              <>
                <Grid item xs={12}>
                  <FieldHeader
                    title={translate('resources.assistants.days.label')}
                  />
                  <FormControl error={!!validationErrors.days}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                      {DAYS_OF_WEEK.map((day) => (
                        <Chip
                          key={day.value}
                          label={translate(`resources.assistants.days.${day.value}`)}
                          onClick={() => handleDayToggle(day.value)}
                          color={currentRule.recurrence_pattern.days.includes(day.value) ? "primary" : "default"}
                          variant={currentRule.recurrence_pattern.days.includes(day.value) ? "filled" : "outlined"}
                        />
                      ))}
                    </Box>
                    {validationErrors.days && (
                      <FormHelperText error>{translate(validationErrors.days)}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} container spacing={2}>
                  <Grid item xs={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                      {is24HourMode ? (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Typography variant="body2" color="primary">
                            <AccessTimeIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 0.5 }} />
                            {translate('resources.assistants.working_hours.active_24_hours')}
                          </Typography>
                          <Button
                            size="small"
                            onClick={disableSet24HourMode}
                            variant="text"
                          >
                            {translate('resources.assistants.working_hours.customize')}
                          </Button>
                        </Box>
                      ) : (
                        <Button
                          size="small"
                          onClick={set24HourTimeRange}
                          startIcon={<AccessTimeIcon />}
                          variant="outlined"
                        >
                          {translate('resources.assistants.working_hours.set_24_hours')}
                        </Button>
                      )}
                    </Box>
                  </Grid>
                  
                  {!is24HourMode && (
                    <>
                      <Grid item xs={12} sm={6}>
                        <FieldHeader
                          title={translate('resources.assistants.working_hours.start_time')}
                        />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            value={dayjs(`2022-01-01T${currentRule.recurrence_pattern.start_time}`)}
                            onChange={(newValue) => handleTimeChange('start_time', newValue)}
                            format="HH:mm"
                            slotProps={{ 
                              textField: { 
                                fullWidth: true, 
                                margin: 'normal',
                                error: !!validationErrors.start_time,
                                helperText: validationErrors.start_time ? translate(validationErrors.start_time) : ''
                              } 
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FieldHeader
                          title={translate('resources.assistants.working_hours.end_time')}
                        />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            value={dayjs(`2022-01-01T${currentRule.recurrence_pattern.end_time}`)}
                            onChange={(newValue) => handleTimeChange('end_time', newValue)}
                            format="HH:mm"
                            slotProps={{ 
                              textField: { 
                                fullWidth: true, 
                                margin: 'normal',
                                error: !!validationErrors.end_time,
                                helperText: validationErrors.end_time ? translate(validationErrors.end_time) : ''
                              } 
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </>
                  )}
                </Grid>
              </>
            ) : (
              // Show date selection for non-recurring rules
              <Grid item xs={12}>
                <Box sx={{mt: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <FieldHeader
                          title={translate('resources.assistants.working_hours.start_date')}
                          description={translate('resources.assistants.working_hours.start_date_description')}
                        />
                        <DatePicker
                          value={currentRule.start_date ? dayjs(currentRule.start_date) : null}
                          onChange={(newValue) => handleDateChange('start_date', newValue)}
                          slotProps={{ 
                            textField: { 
                              fullWidth: true, 
                              margin: 'normal',
                              error: !!validationErrors.start_date,
                              helperText: validationErrors.start_date ? translate(validationErrors.start_date) : ''
                            } 
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FieldHeader
                          title={translate('resources.assistants.working_hours.end_date')}
                          description={translate('resources.assistants.working_hours.end_date_description')}
                        />
                        <DatePicker
                          value={currentRule.end_date ? dayjs(currentRule.end_date) : null}
                          onChange={(newValue) => handleDateChange('end_date', newValue)}
                          slotProps={{ 
                            textField: { 
                              fullWidth: true, 
                              margin: 'normal',
                              error: !!validationErrors.end_date,
                              helperText: validationErrors.end_date ? translate(validationErrors.end_date) : ''
                            } 
                          }}
                        />
                      </Grid>
                    </Grid>
                  </LocalizationProvider>
                </Box>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            {translate('resources.generic.cancel')}
          </Button>
          <Button onClick={handleSaveRule} variant="contained" color="primary">
            {translate('resources.generic.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default WorkingHoursEditDialog; 