import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, FormControlLabel, Checkbox, RadioGroup, Radio, FormControl, FormLabel, Select, MenuItem, Box, IconButton, OutlinedInput } from '@mui/material';
import { IconX } from '@tabler/icons-react';
import { useTranslate } from 'react-admin';


interface ConversationNoteCreateModalProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (note: {

        title: string;
        status: 'OPEN' | 'CLOSED' | 'NONE';
        isPrivate: boolean;
        assignedTo?: string;
    }) => void;
    users?: Array<{ id: string; name: string; email: string }>;
}

export const ConversationNoteCreateModal = ({ open, onClose, onSubmit, users = [] }: ConversationNoteCreateModalProps) => {
    const [formData, setFormData] = React.useState({
        title: '',
        status: 'OPEN' as 'OPEN' | 'CLOSED' | 'NONE',
        isPrivate: false,
        assignedTo: '',
    });

    const translate = useTranslate();

    // Reset form when modal opens
    React.useEffect(() => {
        if (open) {
            setFormData({
                title: '',
                status: 'OPEN',
                isPrivate: false,
                assignedTo: '',
            });
        }
    }, [open]);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!formData.title.trim()) {
            alert('Please enter a note description');
            return;
        }
        onSubmit(formData);
        onClose();
    };

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
            },
        },
    };

    return (
        <Dialog 
            open={open} 
            onClose={onClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle sx={{ m: 0, p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                New Note
                <IconButton onClick={onClose} size="small">
                    <IconX size={18} />
                </IconButton>
            </DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <TextField
                            label="Note description"
                            multiline
                            rows={4}
                            fullWidth
                            required
                            value={formData.title}
                            onChange={(e) => {
                                setFormData({ ...formData, title: e.target.value });
                            }}
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData.isPrivate}
                                    onChange={(e) => {
                                        setFormData({ ...formData, isPrivate: e.target.checked });
                                    }}
                                />
                            }
                            label={translate('resources.conversation_notes.fields.is_private')}
                        />

                        <FormControl component="fieldset">
                            <FormLabel component="legend">Status</FormLabel>
                            <RadioGroup
                                row
                                value={formData.status}
                                onChange={(e) => {
                                    setFormData({ ...formData, status: e.target.value as 'OPEN' | 'CLOSED' | 'NONE' });
                                }}
                            >
                                <FormControlLabel value="OPEN" control={<Radio />} label="Open" />
                                <FormControlLabel value="CLOSED" control={<Radio />} label="Closed" />
                                <FormControlLabel value="NONE" control={<Radio />} label="None" />
                            </RadioGroup>
                        </FormControl>
                        <FormControl fullWidth>
                            <FormLabel component="legend" sx={{ mb: 1 }}>Assigned to</FormLabel>
                            <Select
                                value={formData.assignedTo}
                                onChange={(e) => {
                                    setFormData({ ...formData, assignedTo: e.target.value });
                                }}
                                input={<OutlinedInput />}
                                MenuProps={MenuProps}
                                displayEmpty
                            >
                                <MenuItem value="">
                                    <em>No assignee</em>
                                </MenuItem>
                                {users.map((user) => (
                                    <MenuItem key={user.id} value={user.id}>
                                        {user.name} ({user.email})
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button onClick={onClose} variant="outlined">Cancel</Button>
                    <Button type="submit" variant="contained">Submit</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};