import { styled } from '@mui/material/styles';
import { Chip, ChipProps, useTheme } from '@mui/material';

interface CustomChipProps extends Omit<ChipProps, 'variant' | 'color'> {
  variant?: 'outlined' | 'filled';
  size?: 'small' | 'medium';
  color?: string;
}

const getColorFromText = (text: string, theme: any): string => {
  const hash = Array.from(text.toLowerCase()).reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);
  
  const palette = theme.customComponents.noteColors.palette;
  return palette[Math.abs(hash) % palette.length];
};

const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'size',
})<CustomChipProps>(({ theme, variant, color = 'default', size = 'medium' }) => {
  const mainColor = color;
  
  // Create a darker version of the color for text
  let textColor = mainColor;
  if (mainColor.startsWith('#')) {
    const colorValue = mainColor.replace(/^#/, '');
    const r = parseInt(colorValue.substring(0, 2), 16);
    const g = parseInt(colorValue.substring(2, 4), 16);
    const b = parseInt(colorValue.substring(4, 6), 16);
    
    // Darken the color by reducing brightness (using 0.8 instead of 0.7 for less darkness)
    const darkerR = Math.max(0, Math.floor(r * 0.8));
    const darkerG = Math.max(0, Math.floor(g * 0.8));
    const darkerB = Math.max(0, Math.floor(b * 0.8));
    
    textColor = `#${darkerR.toString(16).padStart(2, '0')}${darkerG.toString(16).padStart(2, '0')}${darkerB.toString(16).padStart(2, '0')}`;
  }
  
  return {
    borderRadius: theme.shape.borderRadius,
    '&.MuiChip-root': {
      fontSize: size === 'small' ? '0.75rem !important' : theme.typography.body2.fontSize,
      padding: size === 'small' ? `${theme.spacing(1)} !important` : `${theme.spacing(1)} !important`,
      height: size === 'small' ? '20px !important' : undefined,
      minHeight: size === 'small' ? '20px !important' : undefined,
      maxWidth: size === 'small' ? '120px' : 'auto',
    },
    color: textColor,

    ...(variant === 'outlined' && {
      backgroundColor: 'transparent',
      border: '1px solid',
      borderColor: theme.palette.grey[300],
      color: textColor,
      fontWeight: 'bold'
    }),

    ...(variant === 'filled' && {
      backgroundColor: `${mainColor}40`,
      color: textColor,
      border: `1.5px solid ${mainColor}45`,
      fontWeight: 'bold'
    }),

    '&.MuiChip-root .MuiChip-label': {
      padding: size === 'small' ? '0 4px !important' : `${theme.spacing(1)} !important`,
      fontSize: 'inherit !important',
      lineHeight: size === 'small' ? '20px !important' : 'inherit',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },

    '& .MuiChip-deleteIcon': {
      fontSize: '10px',
      margin: '0 4px',
      color: 'inherit',
      height: size === 'small' ? '16px !important' : 'auto',
      width: size === 'small' ? '16px !important' : 'auto',
    },
  };
});

const CustomChip = ({ label, variant = 'filled', color, ...props }: CustomChipProps) => {
  const theme = useTheme();
  
  // Use provided color or determine from text using noteColors palette
  const determinedColor = color || (
    typeof label === 'string' ? getColorFromText(label, theme) : theme.customComponents.noteColors.palette[0]
  );

  return (
    <StyledChip
      label={label}
      variant={variant}
      color={determinedColor}
      {...props}
    />
  );
};

export default CustomChip;
