import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useRecordContext } from 'react-admin';
import ChatIcon from '@mui/icons-material/Chat';

const TryAssistantButton = ({  }) => {
  const navigate = useNavigate();
  const record = useRecordContext();

  const handleClick = (event) => {
    event.stopPropagation();
    navigate(`/assistants/${record.id}/try`);
  };

  return (
    <Button size="small" onClick={handleClick} variant="outlined" startIcon={<ChatIcon />}
    sx={{
      border: 'none',
      boxShadow: (theme) => `inset 0 0 0 1px ${theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.12)'}`,
    }}
    >
      Test
    </Button>
  );
};
export default TryAssistantButton;
