import { Box, TextField, Stepper, Step, StepLabel, Button, Alert, CircularProgress, Typography } from '@mui/material';
import { useState } from 'react';
import axios from 'axios';
import { ResourceTitle } from './layout/ResourceTitle';

const Onboarding = () => {
    const [activeStep, setActiveStep] = useState(0);
    const steps = ['Location', 'Details', 'Review'];

    const [placeId, setPlaceId] = useState('');
    const [customSections, setCustomSections] = useState({
        about_business: `[CLEAR INSTRUCTION: Create a title] {name} - [One compelling unique feature]

[Core concept paragraph: Include
- Restaurant's main offering
- Atmosphere/environment
- Unique selling points]

Address: [From provided content]
Website: [From provided content]

Business Hours:
[Format each day's hours from provided content]`,

        faq: `1. Menu Details:
- Offerings
- Special diets
- Service times

2. Facility Information:
- Accessibility
- Equipment available
- Pet policies

3. Services:
- Special offerings
- Unique features
- Operating hours`,

        transfer_policy: `Transfer Authorization:
1. Approved Scenarios:
    [List from content]

2. Contact Numbers:
    [From content]

3. Failure Protocol:
    [From content]

Hours of Operation:
[Specify from content]`,
    });

    const [isLoading, setIsLoading] = useState(false);
    const [assistantData, setAssistantData] = useState<any>(null);
    const [error, setError] = useState<string | null>(null);

    const handleNext = async () => {
        if (activeStep === 0) {
            if (!placeId) {
                setError('Please enter a Google Places ID');
                return;
            }
            setActiveStep((prevStep) => prevStep + 1);
        } else if (activeStep === 1) {
            setActiveStep((prevStep) => prevStep + 1);
            try {
                setIsLoading(true);
                const baseUrl = import.meta.env.VITE_NODE_ENV === 'production'
                    ? 'https://app.pollie.ai/api/onboarding'  // Production URL
                    : import.meta.env.VITE_API_URL; // Development URL

                const endpoint = `${baseUrl}/onboarding`;

                // Get the auth token (keeping the existing auth logic)
                const auth = localStorage.getItem('auth');
                const token = auth ? JSON.parse(auth).access_token : null;

                const response = await axios({
                    method: 'post',
                    url: endpoint,
                    data: {
                        place_id: placeId,
                        custom_sections: customSections
                    },
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                setAssistantData(response.data);
            } catch (error) {
                console.error('Onboarding failed:', error);
                setError('Failed to generate assistant data');
            } finally {
                setIsLoading(false);
            }
        } else if (activeStep === 2) {
            return;
        }
    };

    const handleBack = () => {
        setActiveStep((prevStep) => prevStep - 1);
    };

    return (
        <Box p={2}>
           <ResourceTitle />
            <Box sx={{ width: '100%', p: 3 }}>
                <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>

                <Box sx={{ mt: 2, mb: 2 }}>
                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
                            {error}
                        </Alert>
                    )}
                    {activeStep === 0 && (
                        <Box sx={{ height: '400px', width: '100%' }}>
                            <Box sx={{ mb: 4 }}>
                                <h2>Let's get started!</h2>
                                <p>First, we'll need the Google Places ID for your business location. You can find this by searching for your business on Google Maps and copying the ID from the URL.</p>
                            </Box>
                            <TextField
                                fullWidth
                                label="Google Places ID"
                                placeholder="Enter Google Places ID..."
                                variant="outlined"
                                value={placeId}
                                onChange={(e) => setPlaceId(e.target.value)}
                            />
                        </Box>
                    )}
                    {activeStep === 1 && (
                        <Box>
                            <Box sx={{ mb: 4 }}>
                                <h2>Business Details</h2>
                                <p>Tell us more about your business and how you operate.</p>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    label="About Your Business"
                                    placeholder="Describe your business..."
                                    value={customSections.about_business}
                                    onChange={(e) => setCustomSections({
                                        ...customSections,
                                        about_business: e.target.value
                                    })}
                                />
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    label="Frequently Asked Questions"
                                    placeholder="List common questions and answers..."
                                    value={customSections.faq}
                                    onChange={(e) => setCustomSections({
                                        ...customSections,
                                        faq: e.target.value
                                    })}
                                />
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    label="Transfer Policy"
                                    placeholder="Describe when and how to transfer calls..."
                                    value={customSections.transfer_policy}
                                    onChange={(e) => setCustomSections({
                                        ...customSections,
                                        transfer_policy: e.target.value
                                    })}
                                />
                            </Box>
                        </Box>
                    )}
                    {activeStep === 2 && (
                        <Box>
                            <Box sx={{ mb: 4 }}>
                                <h2>Setting Up Your Assistant</h2>
                                {isLoading ? (
                                    <Box sx={{ textAlign: 'center', py: 4 }}>
                                        <CircularProgress />
                                        <p>We're generating your custom AI assistant...</p>
                                        <p>This may take a few moments.</p>
                                    </Box>
                                ) : assistantData ? (
                                    <Box sx={{ textAlign: 'center', py: 4 }}>
                                        <p>Your AI assistant has been successfully created!</p>
                                        <Button 
                                            variant="contained" 
                                            color="primary"
                                            href={assistantData.assistant_url}
                                            sx={{ mt: 2 }}
                                        >
                                            Go to Your Assistant
                                        </Button>
                                    </Box>
                                ) : null}
                            </Box>
                        </Box>
                    )}
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: 2 }}>
                    <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                    >
                        Back
                    </Button>
                    {activeStep !== steps.length - 1 && (
                        <Button
                            variant="contained"
                            onClick={handleNext}
                        >
                            Next
                        </Button>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default Onboarding;